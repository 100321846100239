<template>
  <div class="login-form-wrap">
    <form>
      <div id="login-container">
        <div class="logo grid-col-1 a-i-center">
          <div class="flex-center pof txt-7xl"><i class="pof portfel-text-horizontal"></i></div>
        </div>
        <div class="login-form grid-col-1 a-i-center" style="min-width: 300px;">

          <div class="group">
            <div class="input-group">
              <div class="input-desc t-white-txt" :class="$t('font')">{{$t('email')}}<span class="red-txt txt-md">*</span>
                <span class="t-white-txt pd-l-2" v-if="getErrors.email">{{ getErrors.email[0] }}</span>
              </div>
              <div class="input-area"><input type="text"  autocomplete="email"  v-model="form.email">
                <span v-if="form.email" class="fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="form.email=''"></span>
              </div>
            </div>
          </div>

          <div class="group">
            <div class="input-group">
              <div class="input-desc t-white-txt" :class="$t('font')">{{$t('password')}}<span class="red-txt txt-md">*</span>
              </div>
              <div class="input-area"><input type="password"  autocomplete="current-password"  v-model="form.password">
                <span v-if="form.password" class="fa fa-times-circle grey3-txt pd-r-2 txt-md" style="max-width: 9%;"  v-on:click="form.password=''"></span>
              </div>
            </div>
          </div>




        </div>
        <div class="remember-forgot grid-col-1 a-i-center">
          <div class="grid-col-2">
            <div class="grid-col-2 flex-inline a-i-center j-c-start g-c-gap-10">
              <input-switch set-class="txt-3xl" :state="form.remember_me" @change="toggleRemember"></input-switch>
              <div class="pd-r-20">{{$t('remember_me')}}</div>
            </div>
            <div class="g-c-2"></div>
          </div>

        </div>
        <div class="login-button flex-center">
          <button class=" btn btn-md yellow t-black-txt mg-10" :class="$t('font_b')" @click.prevent="login">
            {{ $t('login') }}</button>
        </div>


      </div>
    </form>
    <div class="select-loc-wrap">
      <div class="select-loc">
        <span class="txt-xs1 t-center txt-uppercase " style="color: rgba(255,255,255,0.30);"><i class="fal fa-map-marked-alt txt-lg"></i>{{location.tld}}</span>
      </div>
    </div>
    <div class="select-lang-wrap" v-on:click="$router.push({name:'setDomain'})">
      <div class="select-lang">
        <span class="txt-xs1 t-center txt-uppercase"><i class="fal fa-globe txt-lg"></i>{{lang}}</span>
      </div>
    </div>


  </div>
</template>
<script>
import support, {getDomain, getLang, setLocale} from "@/support/support";
import {storageGet} from "@/support/storage";
import router from "@/router";
import {mapActions, mapState} from "vuex";
import InputSwitch from "@/views/components/input-switch";
import {awOpen} from "@/support/applicationWindow";
import {logOut} from "@/support/auth";


export default {
  components: {InputSwitch},
  data(){
    return {
      lang:'',
      location:'',
      form:{
        email:'',
        password:'',
        remember_me:true,
      },
      errors: [],
      state: this.val,


    }
  },
  mounted() {
    setLocale();
    logOut();

    this.lang = this.getLang;
    this.location = this.getLocation(getDomain());

    let email = storageGet('email');
    if(email){
      this.form.email = support.decode(email);
      this.form.remember_me =true;
    }



  },
  computed:{
    ...mapState('GENERAL',['SETTINGS']),
    Settings(){
      return this.SETTINGS;
    },
    isSessionOpen(){
      return this.Settings.session_open;
    },
    getLang(){
      return getLang();
    },
    getErrors(){
      return this.errors;
    },
  },
  methods:{
    ...mapActions('GENERAL',['_logIn','_logOut','_getSettings']),
    getLocation(loc){
      return this.$root.$store.getters._getDomain(loc);
    },
    toggleRemember(state) {
      this.form.remember_me = state
    },
    login() {
      let self = this;
      this._logIn(this.form).then(()=>{
            this._getSettings().then(()=>{
              if(self.isSessionOpen){
                  awOpen(5);
              }
              return (router.currentRoute.name !=='home') ? router.push({name: 'home'}):'';
            });

          }).catch(error => {
              return this.errors = error;
          });
    }

  }

}
</script>
<style scoped>

</style>