// import Home from './components/employee/home.vue';
// import Operations from './components/employee/operations.vue'
import Transfer from './components/employee/transferPoints.vue'


// import SellPoints from './components/employee/sellPoints.vue'
// import SellCards from './components/employee/sellCards.vue'
// import BuyCards from './components/employee/buyCards.vue'
import PartnerPoints from './components/employee/partnerPoints.vue'

import setDomain from "@/views/set-domain";
import Login from '@/views/login.vue';
import Apps from "@/views/AppDashboard";
import AppDashboard from "@/views/AppDashboard";
import AcceptPoints from "@/views/AcceptPoints";
import GrantPoints from "@/views/GrantPoints";
import AcceptCards from "@/views/AcceptCards";
import GrantCard from "@/views/GrantCard";


export default [

    {
        path: '/',
        component: AppDashboard,
        name: 'home',
        meta: { authOnly: true }
    },
    // {
    //     path: '/operations',
    //     component: Operations,
    //     name: 'operations',
    //     meta: { authOnly: true }
    // },
    {
        path: '/apps',
        component: Apps,
        name: 'Apps',
        meta: { authOnly: true }
    },
    {
        path: '/transfer',
        component: Transfer,
        name: 'transfer',
        meta: { authOnly: true }
    },

    {
        path: '/AcceptPoints',
        component: AcceptPoints,
        name: 'AcceptPoints',
        meta: { authOnly: true }
    },
    {
        path: '/GrantPoints',
        component: GrantPoints,
        name: 'GrantPoints',
        meta: { authOnly: true }
        //meta: { guestOnly: true } // MUST CHANE TO AUTH ONLY !!!!!!!!!
    },
    {
        path: '/GrantCard',
        component: GrantCard,
        name: 'GrantCard',
        meta: { authOnly: true }
        //meta: { guestOnly: true } // MUST CHANE TO AUTH ONLY !!!!!!!!!
    },
    {
        path: '/AcceptCard',
        component: AcceptCards,
        name: 'AcceptCard',
        meta: { authOnly: true }
        //meta: { guestOnly: true } // MUST CHANE TO AUTH ONLY !!!!!!!!!
    },
    {
        path: '/partnerpoints',
        component: PartnerPoints,
        name: 'partnerpoints',
        meta: { authOnly: true }
        //meta: { guestOnly: true } // MUST CHANE TO AUTH ONLY !!!!!!!!!
    },

    {
        path: '/login',
        component: Login,
        name: 'login',
        meta: { guestOnly: true }
    },
    {
        path: '/set-location',
        component: setDomain,
        name: 'setDomain',

    },

];