import {storageGet} from "@/support/storage";

export const getReportData = (state)=>{
    let STATE_REPORT = state.REPORT;
    let STORAGE_REPORT = storageGet('STORAGE_REPORT',true,true);
    if(STORAGE_REPORT.total_checks_number>STATE_REPORT.total_checks_number && STATE_REPORT.first_check_time !==STORAGE_REPORT.first_check_time){
        return STATE_REPORT;
    }
    return STORAGE_REPORT;
}
export const getSettings = (state)=>{
    return state.SETTINGS;
}