import axios from "axios";
import {getLang} from "@/support/support";
import {getToken} from "@/support/auth";


function getHost(){
    let domain = localStorage.getItem('UVZCUVgweFBRMEZVU1U5Tw==');
    if(!domain){
        domain = "testnet";
    }
    //domain==="Greenland" Testnet
    return "https://"+domain+"."+process.env.VUE_APP_API;
}

let host = getHost(); //"http://api.production85.test/api";
const common = {

    // 60 sec timeout
    timeout: 60000,
    // keepAlive pools and reuses TCP connections,

    // follow up to 10 HTTP 3xx redirects
    maxRedirects: 10,

    // cap the maximum content length we'll accept
    // to 50MBs, just in case
    maxContentLength: 50 * 1000 * 1000,
    /* Base url From config */
    baseURL: host,
    //needs implementation of dynamic url

};


let BaseApi = axios.create(common);

let Api = function(){

    let token = getToken();//localStorage.getItem(config.tokenName);
    let lang = getLang();
    if(token){
        BaseApi.defaults.headers.common['Authorization']= `Bearer ${token}`;
        BaseApi.defaults.headers.common['X-localization']= lang;
    }
    return BaseApi;
}

// Api.defaults.withCredentials = true;

export default Api;
