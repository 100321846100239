export const storageGet=(key,json=false,decode=false)=>{
    let encodedKey = encode(key);
    if(json){
        return (localStorage.getItem(encodedKey)) ? JSON.parse(localStorage.getItem(encodedKey)) : false;
    }
    if(decode){
        return (localStorage.getItem(encodedKey)) ? decode(localStorage.getItem(encodedKey)) : false;
    }
    return (localStorage.getItem(encodedKey)) ? localStorage.getItem(encodedKey) : false;
}
export const storageHas=(key,nullValue=false)=>{
    let encodedKey = encode(key);
    if(!nullValue){
        return (localStorage.getItem(encodedKey) && localStorage.getItem(encodedKey) !==undefined) ? localStorage.getItem(encodedKey).length>0 : false;
    }
    return (localStorage.getItem(encodedKey));
}
export const storagePut=(key,data)=>{
    if(typeof data !=='object'){
        return localStorage.setItem(encode(key),data);
    }else{
        return localStorage.setItem(encode(key),JSON.stringify(data));
    }

}
export const storageRemove=(key)=>{
    return localStorage.removeItem(encode(key));
}
export const storageUpdate=(key,data)=>{
    localStorage.removeItem(encode(key));
    return storagePut(key,data);
}
export const encode = (string)=>{
    return window.btoa(window.btoa(string));
} // encode a string
export const decode = (string)=>{
    return window.atob(window.atob(string));
} // decode the string

export default {
    namespaced: true,
    storageGet,
    storageHas,
    storagePut,
    storageRemove,
    storageUpdate


}