<template>
  <div id="app">
    <notification-window></notification-window>
    <div v-if="showInstallMessage" class="ios-install-message-container">
      <div class="ios-install-message" v-on:click="showInstallMessage = !showInstallMessage">
        <div class="install-icon"><i class="fal fa-plus-square t-black-txt"></i> </div>
        <div class="install-text" :class="$t('font')">
          {{$t('install_app_text_1')}}: {{$t('tap')}}: <svg data-name="ShareIocin" style="display: inline-block;height: 18px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.4 14.61">
          <path d="M.85,12.15v-6A1.53,1.53,0,0,1,2.58,4.45H4.26v.72H2.59a.92.92,0,0,0-1,1v5.91a.91.91,0,0,0,1,1H9.31a.92.92,0,0,0,1-1V6.2a.93.93,0,0,0-1-1H7.65V4.45H9.32a1.53,1.53,0,0,1,1.73,1.71v6a1.53,1.53,0,0,1-1.73,1.71H2.58A1.53,1.53,0,0,1,.85,12.15ZM5.6,9V2.69l0-.85-.52.54-1,1a.33.33,0,0,1-.25.1A.31.31,0,0,1,3.64,3L5.7,1A.33.33,0,0,1,6,.83.31.31,0,0,1,6.21,1L8.28,3a.37.37,0,0,1,.1.24.32.32,0,0,1-.33.32.33.33,0,0,1-.24-.1l-1-1-.52-.54,0,.85V9A.36.36,0,0,1,5.6,9Z"
                style="fill:var(--blue); max-height:20px;"/>
        </svg> {{$t('next')}} <span class="en">Add to Home Screen <i class="fal fa-plus-square"></i></span>
        </div>
      </div>
    </div>
    <div class="install-arrow" v-if="showInstallMessage"><i class="fas fa-sort-down"></i></div>
    <loading v-if="showLoadingWindow"></loading>
    <div class="rotate-message" v-if="displayRotateMessage">
      <div style="text-align: center;">
        <p :class="$t('font')">{{$t('please_rotate_phone')}}</p>
      </div>
      <div class="rotate-phone">
        <div id="rotate"></div>
      </div>
    </div>




    <router-view></router-view>
    <notifications-list />

  </div>
</template>

<script>

import NotificationsList from "./components/STATIC/NotificationsList";

import support, {autoColorMode, getDomain, getLang, setColorMode} from "@/support/support";
import loading from "@/components/STATIC/loading";
import NotificationWindow from "@/views/components/notification_window";

export default {

    name: 'App',
    components: {
      NotificationWindow,
      loading,
      NotificationsList,
    },
    data(){
      return {
        showInstallMessage:false,
        showRotateMessage:false,
      }
    },
    created() {
      support.setColorMode();
    },
    mounted() {
      // checkAndNotifyUpdate();

      if(!getLang() || !getDomain()){
        if(this.$router.currentRoute.name!=="setDomain"){
          return this.$router.push({ name: "setDomain" });
        }
      }
      //Set Color Mode
      if (autoColorMode()) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches) {
          document.documentElement.setAttribute('data-theme', 'dark');
        }
        window.matchMedia('(prefers-color-scheme:dark)').addEventListener('change', ev => {
          const newColorScheme = ev.matches ? "dark" : "light";
          document.documentElement.setAttribute('data-theme', newColorScheme);
        });
      }else{
        setColorMode();
      }
      // window.addEventListener('orientationchange', this.checkOrientationChange);
      //
      //
      // // Detects if device is on iOS
      // const isIos = () => {
      //   const userAgent = window.navigator.userAgent.toLowerCase();
      //   return /iphone|ipad|ipod/.test( userAgent );
      // }
      // // Detects if device is in standalone mode
      // const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
      //
      // // Checks if should display install popup notification:
      // if (isIos() && !isInStandaloneMode()) {
      //   this.showInstallMessage=true;
      // }
      // support.setColorMode();
    },
    computed:{
      showLoadingWindow(){
        return this.$store.state.LOADING_WINDOW;
      },
      displayRotateMessage() {
        return (this.showRotateMessage);
      },

    },
    methods:{

      checkOrientationChange() {
        let screenOrientation = window.orientation;

        if(screenOrientation !==0){
          return this.showRotateMessage = true;
        }else {
          return this.showRotateMessage = false;

        }
      }

    },
  }
</script>
<style>
body{

  -webkit-tap-highlight-color: transparent;
  /*-webkit-touch-callout: none;*/

}
body div{

  -webkit-tap-highlight-color: transparent;
  /*-webkit-touch-callout: none;*/

}
body div:focus{
  outline: none !important;
}
.info-box-button{
  display: inline-flex;
  height: auto !important;
}
.strech{
  width: 100%;
}
.transaction-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  box-shadow: 0 0 1px 1px var(--grey7);
  padding: 10px 10px;
  border-radius: var(--radius);
  margin-top: 4px;
}
.transaction-group {
  /*margin-bottom: 90px;*/
  margin-top: 30px;
  padding: 50px 13px 13px;
}
.action-button {
  padding: 0!important;
  height: auto !important;
}
.phone-input-desc {
  color: var(--label-primary);
  font-size: var(--txt-sm);
  padding-left: 8px;
  display: block;
}
.vue-tel-input {
  background-color: var(--grey7);
  border-radius: 12px;
  display: inline-flex;
  border: none !important;
  text-align: left;
  width: 100%;
  height: 38px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.register-confirmButton{
  width: 40%;
  display: inline-flex;
  padding: 11px 0 8px 0;
  background-color: var(--yellow);
  border-radius: 0 12px 12px 0;
  text-align: center;
  align-content: center;
  justify-content: center;
  font-weight: 600;
}
.register-buttonSpace {
  width: 60%;
  border-radius: 12px 0 0 12px !important;
}
.vti__input {
  display: inline-flex !important;
  background-color: var(--grey7) !important;
  border-radius: 12px !important;
  font-size: var(--txt-md);
}
.vue-tel-input:focus-within {
  box-shadow: none !important;
}
.vti__dropdown:hover{
  background-color: var(--grey7);
}
.ht-30{
  height: 30px !important;
}
.wd-p100{
  width: 100% !important;
}
.wd-p90{
  width: 90% !important;
}
.flx {
  display: flex;
}
.input-group {
  grid-template-columns:max(10%,30px) 76% 14%;
}
.input-group {
  grid-template-columns:max(10%,30px) 76% 14%;
}
.has-no-title {
   grid-template-areas: "pr co co" !important;
   grid-template-rows: 38px!important;
   height: auto !important;
 }
.slide-fade-slow-enter-active {
  transition: all .4s ease;
}
.slide-fade-slow-leave-active {
  transition: all .1ms ease;
}
.slide-fade-slow-enter, .slide-slow-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.slide-rl-enter-active {
  transition: all .4s ease;
}
.slide-rl-leave-active {
  transition: all .4ms ease;
}
.slide-rl-enter, .slide-rl-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100px);
  opacity: 0;
}
.slide-lr-enter-active {
  transition: all .4s ease;
}
.slide-lr-leave-active {
  transition: all .4ms ease;
}
.slide-lr-enter, .slide-lr-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100px);
  opacity: 0;
}
.transaction-group-body{
  height: 100%;
  overflow: scroll;
}
</style>

