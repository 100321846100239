<template>
<div>
    <div class="input-group has-postxt w-margin" :class="$t('font')">
      <div class="input-desc"></div>
      <div :class="BtnColor" class="input-pos txt-xxl j-i-center a-i-center j-c-center cursor" @click="validatePhoneNumber()">
        <i  v-if="!PhoneHasErrors && !PhoneValidated && !CanValidate" class="fal fa-info-circle pd-t-3"></i>
        <i  v-if="PhoneValidated" class="fal fa-check-circle pd-t-3"> </i>
        <i  v-if="!PhoneValidated && CanValidate && PhoneHasErrors" class="fal fa-exclamation-circle pd-t-3"></i>
        <i  v-if="!PhoneValidated && CanValidate && !PhoneHasErrors" class="fal fa-question-circle pd-t-3"></i>
      </div>
      <div class="input-area">
        <vue-tel-input v-if="HasCountry" v-model="phone" @validate="isValid" :defaultCountry="GetCountry" :inputOptions="inputOptions" :dropdownOptions="dropdownOptions"></vue-tel-input>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getDomain} from "@/support/support";
import {HideLoading, ShowLoading} from "@/support/loading";
import {notifyInfo} from "@/support/notification";
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


export default {
  name: "inputPhone",
  emits: ['validated'],
  components:{
    VueTelInput
  },
  props:{
    setType:{
      default(){
        return 2;
      }
    }
  },
  data(){
    return {
      isSetCountry:false,
      country:'',
      canValidate:false, //input phone
      phoneIsValidated:false, // twillio validation
      phoneHasErrors:false,
      validatedPhone:'',
      phone:'',
      inputOptions:{
        placeholder:this.$t('enter_mobile_phone'),
        showDialCode:true,
      },
      dropdownOptions:{
        width:'80vw',
        showFlags:true,
        showSearchBox:false,
      },
    }
  },
  mounted() {
    this.validatedPhone = '';
    this.phoneHasErrors = false;
    this.country = getDomain();
    this.getIpInfo();
  },
  computed:{
    ...mapGetters('COLLECTOR',['getForm']),
    ...mapGetters('DISCOUNT',{_getForm:'getForm'}),
    CanShowPhoneInfo(){
      if(!this.PhoneHasErrors && !this.PhoneValidated && !this.CanValidate){
        return true;
      }
      return false;
    },
    CanValidatePhone(){
      if(!this.PhoneValidated && this.CanValidate && this.PhoneHasErrors){
        return true;
      }
      if(!this.PhoneValidated && this.CanValidate && !this.PhoneHasErrors){
        return true;
      }
      return false;
    },
    BtnColor(){
      if(this.PhoneValidated){
        return 'green';
      }
      if(!this.PhoneHasErrors && !this.PhoneValidated && !this.CanValidate){
        return 'blue t-white-txt';
      }
      if(!this.PhoneValidated && this.CanValidate && this.PhoneHasErrors){
        return 'red';
      }
      if(!this.PhoneValidated && this.CanValidate && !this.PhoneHasErrors){
          return 'yellow';
      }
      return 'grey7';
    },
    Form(){
      if(this.setType===1){
        return this._getForm;
      }
      return this.getForm;
    },
    PhoneNumber(){
      return this.Form.phone_number;
    },
    GetCountry(){
      return this.country;
    },
    HasCountry(){
      return this.isSetCountry;
    },
    CanValidate(){
      return this.canValidate;
    },
    PhoneValidated(){
      return (this.PhoneNumber === this.validatedPhone && this.phoneIsValidated && this.canValidate);
    },
    PhoneHasErrors(){
      return this.phoneHasErrors;
    }
  },
  watch:{
    phone:function(val){
      this.resetValidation();
      this.setPhoneNumber(val);
    }
  },
  methods:{
    ...mapActions('COLLECTOR',['_updateFormValue']),
    ...mapActions('DISCOUNT',{__updateFormValue:'_updateFormValue'}),
    ...mapActions('GENERAL',['_validatePhone']),
    info(message){
      return notifyInfo(message);
    },
    resetValidation(){
          this.phoneIsValidated=false;
          this.phoneHasErrors=false;
          this.validatedPhone='';
          this.$emit('validated',false);
    },
    isValid(e){

      this.canValidate = e.valid;
      if(e.countryCode !==undefined){
        this.country = e.countryCode;
      }
    },
    updateValue(name,value){
      let data ={};
      data[name] = value;
      if(this.setType===1){
        return this.__updateFormValue(data);
      }
      return this._updateFormValue(data);
    },
    setPhoneNumber(input){
      this.updateValue('phone_number',input);
      return true;
    },
    getIpInfo(){
      let self = this;
      fetch('https://ipinfo.io/json?token=3dca986f555bc1', { headers: { 'Accept': 'application/json' }})
          .then((resp) => resp.json())
          .catch(() => {
            HideLoading();
            self.country = getDomain();
            self.isSetCountry = true;
          })
          .then((resp) => {
            HideLoading();
            self.country=resp.country;
            self.isSetCountry = true;
          });
    },
    validatePhoneNumber(){
      if(this.CanShowPhoneInfo){
        return this.info('grant_on_mobile_txt');
      }
      if(!this.CanValidatePhone){
        return false;
      }
      this.setPhoneNumber(this.phone);
      let phone = this.PhoneNumber;
      ShowLoading();
      this._validatePhone({phone})
          .then(()=>{
            HideLoading();
            this.validatedPhone = this.PhoneNumber;
            this.phoneIsValidated = true;
            this.phoneHasErrors =false;
            this.$emit('validated',true);
          })
          .catch(()=>{
            HideLoading();
            this.validatedPhone = '';
            this.phoneIsValidated = false;
            this.phoneHasErrors =true;
            this.$emit('validated',false);

          });
    },
  }
}
</script>

<style scoped>

</style>