<template>
  <transition name="slide-fade">
    <div class="input-group  has-pre has-no-title wd-p100" :class="$t('font')" v-if="ShowForm">
      <div class="input-pre grey7 txt-xxl j-i-center a-i-center j-c-center">
          <i class="fal fa-search pd-t-3 txt-md1 grey3-txt" @click="hide()"></i>
      </div>
      <div class="input-area">
        <input  type="text" v-model="inputData">
        <span @click="clearField()" v-if="HasText" class="fa fa-times-circle grey3-txt pd-l-2 pd-r-8 pd-t-1 txt-md1" style="max-width: 9%;"></span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "inputSearch",
  emits:['hide','input'],
  props:{
    visible:{
      default(){
        return false;
      }
    },
  },
  mounted() {
    this.show = this.visible;
  },
  data(){
    return {
      inputData:'',
      show:true,
    }
  },
  computed:{
    ShowForm(){
      return this.show;
    },
    HasText(){
      return this.inputData.length>0;
    }
  },
  watch:{
    visible:function (val){
      this.show =val;
    },
    inputData:function (val){
      this.$emit('input',val);
    }
  },
  methods:{
    hide(){
      this.show=false;
      this.$emit('hide',true);
    },
    clearField(){
       this.inputData = '';
    }
  }
}
</script>

<style scoped>

</style>