import Api from "@/apis/General/GeneralApi";
import {logIn, logOut} from "@/support/auth";
import {ApiResponseHandler} from "@/support/apiResponseHandler";
import {HideLoading, ShowLoading} from "@/support/loading";


export const _logIn = (_,form)=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.login(form).then(response => {
            HideLoading();
            let result = ApiResponseHandler(response,true);
            logIn(result,form.email,form.remember_me);
            resolve (response);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _logOut = (_,form)=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.logout(form).then(response => {
            HideLoading();
            ApiResponseHandler(response,true);
            logOut();
            resolve (response);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}

export const _openSession = ({commit})=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.openSession().then(response => {
            HideLoading();
            let result = ApiResponseHandler(response,true);
            commit('SET_SESSION',result);
            resolve (response);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _closeSession = ({commit})=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.closeSession().then(response => {
            HideLoading();
            let result = ApiResponseHandler(response,true);
            commit('SET_SESSION',result);
            resolve (response);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}


export const _sessionReport = ({commit})=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.sessionReport().then(response => {
            HideLoading();
            let result = ApiResponseHandler(response,true);
            commit('SET_REPORT',result);
            resolve (response);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}


export const _getSettings = ({commit})=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.settings().then(response => {
            HideLoading();
            let result = ApiResponseHandler(response,true);
            commit('SET_RESULT',result);
            resolve (response);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _validatePhone = (_,form)=>{
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.phoneValid(form).then(response => {
            HideLoading();
            let result = ApiResponseHandler(response,true);
            resolve (result);
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error,true));
        });

    });
}
export const _clearSettings = ({commit})=> {
    commit('CLEAR_SETTINGS');
}
