export default {
    /* Data Is Filed From Cloud */
    SCAN_RESULT:{
        id: '',
        rate: '',
        balance: '',
        merchant: '',
        minimum_purchase_amount: '',
    },
    PROCESS_RESULT:{
        discount: '',
        discount_value: '',
        check_amount: '',
        check_number: '',
        cash_to_pay: '',
    },
    GRANT_CHOOSE_RESULT:[],
    GRANT_RESULT:{
        hash: "",
        link: "",
        value: '',
    },
    TRANSACTIONS:[],
    SUBMIT_FORM:{},
    FORM:{
        card_id:'',
        hash:'',
        check_amount:'',
        check_number:'',
        phone_number:'',
    },
    _CALLER_METHOD:'',



}