import { render, staticRenderFns } from "./cashier_report.vue?vue&type=template&id=84f8203a&scoped=true&"
import script from "./cashier_report.vue?vue&type=script&lang=js&"
export * from "./cashier_report.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84f8203a",
  null
  
)

export default component.exports