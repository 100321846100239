<template>
  <div>
    <div class="nav-bg blur" v-if="ShowAddonButtons" @click="addonButtons=false"></div>
    <notification-window></notification-window>
    <app-window id="7" close-class="grey3-txt" :hide-close="false" frame-class="t-white">
      <template #content>
        <cashier-report></cashier-report>
      </template>
    </app-window>

    <app-window id="5" close-class="grey3-txt" :hide-close="true">
      <template #content>
        <div class="grid-row-2 g-r-gap-10 j-c-center a-i-center j-i-center">
          <div class="txt-md t-black-txt pd-20">{{$t('open_continue_session_txt')}}</div>
          <div class="yellow pd-10 radius" @click="continueSession()">{{$t('open_continue_session')}}</div>
          <div class="blue pd-10 radius" @click="openNewSession()">{{$t('open_new_session')}}</div>
        </div>
      </template>
    </app-window>
    <app-window id="6" close-class="grey3-txt" :hide-close="true">
      <template #content>
        <div class="grid-row-2 g-r-gap-10 j-c-center a-i-center j-i-center">
          <div class="txt-md t-black-txt pd-20">{{$t('keep_session_open_txt')}}</div>
          <div class="yellow pd-10 radius" @click="keepSessionOpen()">{{$t('keep_session_open')}}</div>
          <div class="red pd-10 radius" @click="closeSession()">{{$t('close_session')}}</div>
        </div>
      </template>
    </app-window>
    <div class="main" v-if="ShowBar" >
      <div class="circle" @click="fetchUpdate()">
        <div v-if="ShowAddonButtons" class="nav-btn-0" >
          <div class="nav-btn" :class="{'blue t-white-txt':FetchUpdatePossible,'grey7 grey3-txt':!FetchUpdatePossible}">
            <i class="fad fa-sync"></i>
          </div>
        </div>
      </div>
      <div class="circle" @click="logout()">
        <div class="nav-btn-1" v-if="ShowAddonButtons">
          <div class="nav-btn yellow">
            <i class="fad fa-lock-alt"></i>
          </div>
        </div>
      </div>
      <div class="circle" v-for="(ITEM,index) in AllowedFunctions" :key="index" :id="ITEM.link">
        <div :class="'nav-btn-'+getIndex(index)" v-if="ShowAddonButtons" :id="ITEM.link" v-touch:tap="tapEventRoute">
          <div class="nav-btn" :class="ITEM.color" :id="ITEM.link" >
            <i :class="ITEM.icon" :id="ITEM.link"></i>
          </div>
        </div>
      </div>



      <div class="circle"  @click="routeTo('Apps')" v-touch:touchhold="toggleAddonButtons" >
        <div class="navigator">
          <div class="nav-square-inner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex';
import support from "@/support/support";
import config from "@/config";
import NotificationWindow from "@/views/components/notification_window";
import {isUserLoggedIn, logOut} from "@/support/auth";
import router from "@/router";
import AppWindow from "@/views/components/application_window";
import {awClose, awOpen} from "@/support/applicationWindow";
import CashierReport from "@/views/components/cashier_report";


export default {
  name: "appBar",
  components: {CashierReport, AppWindow, NotificationWindow},
  data(){
    return {
      enableFetchUpdate:true,
      show:true,
      addonButtons:false,
    }
  },
  mounted() {
    if(!this.isSessionOpen){
      return this.openNewSession();
    }
    let self = this;
    self.show = window.innerHeight >= 600;
    this.addonButtons = false;

    addEventListener('resize', () => {
      return self.show = window.innerHeight >= 600;
    });
    if(!isUserLoggedIn()){
      return logOut();
    }
    this.fetchUpdate();
  },
  computed: {
    ...mapState('GENERAL',['SETTINGS','STATIC_ICON_ARRAY']),
    isSessionOpen(){
      return this.Settings.session_open;
    },
    ShowAddonButtons(){
      return (Boolean(+this.addonButtons) && this.ShowBar);
    },
    ShowBar(){
      return this.show;
    },
    employeeActive(){
      return (this.SETTINGS.active===1);
    },
    updateInterval(){
      return config.dataUpdateIntervalInMinutes;
    },
    Settings(){
      return this.SETTINGS;
    },
    Active(){
      return this.SETTINGS.active;
    },
    FetchUpdatePossible(){
      return this.enableFetchUpdate && this.canRequestUpdate();
    },
    AllowedFunctions(){
      let data = this.STATIC_ICON_ARRAY;

      data = data.filter(obj => {
        if(!this.Active){
          return false;
        }
        if(obj.tag ===1 && this.DiscountActive){
          return obj;
        }
        if(obj.tag ===2 && this.CollectorActive){
          return obj;
        }
        if(obj.tag ===3 && this.PartnerActive){
          return obj;
        }
        if(obj.tag ===0){
          return obj;
        }
      });
      return data.slice(0,4);
    },
    PartnerActive(){
      return this.SETTINGS.PARTNERS.enabled===1;
    },
    DiscountActive(){
      return this.SETTINGS.DISCOUNT.enabled===1;
    },
    CollectorActive(){
      return this.SETTINGS.COLLECTOR.enabled===1;
    },
  },
  methods:{
    ...mapActions('GENERAL',['_getSettings','_logIn','_logOut','_openSession','_closeSession']),
    getIndex(index){
      if(index===0){
        return 2;
      }
      if(index===1){
        return 3;
      }
      return index+2;
    },
    tapEventRoute(e){
      let section = String(e.target.id);
      if(router.currentRoute.name ===section) {
        return this.addonButtons =false;
      }
      return this.$router.push({ name: section });
    },
    toggleAddonButtons(e){
      if(e){
        this.addonButtons = !this.addonButtons;
      }
    },
    isActive(route){
      return this.$route.name===route;
    },
    routeTo(section){

      if(router.currentRoute.name ===section) {
          return this.addonButtons =false;
      }
      return this.$router.push({ name: section });
    },
    canRequestUpdate(){
      return support.canRequestUpdate();
    },
    fetchUpdate(){
      if(!this.canRequestUpdate()){
        return false;
      }

      this._getSettings().then(response=>{
        if(response.status===200){
          this.enableFetchUpdate =false;
          return support.setCloudDataUpdatedToken();
        }
      });
    },
    continueSession() {
      return awClose();
    },
    openNewSession(){
      return this._openSession().then(()=>{
         awClose();
      }).then(()=>{
        this._getSettings().then(response=>{
          if(response.status===200){
            this.enableFetchUpdate =false;
            return support.setCloudDataUpdatedToken();
          }
        });
      });
    },
    getLoggedOut(){
      this._logOut().then(()=>{
        return this.routeTo('login');
      });
    },
    keepSessionOpen(){
      return this.getLoggedOut();
    },
    closeSession() {
      let self = this;
      return this._closeSession().then(()=>{
          return self.getLoggedOut();
      });
    },
    logout() {
      if(this.isSessionOpen){
        return awOpen(6);
      }
      return this.getLoggedOut();

    },
  }
}
</script>

<style scoped>
.nav-bg{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 98;
  animation: fadeIn 1s;
}
.main {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 250px;
  height: 250px;
  bottom: -10vh;
  z-index: 99;
}

.circle {
  position: absolute;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  width: min(60px,12vw);
  height: min(60px,12vw);
  border-radius: 50%;
  z-index: 99;
  cursor: pointer;
}
.nav-btn-0{
  transform: rotate(180deg);
}
.nav-btn-1{
  transform: rotate(148deg);
}
.nav-btn-2{
  transform: rotate(110deg);
}
.nav-btn-3{
  transform: rotate(74deg);
}
.nav-btn-4{
  transform: rotate(34deg);
}
.nav-btn-5{
  transform: rotate(360deg);
}
.circle:nth-child(1) {
  transform: rotate(178deg) translateX(100px);
  animation: fadeIn 800ms;
  z-index: 99;
}
.circle:nth-child(2) {
  transform: rotate(214deg) translateX(100px);
  animation: fadeIn 1200ms;
  z-index: 99;
}

.circle:nth-child(3) {
  transform: rotate(251deg) translateX(100px);
  animation: fadeIn 1600ms;
  z-index: 99;
}

.circle:nth-child(4) {
  transform: rotate(288deg) translateX(100px);
  animation: fadeIn 2000ms;
  z-index: 99;
}

.circle:nth-child(5) {
  transform: rotate(325deg) translateX(100px);
  animation: fadeIn 2400ms;
  z-index: 99;
}
.circle:nth-child(6) {
  transform: rotate(2deg) translateX(100px);
  animation: fadeIn 2800ms;
  z-index: 99;
}
.nav-btn {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  cursor: pointer;
  font-size: min(22px,6vw);
  width: min(60px,12vw);
  height: min(60px,12vw);
  z-index: 99;
}

.nav-square-inner {
  width: min(24px,4vw);
  height: min(24px,4vw);
  border:2px solid white;
  border-radius: 5px;
  z-index: 99;
}
.navigator {
  background-color: rgba(232, 235, 239, .54);
  height: min(60px,12vw);
  width: min(60px,12vw);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  box-shadow: 0 0 5px 0 rgba(0, 30, 102, .02);

}
@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

</style>