import config from "@/config";
import router from "@/router";
import rootStore from '@/store/';
import app from "@/apis/General/GeneralApi";
import state from "@/store/state";
import {notifyError, notifySuccess, notifyWarning} from "@/support/notification";
import {HideLoading} from "@/support/loading";
import {logOut} from "@/support/auth";
import {storageGet, storagePut, storageRemove} from "@/support/storage";

export const convertData = (data)=> {
    const returnData = {};
    Object.entries(data).forEach(([key]) => {
        returnData[data[key].id] = data[key].val;
    });
    return returnData;
}
export const stripSpace=(variable)=>{
    return variable.replace(/\s/g, '');
}

export const convertForRegistrator=(data)=>{
    const returnData = [];

    Object.entries(data).forEach(([key,val]) => {
        returnData.push({id:key,val:val});
    });
    return returnData;
}
export const roundTo=(num,roundTo=2)=>{

    return +(Math.round(num + "e+"+roundTo)  + "e-"+roundTo);
}
export const deviceLog = () => {
    return navigator;
}
/*
* @returns boolean
*/
export const canRequestUpdate = () => {



    let now = new Date();
    let _last = localStorage.getItem('_last');
    let lastUpdate = new Date(_last);
    if(lastUpdate ===null || lastUpdate ===undefined){

        return true;
    }
    if(now.getFullYear() !== lastUpdate.getFullYear()){

        return true;
    }
    if(now.getMonth() !== lastUpdate.getMonth()){

        return true;
    }
    if(now.getDate() !== lastUpdate.getDate()){

        return true;
    }
    if(now.getHours() !== lastUpdate.getHours()){

        return true;
    }

    if(now.getMinutes() !== lastUpdate.getMinutes()){

        let differance =  now.getMinutes()-lastUpdate.getMinutes();

        let allowedDifferance = config.dataUpdateIntervalInMinutes;
        if(differance<0){

            /* Something is not ok there so update */
            return true;
        }
        return (differance > allowedDifferance);
    }
}
export const setCloudDataUpdatedToken = () => {
    let now = new Date();
    return localStorage.setItem(config.dataVersion,now);
}
export const space =(str, after=4)=>{

        if (!str) {
            return false;
        }
        after = after || 4;
        let v = String(str).replace(/[^\dA-Z]/g, ''),
            reg = new RegExp(".{" + after + "}", "g");
        return v.replace(reg, function (a) {
            return a + ' ';
        });

}
export const autoColorMode=()=>{
    return localStorage.getItem('day-night-mode');
}
export const setColorMode=()=>{
    let dayNightMode = localStorage.getItem('day-night-mode');

    if(dayNightMode){

        if(window.matchMedia && window.matchMedia('(prefers-color-scheme:dark)').matches){
            //Dark Mode Here
            return document.documentElement.setAttribute('data-theme', 'dark');
        }
        return  window.matchMedia('(prefers-color-scheme:dark)').addEventListener('change',ev => {
            const newColorScheme = ev.matches ? "dark" : "light";
            document.documentElement.setAttribute('data-theme', newColorScheme);
        });
    }
    let currentTheme = localStorage.getItem('color-mode')
        ? 'dark'
        : 'light';
    if (currentTheme) {
        document.documentElement.setAttribute('data-theme', currentTheme);
    }
}
export const encode = (string)=>{
    return window.btoa(window.btoa(string));
} // encode a string
export const decode = (string)=>{
    return window.atob(window.atob(string));
} // decode the string

export const httpStatusActions =(response,quiteMode=false)=>{

    let status = 0;
    let data =null;
    let message = 'error';
    if(response.data!==undefined){
        if(response.data.message!==undefined){
            message = response.data.message;
        }
    }
    if(response.response!==undefined){
        if(response.response.data!==undefined){
            if(response.response.data.message!==undefined){
                message = response.response.data.message;
            }
            if(response.response.data[0]!==undefined){
                if(response.response.data[0].message!==undefined){
                    message = response.response.data[0].message;
                }

            }
        }
    }


    if(response.status !==undefined){ //Success potentially Potential 200 Range
        status = response.status;
        data = response.data;
        if(data.data!==undefined){
            data = data.data;
        }
    }

    if (response.response) {
        status = response.response.status;
        data = response.response.data;
        if(data.data!==undefined){
            data = data.data;
        }
    } else if (response.request) {
        status = response.request.status;
    } else {
        if(!quiteMode) {
            return notifyError(message);
        }
    }

    if(status === 200) { //Success
        if(!quiteMode){
            notifySuccess();
            return data;
        }
        return data;
    }
    if(status === 401) { //Non Logged User
        logOut();
    }
    if(status === 422) { //Error
        HideLoading();
        if(!quiteMode) {
            notifyError(message);
        }
        return message;
    }
    if(status===404){
        HideLoading();
        if(!quiteMode) {
            notifyError(message);
        }
        return message;
    }
    if(status===500){
        HideLoading();
        if(!quiteMode) {
            notifyError();
        }
        return message;
    }
    if(status ===0){ //Offline
        HideLoading();
        if(!quiteMode) {
            notifyWarning('operation_faild_becouse_of_connection');
        }
        return (router.currentRoute.name !=='home') ? router.push({name: 'home'}) : false;
    }


}
export const info=(message)=>{
    rootStore.dispatch('infoMessage',message,{ root: true });
}
export const colorPalette = (number_of_records)=>{
    if(!number_of_records){
        number_of_records = getColorCount();
    }
    let palette = [];
    let i;
    for(i=1;i<=number_of_records;i++){
        palette.push('grcolor'+i);
    }
    return palette;

}
export const setLang =(lang)=>{
    storagePut('APP_LANG',lang.toLowerCase());
}
export const getLang =()=>{
    return (!storageGet('APP_LANG')) ? config.defaultLang :storageGet('APP_LANG');
}
export const getDomainData =(domain)=>{

    return rootStore.getters._getDomain(domain);
}
export const setDomain =(location)=>{
    if(location !==undefined){
        storagePut('APP_LOCATION',location.toLowerCase());
    }

}
export const resetDomain =()=>{
    storageRemove('APP_LOCATION');
    storageRemove('APP_LANG');
}
export const getDomain =()=>{
    let domain = storageGet('APP_LOCATION');//localStorage.getItem('APP_LOCATION');
    if(!domain){
        logOut();
        resetDomain();
        return (router.currentRoute.name !=='setDomain') ? router.push({name: 'setDomain'}) : false;
    }
    return domain;

}
export const getBrowserLocales=(options = {}) =>{
    const defaultOptions = {
        languageCodeOnly: false,
    };

    const opt = {
        ...defaultOptions,
        ...options,
    };

    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
}
export const checkAndNotifyUpdate=()=>{
    let settings = getAppSettings();

    if(parseFloat(settings.latest_app_version) > parseFloat(config.appVersion)){
        let updateData = {OS:config.OS,VERSION:settings.latest_app_version};
        return rootStore.dispatch('updateMessage',updateData, { root: true });
    }
    return false;
}
export const getAppSettings=()=>{

    let savedSettings = storageGet('app_settings',true);//JSON.parse(localStorage.getItem(encode('app_settings')));
    let defaultSettings= state.DEFAULT_SETTINGS;
    return (savedSettings) ? savedSettings : defaultSettings;
}
export const fetchSettings=()=>{
    return new Promise((resolve, reject) => {
        app.appSettings().then(response=>{
            storagePut('app_settings',response.data);
            resolve(true);
        }).catch(()=>{
            reject(false);
        });
    });

}
export const getColorCount=()=>{
    return getAppSettings().color_count;
}
export const getDollar=()=>{ //returns currency symbol
    let domainData = getDomainData(getDomain());
    return (domainData.currency === undefined) ? "$" : domainData.currency;
}
export const setLocale=(locale='S1')=>{
    return storagePut('general_settings',locale);
    // return localStorage.setItem(encode('general_settings'), locale);
}
export const resetLocale=(locale='S2')=>{
    return storagePut('general_settings',locale);
    // return localStorage.setItem(encode('general_settings'), locale);
}
export const getLocale=()=>{
    return storageGet('general_settings');
    // return localStorage.getItem(encode('general_settings'));
}
export default {
    namespaced: true,
    getLang,
    setLang,
    convertData,
    convertForRegistrator,
    roundTo,
    deviceLog,
    space,
    canRequestUpdate,
    setCloudDataUpdatedToken,
    setColorMode,
    stripSpace,
    encode,
    decode,

    httpStatusActions,
    info,
    colorPalette,
    autoColorMode,
    setDomain,
    getDomain,
    getDomainData,
    getBrowserLocales,
    getAppSettings,
    getColorCount,
    getDollar,
    setLocale,
    getLocale,
    resetLocale,



}


