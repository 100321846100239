import { render, staticRenderFns } from "./partnerPoints.vue?vue&type=template&id=67f3621d&scoped=true&"
import script from "./partnerPoints.vue?vue&type=script&lang=js&"
export * from "./partnerPoints.vue?vue&type=script&lang=js&"
import style0 from "./partnerPoints.vue?vue&type=style&index=0&id=67f3621d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f3621d",
  null
  
)

export default component.exports