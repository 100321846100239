import {storageGet, storageHas} from "@/support/storage";

export const __TRANSACTION_STORAGE_KEY =()=>{
    return 'DISCOUNT.TRANSACTIONS';
}


export const getSubmitForm = (state)=> {
    return state.SUBMIT_FORM;
}
export const getFormValue = (state) => (key)=> {
    return state.FORM[key];
}
export const getForm = (state)=> {
    return state.FORM;
}
export const getResult = (state)=>(key='SCAN')=> {
    return state[key+'_RESULT'];
}
export const getChooseCardList = (state)=>{
      return state.GRANT_CHOOSE_RESULT;
}
export const storeGetTransactions = (state)=>{
    let STORAGE_TRANSACTIONS =[];
    let STATE_TRANSACTIONS = state.TRANSACTIONS;
    if(storageHas(__TRANSACTION_STORAGE_KEY())){
        STORAGE_TRANSACTIONS = storageGet(__TRANSACTION_STORAGE_KEY(),true,true);
    }
    if(STORAGE_TRANSACTIONS.length>STATE_TRANSACTIONS.length){
        return STORAGE_TRANSACTIONS;
    }
    return STATE_TRANSACTIONS;
}