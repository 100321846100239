<template>
  <div>
    <div class="wd-p100 j-i-center j-c-center a-i-center flex-inline txt-xl grey7-txt pd-t-10 pd-b-10" :class="$t('font_b')">{{$t('give_discount_card')}}</div>
    <app-window :id="3" close-class="grey3-txt" frame-class="t-white">
      <template #content>
        <div class="flex-center">
          <q-rgenerator  class-name="flex-center" render-as="canvas" :value="QrValue" size="300" ></q-rgenerator>
        </div>
        <div class="txt-md t-center w-margin grey4-txt" :class="$t('font')" style="line-height: 18px;"><i class="far fa-exclamation-triangle"></i> {{$t('make_screen_brighter')}}</div>
      </template>
    </app-window>
    <div class="flex-cnt wd-100p ht-100p j-c-start a-i-center flex-dir-col pd-t-30" >
      <div class="flex-center window-max-wd flex-dir-col j-c-center j-c-center a-i-center">
        <div class="action-content-wrap" id="focus">
          <div class="info-box">
            <div class="info-box-content">
              <ul>
                <li><span :class="$t('font')" v-on:click="info('issuer_company')"><i class="fal fa-building txt-lg1" style="margin-right: 24px;"></i></span> <span class="en txt-md1">{{ScanResult.merchant}}</span></li>
                <li><span :class="$t('font')" v-on:click="info('discount_percent')"><i class="far fa-function txt-lg1 " style="margin-right: 24px;"></i></span><span class="en txt-md1">{{Rate}}<i v-if="Rate>0" class="fal fa-percent"></i></span></li>
                <li class="mg-t-20"><input-switch @change="setPhoneMode" :state="IsMobileMode" set-class="flex-inline txt-3xl"></input-switch> </li>
              </ul>
            </div>
            <div class="info-box-icon">
              <i v-if="!ShowResetButton" class="fal fa-info-circle"></i>
              <btn-square v-if="ShowResetButton"  icon="fal fa-redo blue-txt" color="t-white" @click.n.native="getFresh()"></btn-square>
            </div>
            <div class="info-box-button a-i-center">
              <div class="action-button">
                <btn-square v-if="ShowQrOption" icon="fal fa-qrcode t-black-txt" color="yellow" @click.n.native="showQr()"></btn-square>
                <btn-square v-if="ShowScanOption" icon="fal fa-credit-card-front t-white-txt" color="indigo" @click.n.native="scan()"></btn-square>
                <btn-square :has-timer="true" :set-timer="2" v-if="ShowProcessOption" icon="fal fa-check-circle t-white-txt" color="blue" @click.n.native="process()"></btn-square>
              </div>
            </div>
          </div>
          <input-phone  :set-type="1" v-if="(IsMobileMode && !HasCardsList)||IsIncorrectPhone"  @validated="setPhoneValidationStatus"></input-phone>
          <input-number v-if="!HasCardsList" :defaultInput="CheckAmount" :required="true" :is-disabled="DisableCheckAmount" :title="$t('check_amount')" symbol="$" @hasInput="setAmountToPay" ></input-number>
          <div class="input-group has-pre w-margin" :class="$t('font')" v-if="!HasCardsList">
            <div class="input-desc">{{$t('check_number')}}</div>
            <div class="input-pre grey7 en-sb txt-xl "><i class="fal fa-receipt"></i></div>
            <div class="input-area"><input  :value="CheckNumber" class="txt-xl text" type="text" @input="setCheckNumber">
              <span v-if="CheckNumber.length>0" class="fa fa-times-circle grey3-txt pd-l-2 pd-r-8 pd-t-1 txt-lg" style="max-width: 9%;"  v-on:click="resetCheckNumber()"></span>
            </div>
          </div>
          <grant-card-list v-if="!ShowQrOption" :empty="IsCardListEmpty"></grant-card-list>
          <discount-transactions v-if="!HasCardsList||ShowQrOption" get-type="GRANT"></discount-transactions>
        </div>
      </div>

      <app-bar></app-bar>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";


import AppBar from "@/views/components/apps_bar";
import QRgenerator from "qrcode.vue";
import InputNumber from "@/views/components/input_number";
import BtnSquare from "@/views/components/action_button_square";
import {notifyInfo} from "@/support/notification";
import DiscountTransactions from "@/views/components/discount_transactions";
import AppWindow from "@/views/components/application_window";
import GrantCardList from "@/views/components/grant-card-list";
import {awOpen} from "@/support/applicationWindow";
import InputPhone from "@/views/components/input_phone";
import InputSwitch from "@/views/components/input-switch";


export default {
  name: "GrantCard",
  components:{
    InputSwitch,
    InputPhone,
    GrantCardList,
    AppWindow,
    DiscountTransactions,
    BtnSquare,
    InputNumber,
    QRgenerator,
    AppBar,
  },
  data(){
    return{
      cardListEmpty:false,
      correctPhone:false,
      displayQr:false,
      mobileSwitch:false,
      phoneIsValidated:false,
    }
  },
  mounted(){
    this.getFresh();
  },
  computed:{
    ...mapState('GENERAL',['SETTINGS']),
    ...mapGetters('DISCOUNT',['getForm','getFormValue','getResult','getSubmitForm','getChooseCardList']),
    GrantResult(){
      return this.getResult('GRANT');
    },
    GrantHasResult(){
      return (this.GrantResult.hash.length>0);
    },
    HasCardsList(){
        return this.getChooseCardList.length>0;
    },
    IsCardListEmpty(){
      return this.cardListEmpty;
    },
    HasCardSelected() {
      return this.Form.card_id>0;
    },
    ShowScanner(){
      return this.enableScanner;
    },
    Form(){
      return this.getForm;
    },
    ScanResult(){
      return this.getResult('SCAN');
    },
    CanScan(){
      return (this.Form.check_amount>0);
    },
    CheckAmount(){
      return (this.Form.check_amount>0)?this.Form.check_amount:0;
    },
    CheckNumber(){
      return this.Form.check_number;
    },
    MinimumPurchase() {
      return this.ScanResult.minimum_purchase_amount;
    },
    Rate(){
      return this.ScanResult.rate;
    },
    CashToPay(){
      return this.CheckAmount-(this.CheckAmount*this.Rate/100);
    },
    ShowScanOption(){
      if(!this.IsMobileMode){
        return (!this.HasCardSelected && this.CanScan && !this.HasCardsList && !this.ShowQrOption);
      }
      if(this.phoneIsValidated){
        return (!this.HasCardSelected && this.CanScan && !this.HasCardsList && !this.ShowQrOption);
      }
      return false;

    },
    ShowProcessOption(){
      if(!this.IsMobileMode){
        return (this.HasCardSelected && this.CanScan && !this.ShowQrOption);
      }
      if(this.phoneIsValidated){
        return (this.HasCardSelected && this.CanScan && !this.ShowQrOption);
      }
      return false;

    },
    DisableCheckAmount(){
      return (this.ScanResult.id.length>0);
    },
    ShowResetButton(){
      return (this.ShowProcessOption || (this.HasCardsList));
    },
    ShowQrOption(){
      if(!this.IsMobileMode) {
        return (this.GrantHasResult);
      }
      return false;
    },
    ShowQrCode(){
      if(!this.IsMobileMode) {
        return this.displayQr;
      }
      return false;
    },
    QrValue(){
      return this.GrantResult.hash;
    },
    IsMobileMode(){
      return this.mobileSwitch;
    },
    IsIncorrectPhone(){
      return this.correctPhone;
    }
  },
  methods:{
    ...mapActions('DISCOUNT',['_choose','_grant','_transfer','_freshForm','_updateFormValue']),
    setPhoneMode(input){
      this.getFresh();
      this.mobileSwitch = input;
      this.phoneIsValidated = false;
      if(!this.IsMobileMode){
        this.updateValue('phone_number','');
      }
    },
    setPhoneValidationStatus(status){
      this.phoneIsValidated = status;
    },
    scan(){
      return this._choose().then(function(){
        if(!this.HasCardsList){
            this.cardListEmpty = true;
        }
      });
    },
    showQr(){
      if(!this.IsMobileMode){
        awOpen(3);
      }
    },
    process(){
      let self = this;
      //If It's not already granted
      if(!this.GrantHasResult){
        //Mobile Mode
        if(this.IsMobileMode){
          return this._transfer().then(function(){
            return self.getFresh();
          }).catch(function(){
            return self.correctPhone = true;
          });
        }
        //Normal Mode
        if(!this.IsMobileMode){
          this.updateValue('hash',this.ScanResult.id);
          this._grant().then(()=>{
            return self.showQr();
          });
        }
      }
      return false;
    },
    info(message){
      return notifyInfo(message);
    },
    getFresh(){
      this.mobileSwitch = false;
      return this._freshForm();
    },
    updateValue(name,value){
      let data ={};
      data[name] = value;
      return this._updateFormValue(data);
    },
    setAmountToPay(input){
      this.updateValue('check_amount',input);
    },
    setCheckNumber(input){
      this.updateValue('check_number',input.target.value);
    },
    resetCheckNumber(){
      this.updateValue('check_number','');
    },
  }
}
</script>

<style scoped>

</style>