
export default {
    namespaced: true,
    debug:true,
    dataVersion:'_last',
    dataUpdateIntervalInMinutes:process.env.VUE_APP_FETCH_INTERVAL,
    nightModeStarts:20,
    nightModeEnds:7,
    defaultLang:'en',
    tokenName:process.env.VUE_APP_TOKEN_NAME,
    baseURL: process.env.VUE_APP_API,
    appVersion:process.env.VUE_APP_VERSION,




    //todo
    // vue add pwa
    // copy vue.config.js
    // Edit service worker

    //npm i -S vue2-touch-events touch
    //edit and import  main.js
    // npm install -D sass-loader sass
    // npm install node-sass

    //npm install vue-i18n vue2-touch-events sass-loader node-sass
}
