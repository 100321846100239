import config from "@/config";
import state from "@/store/state";
import {encode} from "@/support/support";
import rootStore from '@/store/';
import router from "@/router";
import {storageGet, storageHas, storagePut, storageRemove} from "@/support/storage";

export const getToken = ()=>{
    let STATE_TOKEN = state.TOKEN;
    let STORAGE_TOKEN = storageGet(config.tokenName);
    return (STATE_TOKEN)?STATE_TOKEN:STORAGE_TOKEN;
}
export const isUserLoggedIn = ()=>{

    if(storageHas(config.tokenName)){
        let DEVICE_ID = getDeviceId();
        let STORAGE_DEVICE_ID = storageGet('device_id');
        if(DEVICE_ID!==STORAGE_DEVICE_ID){
            return false;
        }
    }

    return Boolean(getToken());

}
export const resetLogin=()=>{
    storageRemove('email');
    storageRemove('remember_email');
    storageRemove('device_id');
    storageRemove('settings');


    storageRemove(config.tokenName);
    storageRemove(config.dataVersion);

    rootStore.dispatch('GENERAL/_clearSettings');

}
export const logIn = (token, email, remember)=>{

    //Reset
    resetLogin();
    let DEVICE_ID = getDeviceId();
    storagePut('device_id',DEVICE_ID);

    if(remember){
        storagePut('email',encode(email));
        storagePut(config.tokenName,token);
        return rootStore.dispatch('setToken',token);
    }
    rootStore.dispatch('setToken',token);
    return (router.currentRoute.name !=='home') ? router.push({name: 'home'}) : '';

}
export const logOut = () => {
    resetLogin();
    rootStore.dispatch('setToken',false);
    return (router.currentRoute.name !=='login') ? router.push({name: 'login'}) : false;
}
export const getDeviceId = () => {
    let navigator_info = window.navigator;
    let screen_info = window.screen;
    let uid = navigator_info.mimeTypes.length;
    uid += navigator_info.userAgent.replace(/\D+/g, '');
    uid += navigator_info.plugins.length;
    uid += screen_info.height || '';
    uid += screen_info.width || '';
    uid += screen_info.pixelDepth || '';
    return encode(uid);
}
export const authOnlyPage=()=>{
    if(!isUserLoggedIn()){
        return logOut();
    }
}

export default {
    namespaced: true,
    logIn,
    logOut,
    getDeviceId,
    authOnlyPage,
    isUserLoggedIn,
    getToken,
}