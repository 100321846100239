<template>
<div v-if="!isExpired">
  <countdown set-class="txt-xs grey3-txt" v-if="hasTimer" :minutes="setTimer" @expire="expire()"></countdown>
  <div :class="spinnerStatus?'grey7':color+' cursor'" class="flex-center square-btn j-i-center j-c-center a-i-center">
    <div>
      <i :class="icon" v-if="!spinnerStatus"></i>
      <i :class="randomAnimation" v-if="spinnerStatus"></i>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import {rand} from "@/support/helpers";
import Countdown from "@/views/components/countdown";

export default {
  name: "btnSquare",
  components: {Countdown},
  props:{
    id:{
      default(){
        return 333;
      }
    },
    color:{
      type:String,
      default(){
        return 'yellow';
      }
    },
    icon:{
      type:String,
      default(){
        return 'fal fa-qrcode';
      }
    },
    hasTimer:{
      default(){
        return false;
      }
    },
    setTimer:{
      default(){
        return 5;
      }
    }

  },
  data(){
    return {
      expired:false,
    }
  },
  computed:{
    ...mapGetters(['_loadingSpinnerStatus']),
     spinnerStatus(){
      return this._loadingSpinnerStatus(this.id);
     },

     randomAnimation(){
          let library = [
              'fad fa-heart fade red-light-txt',
              'fad fa-grin-hearts rotate purple-txt',
              'fad fa-smile-wink rotate3d indigo-txt',
              'fad fa-grin-tears rotate3d orange-txt',
              'fad fa-kiss-wink-heart rise red-light-txt'
          ];
          let numb = rand(0,library.length-1);
          return library[numb];
     },
     isExpired(){
      return this.expired;
     }

  },
  methods:{
    expire(){
      this.expired = true;
    }
  }


}
</script>

<style scoped>
    .square-btn{
      font-size: min(5vw,28px);
      width: min(11vw,55px);
      height: min(11vw,55px);
      border-radius: var(--radius);
    }
    .fade{
      animation: fadeIn 0.75s infinite;
    }
    .rise{
      animation: riseUp 2s infinite;
    }
    .rotate{
      animation: rotate 1s infinite;
    }
    .rotate3d{
      animation: rotate3d 1s infinite;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
        font-size: 100%;
      }
      100% {
        opacity: 1;
        font-size: 50%;
      }
    }
    @keyframes riseUp {
      0% {
        opacity: 0;
        font-size: 0%;
      }
      100% {
        opacity: 1;
        font-size: 120%;
      }
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes rotate3d {
      0% {
        transform: rotate3d(0,60,180,5deg);
      }
      100% {
        transform:  rotate3d(0,60,180,360deg);
      }
    }
</style>