<template>
  <i class="pof points"  :style="setStyle" :class="setClass"></i>
</template>
<script>
export default {
  props:{
    setClass:{
      default:'',
    },
    setStyle:{
      default:'',
    },
  }
}
</script>
<style >

</style>