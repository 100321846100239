import {storagePut, storageRemove} from "@/support/storage";


export const SET_RESULT = (state,data) => {
    state.SETTINGS = {};
    state.SETTINGS = Object.assign({},state.SETTINGS,data);
}
export const SET_SESSION = (state) => {
    let SETTINGS = state.SETTINGS;
    SETTINGS.session_open = !SETTINGS.session_open;
    state.SETTINGS = {};
    state.SETTINGS = Object.assign({},state.SETTINGS,SETTINGS);
}
export const CLEAR_SETTINGS = (state) => {
    state.SETTINGS = {};
    state.SETTINGS = Object.assign({},state.SETTINGS,state.FRESH_SETTINGS);
}
export const SET_REPORT = (state,data) => {
    let REPORT = data;
    state.REPORT = {};
    state.REPORT = Object.assign({},state.REPORT,REPORT);
    storageRemove('STORAGE_REPORT');
    storagePut('STORAGE_REPORT',REPORT);
}