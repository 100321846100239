import {aw, awSetData, awSetId} from "@/support/applicationWindow";

export const notifySuccess=(message=null)=>{
    let data = {
        notify_text:(message)?message:'success',
        notify_type:'success'
    };
    aw();
    awSetId(333);
    return awSetData(data);
}
export const notifyError=(message=null)=>{
    let data = {
        notify_text:(message)?message:'error',
        notify_type:'error'
    };

    aw();
    awSetId(333);
    return awSetData(data);
}
export const notifyWarning=(message=null)=>{
    let data = {
        notify_text:(message)?message:'',
        notify_type:'warning'
    };
    aw();
    awSetId(333);
    return awSetData(data);
}
export const notifyInfo=(message)=>{
    let data = {
        notify_text:message,
        notify_type:'info'
    };
    aw();
    awSetId(333);
    return awSetData(data);
}



export default {
    namespaced: true,
    notifyWarning,
    notifyInfo,
    notifySuccess,
    notifyError

}