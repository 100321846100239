<template>
  <div class="notify-frame blur40" :class="frameClass" v-if="awShow && +id===+awId">
    <div class="notify-content">
      <slot name="content"></slot>
    </div>
    <div class="notify-close" :class="closeClass" v-if="!hideClose">
      <div class="close-btn" @click="awClose()">
        <i class="fa fa-times-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {aw} from "@/support/applicationWindow";

export default {
  name: "appWindow",
  props:{
    id:{
      default(){
        return 1;
      }
    },
    title:{
      default(){
        return '';
      }
    },
    successMsg:{
      default(){
        return '';
      }
    },
    frameClass:{
      default(){
        return '';
      }
    },
    closeClass:{
      default(){
        return 't-black-txt';
      }
    },
    hideClose:{
      default(){
        return false;
      }
    }
  },
  computed:{
    ...mapGetters(['_applicationWindowState','_applicationWindowData','_applicationWindowId']),
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus']),
    loadingSpinner(){
      return this._loadingSpinnerStatus(0);
    },
    awId(){
      return this._applicationWindowId;
    },
    awShow(){
      return this._applicationWindowState;
    },
    awData(){
      return this._applicationWindowData;
    },
    notifyText(){
      if(this.awData===undefined){
        return '';
      }
      return (this.awData.notify_text!==undefined)?this.awData.notify_text :"";
    },
    notifyType(){
      if(this.awData===undefined){
        return '';
      }
      return (this.awData.notify_type!==undefined)?this.awData.notify_type :"";
    }
  },
  methods:{
    awClose(){
      return aw(false);
    }
  },
}
</script>

<style scoped>
  .close-btn {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.04);
  }
</style>