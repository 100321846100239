<template>
  <div>
    <div class="wd-p100 j-i-center j-c-center a-i-center flex-inline txt-xl grey7-txt pd-t-10 pd-b-10" :class="$t('font_b')">{{$t('accept_points')}}</div>
    <div class="flex-cnt wd-100p ht-100p j-c-start a-i-center flex-dir-col pd-t-30" >
      <div class="flex-center window-max-wd flex-dir-col j-c-center j-c-center a-i-center">
        <div class="action-content-wrap" id="focus">
          <div class="info-box">
            <div class="info-box-content">
              <ul>
                <li><span :class="$t('font')" v-on:click="info('issuer_company')"><i class="fal fa-building txt-lg1" style="margin-right: 24px;"></i></span> <span class="en txt-md1 ">{{ScanResult.merchant}}</span></li>
                <li><span :class="$t('font')" v-on:click="info('current_balance')"><i class="fal fa-coins txt-lg1" style="margin-right: 22px;"></i></span>  <span class="en txt-lg blue-txt"> {{ScanResult.balance}}</span></li>
                <li><span :class="$t('font')" v-on:click="info('minimum_num_points_spend')"><i class="fad fa-coin txt-lg1 orange-txt" style="margin-right: 22px;" ></i></span><span class="en txt-lg blue-txt" > {{Collector.spend_minimum}} <i class="pof points"></i></span></li>
                <li><span :class="$t('font')" v-on:click="info('exchange_rate')"><i class="far fa-function txt-lg1 pd-r-4"></i>:</span><span class="en txt-lg pd-l-10 pof" >1<i class="pof points"></i> = {{Rate}}<dollar></dollar> </span></li>
              </ul>
            </div>
            <div class="info-box-icon">
              <i v-if="!ShowResetButton" class="fal fa-info-circle"></i>
              <btn-square v-if="ShowResetButton"  icon="fal fa-redo blue-txt" color="t-white" @click.n.native="getFresh()"></btn-square>
            </div>
            <div class="info-box-button a-i-center">
              <div class="action-button">
                <btn-square v-if="ShowScanOption" icon="fal fa-scanner t-black-txt" color="yellow" @click.n.native="scan()"></btn-square>
                <btn-square :has-timer="true" :set-timer="2" v-if="ShowProcessOption" icon="fal fa-check-circle t-white-txt" color="blue" @click.n.native="process()"></btn-square>
              </div>
            </div>
          </div>

          <input-number :defaultInput="CheckAmount" :required="true" :is-disabled="DisableCheckAmount" :title="$t('amount_to_pay')" symbol="$" @hasInput="setAmountToPay" ></input-number>
          <input-number :defaultInput="PointsAmount" :is-class-red="!CanSpend" :required="true" :title="$t('points_to_pay')" symbol="$P" @hasInput="setPointsAmount"></input-number>
          <div class="input-group has-pre w-margin" :class="$t('font')">
            <div class="input-desc">{{$t('check_number')}}</div>
            <div class="input-pre grey7 en-sb txt-xl "><i class="fal fa-receipt"></i></div>
            <div class="input-area"><input  :value="CheckNumber" class="txt-xl text" type="text" @input="setCheckNumber">
              <span v-if="CheckNumber.length>0" class="fa fa-times-circle grey3-txt pd-l-2 pd-r-8 pd-t-1 txt-lg" style="max-width: 9%;"  v-on:click="resetCheckNumber()"></span>
            </div>
          </div>
          <input-number :defaultInput="CashToPay" :is-class-red="(CashToPay<0)" :required="false" :title="$t('cash_left_to_pay')" symbol="$"  :is-disabled="true"></input-number>
          <collector-transactions get-type="ACCEPT"></collector-transactions>

        </div>

      </div>
      <scanner :show="ShowScanner" @scan="processScanner" @stopScan="stopScan()"></scanner>
      <app-bar></app-bar>
    </div>
  </div>
</template>

<script>


import {mapActions, mapGetters, mapState} from "vuex";
import Dollar from "@/components/STATIC/dollar";
import {roundTo} from "@/support/support";


import AppBar from "@/views/components/apps_bar";
import Scanner from "@/views/components/scanner";
import InputNumber from "@/views/components/input_number";
import BtnSquare from "@/views/components/action_button_square";
import {notifyInfo, notifySuccess} from "@/support/notification";
import CollectorTransactions from "@/views/components/collector_transactions";


export default {
  name:'AcceptPoints',
  components:{
    CollectorTransactions,

    BtnSquare,
    Dollar,
    InputNumber,
    Scanner,
    AppBar,


  },
  data(){
    return{
      enableScanner:false,
    }
  },
  mounted(){
    this.getFresh();
  },
  computed:{
    ...mapState('GENERAL',['SETTINGS']),
    ...mapGetters('COLLECTOR',['getForm','getFormValue','getResult','getSubmitForm']),
    ShowScanner(){
      return this.enableScanner;
    },
    Settings(){
      return this.SETTINGS;
    },
    Collector(){
      return this.SETTINGS.COLLECTOR;
    },
    Form(){
      return this.getForm;
    },
    ScanResult(){
      return this.getResult('SCAN');
    },
    ProcessResult(){
      return this.getResult('PROCESS');
    },
    Rate(){
       if(this.ScanResult.rate.length===0){
         return this.Collector.buy_rate;
       }
       return this.ScanResult.rate;
    },
    CanSpend(){
      return (this.ScanResult.balance>=this.Form.points_amount && this.Form.points_amount>this.Collector.spend_minimum);
    },
    CanScan(){
      return (this.Form.check_amount>0 && this.Form.points_amount>0);
    },
    CanComplete(){
      return (this.CanSpend && this.cashToPay >=0 && this.hash && this.card_verified);
    },
    CheckAmount(){
      return (this.Form.check_amount>0)?this.Form.check_amount:0;
    },
    PointsAmount(){
      return (this.Form.points_amount>0)?this.Form.points_amount:0;
    },
    CheckNumber(){
      return this.Form.check_number;
    },
    CashToPay(){
      return this.CheckAmount-(this.PointsAmount*this.Rate);
    },
    PointsAmountInsteadOfCash(){
      return roundTo((this.CheckAmount/this.Rate),2);
    },
    PointsToCash(){
      return roundTo((this.PointsAmount/this.Rate),2);
    },
    ShowScanOption(){
      return (this.ScanResult.id.length===0 && this.CanScan);
    },
    ShowProcessOption(){
      return (this.ScanResult.id.length>0 && this.CanScan && this.CanSpend && this.CashToPay>=0);
    },
    DisableCheckAmount(){
      return (this.ScanResult.id.length>0);
    },
    ShowResetButton(){
      return (this.ShowProcessOption || (this.ScanResult.id.length>0));
    }

  },
  methods:{
    ...mapActions('COLLECTOR',['_scan','_process','_freshForm','_updateFormValue','_createSubmitForm']),

    scan(){
      return this.enableScanner = true;
    },
    stopScan(){
      return this.enableScanner = false;
    },
    processScanner(hash){
        this.updateValue('hash',hash);
        return this._scan().then().catch(()=>{
          this.enableScanner = false;
        });
    },
    process(){
      this.updateValue('hash',this.ScanResult.id);
      this._process().then(()=>{
        notifySuccess('success');
        this.getFresh();
      });
    },
    info(message){
      return notifyInfo(message);
    },
    getFresh(){
        return this._freshForm();
    },
    updateValue(name,value){
      let data ={};
      data[name] = value;
      return this._updateFormValue(data);
    },
    cashToPoints(){
      this.updateValue('points_amount',this.PointsAmountInsteadOfCash);
    },
    setAmountToPay(input){
      this.updateValue('check_amount',input);
      this.updateValue('points_amount',input);
      return this.CashToPay;
    },
    setPointsAmount(input){
      this.updateValue('points_amount',input);
      return this.CashToPay;
    },
    setCheckNumber(input){
      this.updateValue('check_number',input.target.value);
    },
    resetCheckNumber(){
      this.updateValue('check_number','');
    },
  }
}
</script>
<style>

</style>
