<template>
  <div>
    <div class="wd-p100 j-i-center j-c-center a-i-center flex-inline txt-xl grey7-txt pd-t-10 pd-b-10" :class="$t('font_b')">{{$t('give_points')}}</div>
  <app-window :id="3" close-class="grey3-txt" frame-class="t-white">
    <template #content>
      <div class="pd-10 grey7 radius mg-b-20"><span :class="$t('font')"><i class="fal fa-coins txt-lg1 yellow-txt pd-r-4"></i></span><span class="en txt-lg t-black-txt">{{ PointsAmount }} <currency set-class="t-black-txt"></currency></span></div>
      <div class="flex-center">
      <q-rgenerator  class-name="flex-center" :value="QrValue" size="300" ></q-rgenerator>
      </div>
      <div class="txt-md t-center w-margin grey4-txt" :class="$t('font')" style="line-height: 18px;"><i class="far fa-exclamation-triangle"></i> {{$t('make_screen_brighter')}}</div>
    </template>
  </app-window>

  <div class="flex-cnt wd-100p ht-100p j-c-start a-i-center flex-dir-col pd-t-30" >
    <div class="flex-center window-max-wd flex-dir-col j-c-center j-c-center a-i-center">
      <div class="action-content-wrap" id="focus">
        <div class="info-box">
          <div class="info-box-content">
            <ul>
              <li><span :class="$t('font')" v-on:click="info('estimated_points_to_get')" class="txt-lg1 "><i class="fal fa-coins txt-lg1" style="margin-right: 22px;"></i></span><span class="en txt-lg blue-txt">{{ PointsAmount }} <currency></currency></span></li>
              <li><span :class="$t('font')" v-on:click="info('minimum_purchase')" class="txt-lg1 "><i class="fal fa-receipt txt-lg1" style="margin-right: 22px;"></i></span><span class="en txt-lg blue-txt">{{ MinimumPurchase }} <dollar set-class="grey5-txt"></dollar></span></li>
              <li><span :class="$t('font')" v-on:click="info('exchange_rate')" class="txt-lg1 grey5-txt"><i class="fal fa-calculator txt-lg1" style="margin-right: 22px;"></i></span><span class="en txt-lg grey5-txt ">1 <dollar></dollar> =  {{ Rate }} <currency set-style="fill:#e5e5ea;stroke:#e5e5ea;"></currency></span></li>
              <li class="mg-t-20"><input-switch @change="setPhoneMode" :state="IsMobileMode" set-class="flex-inline txt-3xl"></input-switch> </li>
            </ul>
          </div>
          <div class="info-box-icon">
            <i v-if="!ShowResetButton" class="fal fa-info-circle"></i>
            <btn-square v-if="ShowResetButton"  icon="fal fa-redo blue-txt" color="t-white" @click.n.native="getFresh()"></btn-square>
          </div>
          <div class="info-box-button a-i-center">
            <div class="action-button">
              <btn-square v-if="ShowQrOption" icon="fal fa-qrcode t-black-txt" color="yellow" @click.n.native="showQr()"></btn-square>
              <btn-square v-if="ShowGrantOption" icon="fal fa-check-circle t-white-txt" color="blue" @click.n.native="grant()"></btn-square>
            </div>
          </div>
        </div>
        <input-phone v-if="IsMobileMode"  @validated="setPhoneValidationStatus"></input-phone>

        <input-number :defaultInput="CheckAmount" :required="true" :is-disabled="DisableCheckAmount" :title="$t('amount_to_pay')" symbol="$" @hasInput="setCheckAmount" ></input-number>

        <div class="input-group has-pre w-margin" :class="$t('font')">
          <div class="input-desc">{{$t('check_number')}}</div>
          <div class="input-pre grey7 en-sb txt-xl "><i class="fal fa-receipt"></i></div>
          <div class="input-area"><input  :value="CheckNumber" class="txt-xl text" type="text" @input="setCheckNumber" :disabled="DisableCheckAmount">
            <span v-if="CheckNumber.length>0" class="fa fa-times-circle grey3-txt pd-l-2 pd-r-8 pd-t-1 txt-lg" style="max-width: 9%;"  v-on:click="resetCheckNumber()"></span>
          </div>
        </div>

        <collector-transactions get-type="GRANT"></collector-transactions>

      </div>

    </div>

    <app-bar></app-bar>
  </div>
  </div>
</template>

<script>
import Currency from "@/components/STATIC/currency";
import BtnSquare from "@/views/components/action_button_square";
import Dollar from "@/components/STATIC/dollar";
import InputNumber from "@/views/components/input_number";
import AppBar from "@/views/components/apps_bar";
import {mapActions, mapGetters, mapState} from "vuex";
import {notifyInfo} from "@/support/notification";
import {awOpen} from "@/support/applicationWindow";
import {roundTo} from "@/support/support";
import AppWindow from "@/views/components/application_window";
import QRgenerator from "qrcode.vue";
import InputSwitch from "@/views/components/input-switch";
import InputPhone from "@/views/components/input_phone";
import CollectorTransactions from "@/views/components/collector_transactions";


export default {
  name: "GrantPoints",
  components:{
    CollectorTransactions,
    InputPhone,
    InputSwitch,

    AppWindow,
    Currency,
    BtnSquare,
    Dollar,
    InputNumber,
    AppBar,
    QRgenerator,
  },
  data(){
    return{
      mobileSwitch:false,
      phoneIsValidated:false,
      setPointsAmount:0,
      displayQr:false,
    }
  },
  mounted(){

    this.getFresh();
  },
  computed:{
    ...mapState('GENERAL',['SETTINGS']),
    ...mapGetters('COLLECTOR',['getForm','getFormValue','getResult','getSubmitForm','storeGetTransactions']),
    GrantResult(){
      return this.getResult('GRANT');
    },
    ShowScanner(){
      return this.enableScanner;
    },
    Settings(){
      return this.SETTINGS;
    },
    MinimumPurchase(){
      return this.Collector.min_purchase;
    },
    MaxGrant(){
      return this.Settings.max_onetime_grant;
    },
    Collector(){
      return this.SETTINGS.COLLECTOR;
    },
    Form(){
      return this.getForm;
    },
    Rate(){
        return this.Collector.sell_rate;
    },
    CheckAmount(){
      return (this.Form.check_amount>0)?this.Form.check_amount:0;
    },
    PointsAmount(){
      return (this.GrantResult.value<=0)?roundTo(this.setPointsAmount,2):roundTo(this.GrantResult.value,2);
    },
    CheckNumber(){
      return this.Form.check_number;
    },
    ShowGrantOption(){
      if(!this.IsMobileMode){
        return (this.CheckAmount>0 && this.CheckAmount>=this.MinimumPurchase && !this.ShowQrOption);
      }
      if(this.phoneIsValidated){
        return (this.CheckAmount>0 && this.CheckAmount>=this.MinimumPurchase && !this.ShowQrOption);
      }
      return false;

    },
    ShowQrOption(){
      if(!this.IsMobileMode) {
        return (this.GrantHasResult);
      }
      return false;
    },
    ShowQrCode(){
      if(!this.IsMobileMode) {
        return this.displayQr;
      }
      return false;

    },
    ShowResetButton(){
      return this.GrantHasResult;
    },
    GetCalcPointsAmount(){
      return roundTo(this.CheckAmount*this.Rate,2);
    },
    GrantHasResult(){
      return (this.GrantResult.value>0);
    },
    DisableCheckAmount(){
      return this.GrantHasResult;
    },
    QrValue(){
      return this.GrantResult.hash;
    },
    IsMobileMode(){
      return this.mobileSwitch;
    }
  },
  methods:{
    ...mapActions('COLLECTOR',['_grant','_transfer','_freshForm','_updateFormValue','_createSubmitForm']),
    setPhoneMode(input){
      this.getFresh();
      this.mobileSwitch = input;
      this.phoneIsValidated = false;
      if(!this.IsMobileMode){
        this.updateValue('phone_number','');
      }
    },
    setPhoneValidationStatus(status){
      this.phoneIsValidated = status;
    },
    showQr(){
      if(!this.IsMobileMode){
        awOpen(3);
      }
    },
    grant(){
      let that = this;
      if(!this.GrantHasResult){
        if(this.IsMobileMode){
          return this._transfer().then(function(){
            return that.getFresh();
          });
        }
        if(!this.IsMobileMode){
          return this._grant().then(function(){
            return that.showQr();
          });
        }
      }
      return false;
    },
    info(message){
      return notifyInfo(message);
    },
    getFresh(){
      this.mobileSwitch = false;
      return this._freshForm();
    },
    updateValue(name,value){
      let data ={};
      data[name] = value;
      return this._updateFormValue(data);
    },
    setCheckAmount(input){
      this.updateValue('check_amount',input);
      this.setPointsAmount = this.GetCalcPointsAmount;
    },
    setCheckNumber(input){
      this.updateValue('check_number',input.target.value);
    },
    resetCheckNumber(){
      this.updateValue('check_number','');
    },
    action(){
      this.getFresh();
    },

  }
}
</script>

<style scoped>

</style>