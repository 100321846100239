<template>

  <div class="card-skeleton cursor" :class="color" :id="id" :style="styles">
    <div class="company-logo en"><slot name="logo"></slot></div>
    <div class="company-name txt-lg1" :class="{'ka':label_lang==0,'en-m':label_lang==1}"><slot name="label"></slot> </div>

    <div class="card-type" v-if="type==1">{{cardType}}-{{discount}}%</div>
    <div class="card-type" v-if="type==2"><i class="fal fa-coins"></i></div>

    <slot name="qr"></slot>
    <div class="account-number"><span class="acc">{{cardAccount}}</span></div>
    <div class="valid-date ka ">
      <span :class="$t('font_b')">{{$t('active')}}</span><br>
      <span class="en">{{active}}</span>
    </div>
    <div class="qr-logo">
      <card-logo></card-logo>

    </div>
  </div>

</template>
<script>


import support from "@/support/support";
import cardLogo from "@/components/STATIC/cardLogo";

export default {
  components:{
    cardLogo
  },

  props:{
    id:{
      default:'card',
    },
    label_lang:{
      default:0, /* Georgian */
    },
    label:{
      type:String,
    },
    type:{
      type:Number,
    },
    icon:{
      default: '',
    },
    active:{
      type:String,
    },
    color:{
      type:String,
    },
    discount:{
      default:0,
    },
    acc:{
      default:'0000 0000 0000 0000',
    },
    styles:{
      default:'',
    },
    index:{
      default:1,
    },
    onetime:{
      default:0,
    },
    discount_dynamic:{
      default:0,
    },
  },
  computed:{
    cardAccount(){
      return support.space(this.acc);
    },
    cardType(){

      if(+this.discount_dynamic>0){
        return "D";
      }
      if(+this.onetime >0){
        return "O";
      }
      return "S";
    }
  },


}
</script>
<style lang="scss">


.card-skeleton {
  border-radius: var(--radius);
  display: grid;
  grid-template-columns: 33px 1fr 0.1fr;
  grid-template-rows: 30px 60px 50px 30px;
  grid-template-areas:
                "logo name card-type"
                "a b paybtn"
                "acc acc acc"
                "valid c applogo";
  grid-row-gap: 20px;
  animation-name: bounce-up;
  animation-duration: 350ms;
  animation-delay: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

}
.company-name {
  line-height: 30px;
  overflow: hidden;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-8px);
  }

  100% {
    opacity: 1;
  }
}
@keyframes bounce-up {
  0%   {bottom: -230px;}
  25%  {bottom: 0px;}
  100% {bottom: 20px;}
}

.account-number {
border: none !important;
}


</style>