import { render, staticRenderFns } from "./action_button_square.vue?vue&type=template&id=349f6bb7&scoped=true&"
import script from "./action_button_square.vue?vue&type=script&lang=js&"
export * from "./action_button_square.vue?vue&type=script&lang=js&"
import style0 from "./action_button_square.vue?vue&type=style&index=0&id=349f6bb7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349f6bb7",
  null
  
)

export default component.exports