<template>
  <div class="transaction-group-head">
    <div   class="pd-b-20 flex-inline wd-p100 a-i-center">
      <transition name="slide-fade-slow">
        <div class="wd-p100" v-if="!DisplaySearch"><h4 class="grey4-txt">{{$t('latest_transactions')}}</h4></div>
      </transition>
      <input-search  :visible="DisplaySearch" @hide="toggleSearch()" @input="setInput"></input-search>
      <transition name="slide-fade">
        <div  class="flex-inline grey7 radius pd-10" v-if="!DisplaySearch" @click="toggleSearch()"><i class="fal fa-search pd-t-3 txt-md1 grey3-txt"></i></div>
      </transition>

    </div>
  </div>
</template>

<script>
import InputSearch from "@/views/components/input-search";

export default {
  name: "transactionGroupHead",
  components: {InputSearch},
  emits:['search'],
  data(){
    return {
      showSearch:false,
      keyword:'',
    }
  },
  computed:{
    DisplaySearch(){
      return this.showSearch;
    }
  },
  methods:{
    setInput(input){
      this.keyword = input;
      this.$emit('search',input);
    },
    toggleSearch(){
      return this.showSearch = !this.showSearch;
    },
  }
}
</script>

<style scoped>

</style>