<template>
  <div class="notify-frame blur40 t-black" v-if="showScanner">
    <div class="notify-content">
      <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>
      <div class="scanner-qr-overlay"><i class="fal fa-qrcode"></i></div>
    </div>
    <div class="notify-close">
      <div class="close-btn white-txt" @click="stopScan()">
        <i class="fa fa-times-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {StreamBarcodeReader} from "vue-barcode-reader";

export default {
  name: "scanner",
  components:{StreamBarcodeReader},
  data(){
    return {
      show_scanner:false,
    }
  },
  props:{
    show:{
      default(){
        return false;
      }
    },
  },
  mounted() {
    this.show_scanner = this.show;
  },
  watch:{
    show:function (val){
      return this.show_scanner = val;
    }
  },
  computed:{
    showScanner(){
      return this.show_scanner;
    }
  },
  methods:{
    async onDecode (content) {
      let hash = content;
      //Fail if hash is not hash
      if(hash.length>32){
        hash=null;
      }
      this.stopScan();
      return this.$emit('scan',hash);
    },
    stopScan(){
      this.$emit('stopScan',true);
      return this.show_scanner = false;
    }
  }
}
</script>

<style scoped>


</style>