import Api from "@/apis/Api";

const END_POINT = 'v1';

export default {

    settings(){
        return  Api().get(`${END_POINT}/settings`);
    },
    appSettings(){
        return  Api().get(`${END_POINT}/settings/app`);
    },
    phoneValid(form) {
        return Api().post(`${END_POINT}/phone/validate`, form);
    },
    login(form) {
        return Api().post(`${END_POINT}/login`, form);
    },
    logout(form) {
        return Api().post(`${END_POINT}/logout`,form);
    },
    openSession() {
        return Api().get(`${END_POINT}/session/open`);
    },
    closeSession() {
        return Api().get(`${END_POINT}/session/close`);
    },
    sessionReport() {
        return Api().get(`${END_POINT}/session/report`);
    },



}