import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import Vue2TouchEvents from 'vue2-touch-events';
import i18n from './i18n';
import VueHtmlToPaper from '@/Modules/vue-html-to-paper';

//#Sentry
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";

const options = {
  name: '_blank',
  specs: [
    'popup',
    'width=320,height=400',
    'fullscreen=no',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://assets.portfel.app/css/cashier.css?v='+process.env.VUE_APP_VERSION,
  ],
  timeout: 100, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Sentry.init({
  Vue,
  dsn: "https://ddc54755074941bfa28ab76f3b47ae89@o1291216.ingest.sentry.io/6512743",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "cashier.portfel.app", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
//#Sentry END
Vue.use(VueHtmlToPaper, options);
Vue.use(Vue2TouchEvents,{
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 300,
  swipeTolerance: 30,
  longTapTimeInterval: 300
});

Vue.config.productionTip = false;



new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
