export default {
    /* Data Is Filed From Cloud */
    SCAN_RESULT:{
        id: '',
        rate: '',
        balance: '',
        merchant: '',
        minimum_purchase_amount: '',
    },
    PROCESS_RESULT:{
        rate: '',
        points_amount: '',
        discount_value: '',
        check_amount: '',
        check_number: '',
        cash_to_pay: '',
    },
    GRANT_RESULT:{
        hash: "",
        link: "",
        value: '',
    },
    TRANSACTIONS:[],
    SUBMIT_FORM:{},
    FORM:{
        hash:'',
        check_amount:'',
        check_number:'',
        points_amount:'',
        phone_number:'',
    },
    _CALLER_METHOD:'',



}