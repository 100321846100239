<template>
  <transition name="fade">
    <div class="notification-frame">

      <div v-if="notification.type==='success'" class="notification_window blur center">
        <div class="notification-icon green-txt"><i class="fal fa-check-circle"></i></div>
        <div class="notification-header " :class="$t('font')" style="padding-top: 20px;"><h3>{{ $t('finish') }}</h3></div>
        <div class="notification-text " :class="$t('font')">
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button green t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>


      <div v-if="notification.type==='error'" class="notification_window blur center">
        <div class="notification-icon red-light-txt"><i class="fal fa-times-circle"></i></div>
        <div class="notification-header" :class="$t('font')" style="padding-top: 20px;"><h3>{{ $t('error') }}</h3></div>
        <div class="notification-text " :class="$t('font')" >
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button red-light t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>

      <div v-if="notification.type==='warning'" class="notification_window blur center">
        <div class="notification-icon orange-txt"><i class="fal fa-info-circle"></i></div>
        <div class="notification-header" :class="$t('font')" style="padding-top: 20px;"><h3>{{ $t('warning') }}</h3></div>
        <div class="notification-text " :class="$t('font')" >
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button orange t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>

      <div v-if="notification.type==='info'" class="notification_window blur center">
        <div class="notification-icon blue-txt"><i class="fal fa-info-circle"></i></div>
        <div class="notification-header" :class="$t('font')" style="padding-top: 20px;"><h3>{{ $t('info') }}</h3></div>
        <div class="notification-text " :class="$t('font')" >
          <span v-if="!$te(notification.message)">{{notification.message}}</span>
          <span v-if="$te(notification.message)">{{ $t(notification.message) }}</span>
        </div>
        <div class="notification-button blue t-white-txt" :class="$t('font_sb')"  @click="remove(notification)">{{ $t('close') }}</div>
      </div>

      <div v-if="notification.type==='update'" class="notification_window blur center">
        <div class="notification-icon indigo-txt">
          <i class="far fa-planet-ringed"></i>
        </div>
        <div class="notification-header txt-md text-short-l t-center pd-b-6" :class="$t('font')">{{ $t('update_available') }} </div>
        <div class="notification-text t-center txt-md1 pd-b-4 pd-t-6" :class="$t('font')">
          <span class="pd-2 indigo  radius pd-4 pd-l-10 pd-r-10"><span @click="update()"> {{$t('update')}} </span></span>
        </div>
        <div class="notify_close grey5-txt txt-3xl" :class="$t('font')" @click="remove(notification)"><i class="fas fa-times-circle"></i></div>
      </div>

    </div>
  </transition>




</template>

<script>
import {mapActions} from "vuex";

export default {
  props: ["notification"],

  data() {
    return {
      timeout: null
    };
  },

  computed: {
    typeClass() {
      return `alert-${this.notification.type}`;
    }
  },

  created() {
    if(this.notification.type==='success') {
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 1500);
    }

    if(this.notification.type==='update'){
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 10000);

    }
    if(this.notification.type!=='update' && this.notification.type!=='success'){
      this.timeout = setTimeout(() => {
        this.removeNotification(this.notification);
      }, 10000);
    }


  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    ...mapActions(["removeNotification"]),
    update(){
      return window.location.reload();
    },
    remove:function(notification){
      this.removeNotification(notification);
    },

  }
};
</script>
<style>

</style>