<template>
  <i class="pof" :class="setClass" :style="setStyle">{{currency}}</i>
</template>
<script>
import {getDollar} from "@/support/support";

export default {
  props:{
    setClass:{
      default:'icon-defaults',
    },
    setSize:{
      default:'1rem;',
    },
    setStyle:{
      default:'',
    }
  },
  computed:{
    currency() {
      return getDollar();
    }
  }
}
</script>
<style>
.icon-defaults,.icon-defaults >svg{
  width: 1rem;
  display: inline;
  margin-right: 5px;
  margin-left: 2px;
  position: relative;

}
</style>