<template>
  <div class="flex-center flex-dir-col">
    <div class="grid-col-2">
      <div class="txt-xxl mg-b-20 pd-b-10 flex-start grid-row-2" :class="$t('font_b')">
        <div class="flex-start">{{$t('get_report')}}</div>
        <div class="flex-start txt-xs1 grey3-txt">{{$t('last_session_close')}}: {{Settings.session_close_date}}</div>
      </div>
      <div class="flex-end">
        <div class="ht-12 blue pd-10 radius mg-b-20 wd-20 flex-center-raw" @click="getReport()"><i class="fad fa-sync"></i></div>
      </div>

    </div>


    <div class="grid-col-2 g-c-gap-10 t-left pd-t-20 pd-b-20 w-tb-line">
      <div>{{$t('total_checks_number')}}</div>
      <div class="t-right" :class="$t('font_b')">{{ReportData.total_checks_number}}</div>
      <div>{{$t('total_check_amount')}}</div>
      <div class="t-right" :class="$t('font_b')"><dollar></dollar><span class="pd-l-4">{{ReportData.total_checks_number}} </span></div>
      <div>{{$t('total_net_income')}}</div>
      <div class="t-right" :class="$t('font_b')"><dollar></dollar><span class="pd-l-4">{{ReportData.total_net_income}}</span></div>
      <div v-if="DiscountActive">{{$t('total_discounts')}}</div>
      <div v-if="DiscountActive" class="t-right" :class="$t('font_b')" >{{ReportData.total_discounts}}</div>
      <div v-if="CollectorActive" >{{$t('total_points_accepted')}}</div>
      <div v-if="CollectorActive" class="t-right" :class="$t('font_b')" >{{ReportData.total_points_accepted}}</div>
      <div v-if="CollectorActive">{{$t('total_points_accepted_value')}}</div>
      <div v-if="CollectorActive" class="t-right" :class="$t('font_b')"><dollar></dollar><span class="pd-l-4">{{ReportData.total_points_accepted_value}}</span></div>
      <div v-if="CollectorActive">{{$t('total_points_granted')}}</div>
      <div v-if="CollectorActive" class="t-right" :class="$t('font_b')">{{ReportData.total_points_granted}}</div>
      <div v-if="DiscountActive">{{$t('total_cards_granted')}}</div>
      <div v-if="DiscountActive" class="t-right" :class="$t('font_b')">{{ReportData.total_cards_granted}}</div>
      <div>{{$t('first_check_time')}}</div>
      <div class="t-right" :class="$t('font_b')">{{ReportData.first_check_time}}</div>
      <div>{{$t('last_check_time')}}</div>
      <div class="t-right" :class="$t('font_b')">{{ReportData.last_check_time}}</div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Dollar from "@/components/STATIC/dollar";

export default {
  name: "cashierReport",
  components: {Dollar},
  mounted() {
    return this.getReport();
  },
  computed:{
    ...mapGetters('GENERAL',['getReportData','getSettings']),
    Settings(){
      return this.getSettings;
    },
    ReportData(){
        return this.getReportData;
    },
    PartnerActive(){
      return this.Settings.PARTNERS.enabled===1;
    },
    DiscountActive(){
      return this.Settings.DISCOUNT.enabled===1;
    },
    CollectorActive(){
      return this.Settings.COLLECTOR.enabled===1;
    },
  },
  methods:{
    ...mapActions('GENERAL',['_sessionReport']),
    getReport(){
       return this._sessionReport();
    }
  }
}
</script>

<style scoped>

</style>