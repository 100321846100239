export const PUSH_NOTIFICATION = (state, notification) => {
    state.notifications.push({
        ...notification,
        id: (Math.random().toString(36) + Date.now().toString(36)).substr(2)
    })
}

export const REMOVE_NOTIFICATION = (state, notificationToRemove) => {
    state.notifications = state.notifications.filter(notification => {
        return notification.id !== notificationToRemove.id;
    })
}
export const SHOW_LOADING = (state) => {
    state.LOADING_WINDOW = true;
}
export const HIDE_LOADING = (state) => {
    state.LOADING_WINDOW = false;
}
export const SET_TOKEN= (state,token) => {
    state.TOKEN = token;
}


export const LOADING_SPINNER = (state,options) => {
    let newState = state.LOADING_SPINNER;
    newState[options.spinner] = options.state;
    state.LOADING_SPINNER = Object.assign([],newState);
}
export const RESET_LOADING_SPINNER = (state) => {
    state.LOADING_SPINNER = Object.assign([],state.LOADING_SPINNER_TEMPLATE);
}

export const APPLICATION_WINDOW_OPEN = (state) => {
    let nState = state.APPLICATION_WINDOW_FRESH;
    state.APPLICATION_WINDOW = Object.assign({},nState);
}
export const APPLICATION_WINDOW_CLOSE = (state) => {
    let nState = state.APPLICATION_WINDOW;
    //Reset All
    nState.SHOW = false;
    nState.STATUS = false;
    nState.DATA={};
    state.APPLICATION_WINDOW = Object.assign({},nState);
}
export const APPLICATION_WINDOW_STATUS = (state) => {
    let nState = state.APPLICATION_WINDOW;
    nState.STATUS = true;
    state.APPLICATION_WINDOW = Object.assign({},nState);
}
export const APPLICATION_WINDOW_SET_DATA = (state,data) => {
    let nState = state.APPLICATION_WINDOW;
    nState.DATA = data;
    state.APPLICATION_WINDOW = Object.assign({},nState);

}
export const APPLICATION_WINDOW_SET_ID = (state,id) => {
    let nState = state.APPLICATION_WINDOW;
    nState.ID = id;
    state.APPLICATION_WINDOW = Object.assign({},nState);
}