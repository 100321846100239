<template>
  <div class="main">
    <div @click="print()" class="print-icon-container">
      <print-svg></print-svg>
    </div>
    <div class="t-white mg-t-20 pd-10" id="receipt" >
        <div class="flex-center flex-dir-col">
          <div class="flex-center"><span class="grey3-txt txt-md">{{ transaction.date }}</span></div>
          <div class="grid-col-2 a-i-center j-c-space-between">
            <div class="grid-row-3 j-c-start">
              <div><b>{{ $t('company') }} :</b> {{ getSettings.MERCHANT.name }}</div>
              <div><b>{{ $t('cashier') }} :</b> {{ getSettings.first_name }} {{ getSettings.last_name }}</div>
              <div><b>{{ $t('branch') }} :</b> {{ getSettings.BRANCH.name }}</div>
            </div>
            <div class="grid-row-2 j-i-end">
              <div class="txt-7xl grey3-txt"><recipt-svg></recipt-svg></div>
              <div class="txt-xs1 grey3-txt">{{ transaction.check_number }}</div>
            </div>
          </div>
          <div class="w-b-line pd-t-10"></div>
          <div class="grid-col-4 pd-t-10 t-center j-c-space-between j-i-end">
           <div class="en-b">#</div>
            <div class="en-b">{{$t('check_amount')}}</div>
            <div class="en-b" v-if="CollectorType">{{$t('points_spend')}} </div>
            <div class="en-b" v-if="DiscountType">{{$t('discount')}} </div>
            <div class="en-b">{{$t('cash_paid')}}</div>
            <div>1</div>
            <div><span class="pd-r-2">{{ transaction.check_amount }}</span><dollar></dollar> </div>
            <div v-if="CollectorType"><span class="pd-r-2" >{{ transaction.points_amount }}</span><currency></currency></div>
            <div v-if="DiscountType"><span class="pd-r-2" >{{ transaction.discount_value }}</span><dollar></dollar></div>
            <div><span class="pd-r-2">{{ transaction.cash_to_pay }}</span><dollar></dollar> </div>
          </div>
          <div class="w-b-line pd-t-10"></div>
          <div class="w-tb-margin">
          <q-rgenerator v-if="ShowQrCode" render-as="svg"  class-name="flex-center" :value="QrCode" size="180" ></q-rgenerator>
          </div>
          <div class="w-tb-pad-20 t-center">{{$t('thanks_for_purchase')}}</div>
          <div class="w-b-line pd-t-10"></div>
        </div>
    </div>
  </div>
</template>

<script>
import PrintSvg from "@/views/components/svg/print";
import ReciptSvg from "@/views/components/svg/recipt-svg";
import Currency from "@/components/STATIC/currency";
import Dollar from "@/components/STATIC/dollar";
import {mapState} from "vuex";
import QRgenerator from "qrcode.vue";

export default {
  name: "printReceipt",
  props:{
    transaction:{
      default(){
        return {}
      }
    },
    processType:{
      default(){
        return 0;
      }
    },
  },
  components: {Dollar, Currency, ReciptSvg, PrintSvg, QRgenerator},
  computed:{
    ...mapState('GENERAL',['SETTINGS']),
    getSettings(){
      return this.SETTINGS;
    },
    CollectorType(){
      return +this.processType===2 || +this.processType===22;
    },
    DiscountType(){
      return +this.processType===1 || +this.processType===11;
    },
    QrCode(){
      if(this.transaction.hash===undefined){
        return JSON.stringify(this.transaction);
      }
      return this.transaction.hash;
    },
    ShowQrCode() {
      return this.QrCode.length>0;
    }
  },
  methods:{
    async print(){
      await this.$htmlToPaper('receipt');
    },
    openWindow (url, name, props) {
      let windowRef = null;
      windowRef = window.open(url, name, props);
      if (!windowRef.opener) {
        windowRef.opener = self;
      }
      windowRef.focus();
      return windowRef;
    },

  }

}
</script>

<style scoped>
.main {
  width: 90vw;
}
.print-icon-container{
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

</style>