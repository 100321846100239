<template>
<div class="pd-t-20">
    <div class="flx j-c-center wd-p100" v-if="HasCardSelected">
      <div @click="resetSelection()" class="flex-center-raw cursor j-i-center j-c-center pd-l-10 pd-r-10 pd-t-8 pd-b-6 grey7 radius" >
        <span :class="$t('font')" class="blue-txt txt-xs1"><i class="fad fa-filter blue-txt"></i> {{$t('reset')}}</span>
      </div>
    </div>
    <div v-if="IsCardListEmpty" class="flx j-c-center wd-p100 pd-l-10 grey3-txt"><p>{{$t('company_has_no_cards_on_the_amount')}}</p></div>
    <div  v-if="HasCards" class="flex-center flex-dir-col a-i-center">
      <div class="wallet-card" style="width: 90%;" v-for="(card,index) in CardList" :class="card.color" :key="index" :style="'--card-order:'+card.id">
          <input-card
              @click.native="selectCard(card)"
              :index="index"
              :id="'C-'+card.id"
              :styles="'---animation-order:'+index+';'"
              :type=1
              :acc="1000000000000000"
              :icon="card.icon"
              :active="card.expires"
              :label_lang="card.is_en"
              :discount="card.discount"
              :discount_dynamic="card.is_dynamic"
              :onetime="card.is_onetime"
          >
            <template v-slot:logo>
              <span class="pof portfel"></span>
            </template>
            <template v-slot:label><span>{{card.label }}</span></template>
          </input-card>

      </div>
    </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import InputCard from "@/components/STATIC/inputCard";

export default {
  name: "grantCardList",
  components: {InputCard},
  props:['empty'],
  mounted() {
      this.resetSelection();
  },
  computed:{
    ...mapGetters('DISCOUNT',['getChooseCardList','getForm']),
    Form(){
      return this.getForm;
    },
    HasCardSelected(){
      return this.Form.card_id>0;
    },
    IsCardListEmpty() {
      return this.empty;
    },
    SelectedCardId(){
      return this.Form.card_id;
    },
    CardList(){
      let self=this;
      if(this.HasCardSelected){
        return this.getChooseCardList.filter(function(card){
          return card.id=== self.SelectedCardId;
        });
      }
      return this.getChooseCardList;
    },
    HasCards(){
      return this.CardList.length>0;
    }
  },
  methods:{
    ...mapActions('DISCOUNT',['_updateFormValue']),
    resetSelection(){
      this.updateValue('card_id','');
    },
    selectCard(card){
      this.selectFilter = card;
      this.updateValue('card_id',card.id);
      this.$emit('selected',card);
    },
    updateValue(name,value){
      let data ={};
      data[name] = value;
      return this._updateFormValue(data);
    },
  }
}
</script>

<style scoped>

</style>