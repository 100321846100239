import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as getters from './getters';
import * as mutations from './mutations';
import * as actions from './actions';

/*  Modules */
/*
* New Modules
*/
import COLLECTOR from './Collector'
import DISCOUNT from './Discount'
import GENERAL from './General'
import PARTNERSHIP from './Partnership'
/*
* End of new Modules
*/
// import _home from './modules/employee/home'
// import _operations from './modules/employee/operations'
// import _transfer from './modules/employee/transfer'
// import _buy_points from './modules/employee/buypoints'
// import _sell_points from './modules/employee/sellpoints'
// import _sell_cards from './modules/employee/sellcards'
// import _buy_cards from './modules/employee/buycards'
// import _partner from './modules/employee/partner'

Vue.use(Vuex);


export default new Vuex.Store({
    strict: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        // _home,
        // _operations,
        // _transfer,
        // _buy_points,
        // _sell_points,
        // _sell_cards,
        // _buy_cards,
        // _partner,
        /*
        * New Modules
        */
        DISCOUNT,
        COLLECTOR,
        GENERAL,
        PARTNERSHIP

    }
});