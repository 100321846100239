<template>
  <div class="input-group has-postxt w-margin" :class="$t('font')">
    <div class="input-desc">{{title}} <span class="red-txt txt-md" v-if="required">* </span></div>
    <div class="input-pos grey7 txt-xxl j-i-center a-i-center j-c-center">
      <lari-symbol v-if="symbol==='$'"></lari-symbol>
      <currency v-if="symbol==='$P'"></currency>
      <i v-if="symbol==='$%'" class="fad fa-percent txt-md1"></i>
    </div>
    <div class="input-area">
      <input class="txt-xl number" :class="{'red-light-txt':getRedClass,'grey3-txt':(disableValue&&!getRedClass)}" :ref="'inputNum'+id" type="number" min="0" max="100000" autocomplete="off" v-on:focusin="clearIfNeeded()" :value="inputData" v-on:input="pushData" :disabled="disableValue">
      <span v-if="realInput && !disableValue" class="fa fa-times-circle grey3-txt pd-l-2 pd-r-8 pd-t-1 txt-md1" style="max-width: 9%;"  v-on:click="clearInput()"></span>
    </div>
  </div>
</template>

<script>
import LariSymbol from "@/components/STATIC/lariSymbol";
import Currency from "@/components/STATIC/currency";
import {rand} from "@/support/helpers";

export default {
  name: "inputNumber",
  components: {Currency, LariSymbol},
  props:{
    required:{
      default(){
        return true;
      }
    },
    title:{
      default(){
        return this.$t('amount_to_pay');
      }
    },
    defaultInput:{
      default() {
        return 0;
      }
    },
    isDisabled:{
      default() {
        return false;
      }
    },
    symbol:{
      type:String,
      default(){
        return '$'
      },



    },
    isClassRed:{
        default(){
          return false;
        }
    }
  },
  data(){
    return{
      inputData:0,
      id:0,
      isDisabledValue:false,
      setClassRed:false,
    }
  },
  mounted() {
    this.id = rand(1,999).toString();
    this.isDisabledValue = this.isDisabled;
    this.inputData = this.defaultInput;
    this.setClassRed = this.isClassRed;
  },
  computed:{
    disableValue(){
      return this.isDisabledValue;
    },
    realInput(){
      return this.inputData>0;
    },
    getRedClass(){
      return this.setClassRed;
    }
  },
  watch: {
    defaultInput: function(newVal) {
      return this.inputData = newVal;
    },
    isDisabled: function(newVal) {
      return this.isDisabledValue = newVal;
    },
    isClassRed: function(newVal) {
      return this.setClassRed = newVal;
    },
  },
  methods:{
    pushData(event){

      this.inputData = event.target.value;
      return this.$emit('hasInput',this.inputData);
    },
    clearInput(){
      this.inputData='';
      this.$emit('hasInput',this.inputData);
    },
    clearIfNeeded(){
        if(!this.realInput){
          this.inputData='';
          this.$emit('hasInput',this.inputData);
        }
        let element = 'inputNum'+this.id;
        return this.$refs[element].focus();
    }
  }
}
</script>

<style scoped>

</style>