<template>
  <div class="notify-frame blur40" v-if="ShowWindow">
    <div class="notify-content max-wd-250px">

      <div class="notify-icon pd-b-10 anime-bounce" v-if="notifyType==='error'">
        <i class="anime-red-color fal fa-times-circle red-txt"></i>
      </div>
      <div class="notify-icon pd-b-10 anime-bounce" v-if="notifyType==='success'">
        <i class="fal fa-check-circle green-txt"></i>
      </div>
      <div class="notify-icon pd-b-10 anime-bounce" v-if="notifyType==='warning'">
        <i class="anime-red-color fal fa-triangle orange-txt"></i>
      </div>
      <div class="notify-icon pd-b-10 anime-bounce" v-if="notifyType==='info'">
        <i class="anime-red-color fal fa-info-circle blue-txt"></i>
      </div>

      <transition name="slide-fade">
        <div class="notify-txt">
          <span v-if="!$te(notifyText)">{{notifyText}}</span>
          <span v-if="$te(notifyText)">{{ $t(notifyText) }}</span>
        </div>
      </transition>
    </div>
    <div class="notify-close max-wd-250px" :class="closeClass">
      <div class="close-btn" @click="awClose()">
        <i class="fa fa-times-circle"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {aw} from "@/support/applicationWindow";

export default {
  name: "notificationWindow",
  props:{

    id:{
      default(){
        return 333;
      }
    },
    title:{
      default(){
        return '';
      }
    },
    successMsg:{
      default(){
        return '';
      }
    },
    keepOpen:{
      default(){
        return 1500;
      }
    },
    closeClass:{
      default(){
        return 'grey7-txt';
      }
    },
  },
  mounted() {

  },
  computed:{
    ...mapGetters(['_applicationWindowState','_applicationWindowData','_applicationWindowId']),
    //SPINNERS
    ...mapGetters(['_loadingSpinnerStatus']),
    loadingSpinner(){
      return this._loadingSpinnerStatus(0);
    },
    awId(){
      return this._applicationWindowId;
    },
    awShow(){
      return this._applicationWindowState;
    },
    awData(){
      return this._applicationWindowData;
    },
    notifyText(){
      return this.awData.notify_text;
    },
    notifyType(){
      return this.awData.notify_type;
    },
    ShowWindow(){
      return (this.awShow && +this.id===+this.awId);
    },
    OpenTimer(){
      if(this.notifyType==='info'){
        return +this.keepOpen+(this.keepOpen*1.50);
      }
      if(this.notifyType==='warning'){
        return +this.keepOpen+(this.keepOpen*0.30);
      }
      if(this.notifyType==='error'){
        return +this.keepOpen+(this.keepOpen*0.90);
      }
      //Success
      return +this.keepOpen;
    }
  },
  watch:{
    ShowWindow:function(val){
      if(val){
        let self = this;
        setTimeout(function (){
          if(self.notifyType==='success'){
            return aw(false);
          }

        },this.OpenTimer);
      }
    }
  },
  methods:{
    awClose(){
      return aw(false);
    }
  },
}
</script>

<style scoped>

</style>