<template>
  <div :class="setClass">{{ timer }}</div>
</template>

<script>
export default {
  name: 'countdown',
  props: ['minutes','setClass'],

  data() {
    return{
      countdownTimer:0,
    }
  },

  computed:{
    timer(){
      return this.countdownTimer;
    }
  },
  mounted() {

    let that = this;
    let minutes = this.minutes;
    let seconds = minutes*60;
    let displaySeconds = 0;
    setInterval(function() {
      let ShowSeconds = displaySeconds;
      if(displaySeconds===0){
        ShowSeconds = "0"+displaySeconds;
      }
      if(displaySeconds===0){
        minutes = minutes-1;
        displaySeconds = 60;
      }
      if(displaySeconds<10){
        ShowSeconds = "0"+displaySeconds;
      }
      if(seconds===0){
        that.countdownTimer = '';
        return that.$emit('expire');
      }
      that.countdownTimer = minutes+":"+ShowSeconds ;
      displaySeconds = displaySeconds-1;
      seconds = seconds-1;
    }, 1000);
  }
};
</script>