export default {
    options:{
        debug:true,
        appName: 'Cashier'
    },
    LOADING_WINDOW:false,
    notifications: [],

    APPLICATION_WINDOW:{
        ID:1,
        SHOW:false,
        STATUS:false,
        DATA:{}
    },
    APPLICATION_WINDOW_FRESH:{
        ID:1,
        SHOW:true,
        STATUS:false,
        DATA:{}
    },

    LOADING_SPINNER:[false,false,false,false,false,false,false,false,false,false],
    LOADING_SPINNER_TEMPLATE:[false,false,false,false,false,false,false,false,false,false],




    TOKEN:false,
    SETTINGS:{

        COUNTRY_DATA:[
            {
                "country": "Greenland",
                "tld": "testnet",
                "pid_regex":"^[0-9]{6,20}$",
                "regid_regex":"^[0-9]{6,30}$",
                "phone_regex":"^[0-9]{6,30}$",
                "language":"en",
                "currency":"£"
            },
            {
                "country": "Afghanistan",
                "tld": "af"
            },
            {
                "country": "Albania",
                "tld": "al"
            },
            {
                "country": "Algeria",
                "tld": "dz"
            },
            {
                "country": "American Samoa",
                "tld": "as"
            },
            {
                "country": "Andorra",
                "tld": "ad"
            },
            {
                "country": "Angola",
                "tld": "ao"
            },
            {
                "country": "Anguilla",
                "tld": "ai"
            },
            {
                "country": "Antarctica",
                "tld": "aq"
            },
            {
                "country": "Antigua and Barbuda",
                "tld": "ag"
            },
            {
                "country": "Argentina",
                "tld": "ar"
            },
            {
                "country": "Armenia",
                "tld": "am",
                "pid_regex":"^[0-9]{6,20}$",
                "regid_regex":"^[0-9]{6,30}$",
                "phone_regex":"^[0-9]{6,30}$",
                "language":"am",
            },
            {
                "country": "Aruba",
                "tld": "aw"
            },
            {
                "country": "Australia",
                "tld": "au"
            },
            {
                "country": "Austria",
                "tld": "at"
            },
            {
                "country": "Azerbaijan",
                "tld": "az",
                "pid_regex":"^[0-9]{6,20}$",
                "regid_regex":"^[0-9]{6,30}$",
                "phone_regex":"^[0-9]{6,30}$",
                "language":"az",
            },
            {
                "country": "Bahamas",
                "tld": "bs"
            },
            {
                "country": "Bahrain",
                "tld": "bh"
            },
            {
                "country": "Bangladesh",
                "tld": "bd"
            },
            {
                "country": "Barbados",
                "tld": "bb"
            },
            {
                "country": "Belarus",
                "tld": "by"
            },
            {
                "country": "Belgium",
                "tld": "be"
            },
            {
                "country": "Belize",
                "tld": "bz"
            },
            {
                "country": "Benin",
                "tld": "bj"
            },
            {
                "country": "Bermuda",
                "tld": "bm"
            },
            {
                "country": "Bhutan",
                "tld": "bt"
            },
            {
                "country": "Bolivia",
                "tld": "bo"
            },
            {
                "country": "Bosnia and Herzegovina",
                "tld": "ba"
            },
            {
                "country": "Botswana",
                "tld": "bw"
            },
            {
                "country": "Bouvet Island",
                "tld": "bv"
            },
            {
                "country": "Brazil",
                "tld": "br"
            },
            {
                "country": "British Indian Ocean Territory",
                "tld": "io"
            },
            {
                "country": "Brunei",
                "tld": "bn"
            },
            {
                "country": "Bulgaria",
                "tld": "bg"
            },
            {
                "country": "Burkina Faso",
                "tld": "bf"
            },
            {
                "country": "Burundi",
                "tld": "bi"
            },
            {
                "country": "Cambodia",
                "tld": "kh"
            },
            {
                "country": "Cameroon",
                "tld": "cm"
            },
            {
                "country": "Canada",
                "tld": "ca"
            },
            {
                "country": "Cape Verde",
                "tld": "cv"
            },
            {
                "country": "Cayman Islands",
                "tld": "ky"
            },
            {
                "country": "Central African Republic",
                "tld": "cf"
            },
            {
                "country": "Chad",
                "tld": "td"
            },
            {
                "country": "Chile",
                "tld": "cl"
            },
            {
                "country": "China",
                "tld": "cn"
            },
            {
                "country": "Christmas Island",
                "tld": "cx"
            },
            {
                "country": "Cocos (Keeling) Islands",
                "tld": "cc"
            },
            {
                "country": "Colombia",
                "tld": "co"
            },
            {
                "country": "Comoros",
                "tld": "km"
            },
            {
                "country": "Congo",
                "tld": "cg"
            },
            {
                "country": "Cook Islands",
                "tld": "ck"
            },
            {
                "country": "Costa Rica",
                "tld": "cr"
            },
            {
                "country": "Croatia",
                "tld": "hr"
            },
            {
                "country": "Cuba",
                "tld": "cu"
            },
            {
                "country": "Cyprus",
                "tld": "cy"
            },
            {
                "country": "Czech Republic",
                "tld": "cz"
            },
            {
                "country": "Denmark",
                "tld": "dk"
            },
            {
                "country": "Djibouti",
                "tld": "dj"
            },
            {
                "country": "Dominica",
                "tld": "dm"
            },
            {
                "country": "Dominican Republic",
                "tld": "do"
            },
            {
                "country": "East Timor",
                "tld": "tl"
            },
            {
                "country": "Ecuador",
                "tld": "ec"
            },
            {
                "country": "Egypt",
                "tld": "eg"
            },
            {
                "country": "El Salvador",
                "tld": "sv"
            },
            {
                "country": "England",
                "tld": 'uk'
            },
            {
                "country": "Equatorial Guinea",
                "tld": "gq"
            },
            {
                "country": "Eritrea",
                "tld": "er"
            },
            {
                "country": "Estonia",
                "tld": "ee"
            },
            {
                "country": "Ethiopia",
                "tld": "et"
            },
            {
                "country": "Falkland Islands",
                "tld": "fk"
            },
            {
                "country": "Fiji Islands",
                "tld": "fj"
            },
            {
                "country": "Finland",
                "tld": "fi"
            },
            {
                "country": "France",
                "tld": "fr"
            },
            {
                "country": "French Guiana",
                "tld": "gf"
            },
            {
                "country": "French Polynesia",
                "tld": "pf"
            },
            {
                "country": "French Southern territories",
                "tld": "tf"
            },
            {
                "country": "Gabon",
                "tld": "ga"
            },
            {
                "country": "Gambia",
                "tld": "gm"
            },
            {
                "country": "Georgia",
                "tld": "ge",
                "pid_regex":"^[0-9]{11}$",
                "regid_regex":"^[0-9]{9}$",
                "phone_regex":"5([0-9]{2})[0-9]{6}$",
                "language":"ka",
                "currency":"¶"
            },
            {
                "country": "Germany",
                "tld": "de",
                "pid_regex":"^[0-9]{11}$",
                "regid_regex":"^[0-9]{9}$",
                "phone_regex":"5([0-9]{2})[0-9]{6}$",
                "language":"de",
                "currency":"€"
            },
            {
                "country": "Ghana",
                "tld": "gh"
            },
            {
                "country": "Gibraltar",
                "tld": "gi"
            },
            {
                "country": "Greece",
                "tld": "gr"
            },
            {
                "country": "Grenada",
                "tld": "gd"
            },
            {
                "country": "Guadeloupe",
                "tld": "gp"
            },
            {
                "country": "Guam",
                "tld": "gu"
            },
            {
                "country": "Guatemala",
                "tld": "gt"
            },
            {
                "country": "Guinea",
                "tld": "gn"
            },
            {
                "country": "Guinea-Bissau",
                "tld": "gw"
            },
            {
                "country": "Guyana",
                "tld": "gy"
            },
            {
                "country": "Haiti",
                "tld": "ht"
            },
            {
                "country": "Heard Island and McDonald Islands",
                "tld": "hm"
            },
            {
                "country": "Holy See (Vatican City State)",
                "tld": "va"
            },
            {
                "country": "Honduras",
                "tld": "hn"
            },
            {
                "country": "Hong Kong",
                "tld": "hk"
            },
            {
                "country": "Hungary",
                "tld": "hu"
            },
            {
                "country": "Iceland",
                "tld": "is"
            },
            {
                "country": "India",
                "tld": "in"
            },
            {
                "country": "Indonesia",
                "tld": "id"
            },
            {
                "country": "Iran",
                "tld": "ir"
            },
            {
                "country": "Iraq",
                "tld": "iq"
            },
            {
                "country": "Ireland",
                "tld": "ie"
            },
            {
                "country": "Israel",
                "tld": "il"
            },
            {
                "country": "Italy",
                "tld": "it",
                "currency":"€"
            },
            {
                "country": "Ivory Coast",
                "tld": "ci"
            },
            {
                "country": "Jamaica",
                "tld": "jm"
            },
            {
                "country": "Japan",
                "tld": "jp"
            },
            {
                "country": "Jordan",
                "tld": "jo"
            },
            {
                "country": "Kazakhstan",
                "tld": "kz"
            },
            {
                "country": "Kenya",
                "tld": "ke"
            },
            {
                "country": "Kiribati",
                "tld": "ki"
            },
            {
                "country": "Kuwait",
                "tld": "kw"
            },
            {
                "country": "Kyrgyzstan",
                "tld": "kg"
            },
            {
                "country": "Laos",
                "tld": "la"
            },
            {
                "country": "Latvia",
                "tld": "lv"
            },
            {
                "country": "Lebanon",
                "tld": "lb"
            },
            {
                "country": "Lesotho",
                "tld": "ls"
            },
            {
                "country": "Liberia",
                "tld": "lr"
            },
            {
                "country": "Libyan Arab Jamahiriya",
                "tld": "ly"
            },
            {
                "country": "Liechtenstein",
                "tld": "li"
            },
            {
                "country": "Lithuania",
                "tld": "lt"
            },
            {
                "country": "Luxembourg",
                "tld": "lu"
            },
            {
                "country": "Macao",
                "tld": "mo"
            },
            {
                "country": "North Macedonia",
                "tld": "mk"
            },
            {
                "country": "Madagascar",
                "tld": "mg"
            },
            {
                "country": "Malawi",
                "tld": "mw"
            },
            {
                "country": "Malaysia",
                "tld": "my"
            },
            {
                "country": "Maldives",
                "tld": "mv"
            },
            {
                "country": "Mali",
                "tld": "ml"
            },
            {
                "country": "Malta",
                "tld": "mt"
            },
            {
                "country": "Marshall Islands",
                "tld": "mh"
            },
            {
                "country": "Martinique",
                "tld": "mq"
            },
            {
                "country": "Mauritania",
                "tld": "mr"
            },
            {
                "country": "Mauritius",
                "tld": "mu"
            },
            {
                "country": "Mayotte",
                "tld": "yt"
            },
            {
                "country": "Mexico",
                "tld": "mx"
            },
            {
                "country": "Moldova",
                "tld": "md"
            },
            {
                "country": "Monaco",
                "tld": "mc"
            },
            {
                "country": "Mongolia",
                "tld": "mn"
            },
            {
                "country": "Montserrat",
                "tld": "ms"
            },
            {
                "country": "Morocco",
                "tld": "ma"
            },
            {
                "country": "Mozambique",
                "tld": "mz"
            },
            {
                "country": "Myanmar",
                "tld": "mm"
            },
            {
                "country": "Namibia",
                "tld": "na"
            },
            {
                "country": "Nauru",
                "tld": "nr"
            },
            {
                "country": "Nepal",
                "tld": "np"
            },
            {
                "country": "Netherlands",
                "tld": "nl"
            },
            {
                "country": "Netherlands Antilles",
                "tld": "an"
            },
            {
                "country": "New Caledonia",
                "tld": "nc"
            },
            {
                "country": "New Zealand",
                "tld": "nz"
            },
            {
                "country": "Nicaragua",
                "tld": "ni"
            },
            {
                "country": "Niger",
                "tld": "ne"
            },
            {
                "country": "Nigeria",
                "tld": "ng"
            },
            {
                "country": "Niue",
                "tld": "nu"
            },
            {
                "country": "Norfolk Island",
                "tld": "nf"
            },
            {
                "country": "North Korea",
                "tld": "kp"
            },
            {
                "country": "Northern Ireland",
                "tld": 'ie'
            },
            {
                "country": "Northern Mariana Islands",
                "tld": "mp"
            },
            {
                "country": "Norway",
                "tld": "no"
            },
            {
                "country": "Oman",
                "tld": "om"
            },
            {
                "country": "Pakistan",
                "tld": "pk"
            },
            {
                "country": "Palau",
                "tld": "pw"
            },
            {
                "country": "Palestine",
                "tld": "ps"
            },
            {
                "country": "Panama",
                "tld": "pa"
            },
            {
                "country": "Papua New Guinea",
                "tld": "pg"
            },
            {
                "country": "Paraguay",
                "tld": "py"
            },
            {
                "country": "Peru",
                "tld": "pe"
            },
            {
                "country": "Philippines",
                "tld": "ph"
            },
            {
                "country": "Pitcairn",
                "tld": "pn"
            },
            {
                "country": "Poland",
                "tld": "pl",
                "pid_regex":"^[0-9]{11}$",
                "regid_regex":"^[0-9]{9}$",
                "phone_regex":"5([0-9]{2})[0-9]{6}$",
                "language":"pl",
                "currency":"zł"
            },
            {
                "country": "Portugal",
                "tld": "pt"
            },
            {
                "country": "Puerto Rico",
                "tld": "pr"
            },
            {
                "country": "Qatar",
                "tld": "qa"
            },
            {
                "country": "Reunion",
                "tld": "re"
            },
            {
                "country": "Romania",
                "tld": "ro"
            },
            {
                "country": "Russian Federation",
                "tld": "ru",
                "pid_regex":"^[0-9]{6,20}$",
                "regid_regex":"^[0-9]{6,30}$",
                "phone_regex":"^[0-9]{6,30}$",
                "language":"ru",
                "currency":"₽"
            },
            {
                "country": "Rwanda",
                "tld": "rw"
            },
            {
                "country": "Saint Helena",
                "tld": "sh"
            },
            {
                "country": "Saint Kitts and Nevis",
                "tld": "kn"
            },
            {
                "country": "Saint Lucia",
                "tld": "lc"
            },
            {
                "country": "Saint Pierre and Miquelon",
                "tld": "pm"
            },
            {
                "country": "Saint Vincent and the Grenadines",
                "tld": "vc"
            },
            {
                "country": "Samoa",
                "tld": "ws"
            },
            {
                "country": "San Marino",
                "tld": "sm"
            },
            {
                "country": "Sao Tome and Principe",
                "tld": "st"
            },
            {
                "country": "Saudi Arabia",
                "tld": "sa"
            },
            {
                "country": "Scotland",
                "tld": 'uk'
            },
            {
                "country": "Senegal",
                "tld": "sn"
            },
            {
                "country": "Serbia",
                "tld": "rs"
            },
            {
                "country": "Seychelles",
                "tld": "sc"
            },
            {
                "country": "Sierra Leone",
                "tld": "sl"
            },
            {
                "country": "Singapore",
                "tld": "sg"
            },
            {
                "country": "Slovakia",
                "tld": "sk"
            },
            {
                "country": "Slovenia",
                "tld": "si"
            },
            {
                "country": "Solomon Islands",
                "tld": "sb"
            },
            {
                "country": "Somalia",
                "tld": "so"
            },
            {
                "country": "South Africa",
                "tld": "za"
            },
            {
                "country": "South Georgia and the South Sandwich Islands",
                "tld": "gs"
            },
            {
                "country": "South Korea",
                "tld": "kr"
            },
            {
                "country": "South Sudan",
                "tld": "ss"
            },
            {
                "country": "Spain",
                "tld": "es"
            },
            {
                "country": "Sri Lanka",
                "tld": "lk"
            },
            {
                "country": "Sudan",
                "tld": "sd"
            },
            {
                "country": "Suriname",
                "tld": "sr"
            },
            {
                "country": "Svalbard and Jan Mayen",
                "tld": "sj"
            },
            {
                "country": "Swaziland",
                "tld": "sz"
            },
            {
                "country": "Sweden",
                "tld": "se"
            },
            {
                "country": "Switzerland",
                "tld": "ch"
            },
            {
                "country": "Syria",
                "tld": "sy"
            },
            {
                "country": "Tajikistan",
                "tld": "tj"
            },
            {
                "country": "Tanzania",
                "tld": "tz"
            },
            {
                "country": "Thailand",
                "tld": "th"
            },
            {
                "country": "The Democratic Republic of Congo",
                "tld": "cd"
            },
            {
                "country": "Togo",
                "tld": "tg"
            },
            {
                "country": "Tokelau",
                "tld": "tk"
            },
            {
                "country": "Tonga",
                "tld": "to"
            },
            {
                "country": "Trinidad and Tobago",
                "tld": "tt"
            },
            {
                "country": "Tunisia",
                "tld": "tn"
            },
            {
                "country": "Turkey",
                "tld": "tr"
            },
            {
                "country": "Turkmenistan",
                "tld": "tm"
            },
            {
                "country": "Turks and Caicos Islands",
                "tld": "tc"
            },
            {
                "country": "Tuvalu",
                "tld": "tv"
            },
            {
                "country": "Uganda",
                "tld": "ug"
            },
            {
                "country": "Ukraine",
                "tld": "ua",
                "pid_regex":"^[0-9]{13,20}$",
                "regid_regex":"^[0-9]{13,30}$",
                "phone_regex":"^0(50|66|67|98|97|96|68|39|63|93|99|95){1}[0-9]{7}$",
                "language":"ua",
                "currency":"₴"


            },
            {
                "country": "United Arab Emirates",
                "tld": "ae"
            },
            {
                "country": "United Kingdom",
                "tld": "uk",
                "pid_regex":"^[0-9]{6,20}$",
                "regid_regex":"^[0-9]{6,30}$",
                "phone_regex":"^(?:0?(?:(\\+|[00])44)?[2-3,7-9]\\d{9})$",
                "language":"en",
                "currency":"£"
            },
            {
                "country": "United States",
                "tld": "us",
                "pid_regex":"^[0-9]{6,20}$",
                "regid_regex":"^[0-9]{6,30}$",
                "phone_regex":"[(][0-9]{3}[)]\\s[0-9]{3}\\s[0-9]{4}",
                "language":"en",
                "currency":"$"
            },
            {
                "country": "United States Minor Outlying Islands",
                "tld": 'us'
            },
            {
                "country": "Uruguay",
                "tld": "uy"
            },
            {
                "country": "Uzbekistan",
                "tld": "uz"
            },
            {
                "country": "Vanuatu",
                "tld": "vu"
            },
            {
                "country": "Venezuela",
                "tld": "ve"
            },
            {
                "country": "Vietnam",
                "tld": "vn"
            },
            {
                "country": "Virgin Islands, British",
                "tld": "vg"
            },
            {
                "country": "Virgin Islands, U.S.",
                "tld": "vi"
            },
            {
                "country": "Wales",
                "tld": "uk"
            },
            {
                "country": "Wallis and Futuna",
                "tld": "wf"
            },
            {
                "country": "Western Sahara",
                "tld": "eh"
            },
            {
                "country": "Yemen",
                "tld": "ye"
            },
            {
                "country": "Zambia",
                "tld": "zm"
            },
            {
                "country": "Zimbabwe",
                "tld": "zw"
            }
        ],
        LANGUAGE_DATA:[
            {
                "code": "aa",
                "name": "Afar",
                "native": "Afar"
            },
            {
                "code": "ab",
                "name": "Abkhazian",
                "native": "Аҧсуа"
            },
            {
                "code": "af",
                "name": "Afrikaans",
                "native": "Afrikaans"
            },
            {
                "code": "ak",
                "name": "Akan",
                "native": "Akana"
            },
            {
                "code": "am",
                "name": "Amharic",
                "native": "አማርኛ"
            },
            {
                "code": "an",
                "name": "Aragonese",
                "native": "Aragonés"
            },
            {
                "code": "ar",
                "name": "Arabic",
                "native": "العربية",
                "rtl": 1
            },
            {
                "code": "as",
                "name": "Assamese",
                "native": "অসমীয়া"
            },
            {
                "code": "av",
                "name": "Avar",
                "native": "Авар"
            },
            {
                "code": "ay",
                "name": "Aymara",
                "native": "Aymar"
            },
            {
                "code": "az",
                "name": "Azerbaijani",
                "native": "Azərbaycanca / آذربايجان"
            },
            {
                "code": "ba",
                "name": "Bashkir",
                "native": "Башҡорт"
            },
            {
                "code": "be",
                "name": "Belarusian",
                "native": "Беларуская"
            },
            {
                "code": "bg",
                "name": "Bulgarian",
                "native": "Български"
            },
            {
                "code": "bh",
                "name": "Bihari",
                "native": "भोजपुरी"
            },
            {
                "code": "bi",
                "name": "Bislama",
                "native": "Bislama"
            },
            {
                "code": "bm",
                "name": "Bambara",
                "native": "Bamanankan"
            },
            {
                "code": "bn",
                "name": "Bengali",
                "native": "বাংলা"
            },
            {
                "code": "bo",
                "name": "Tibetan",
                "native": "བོད་ཡིག / Bod skad"
            },
            {
                "code": "br",
                "name": "Breton",
                "native": "Brezhoneg"
            },
            {
                "code": "bs",
                "name": "Bosnian",
                "native": "Bosanski"
            },
            {
                "code": "ca",
                "name": "Catalan",
                "native": "Català"
            },
            {
                "code": "ce",
                "name": "Chechen",
                "native": "Нохчийн"
            },
            {
                "code": "ch",
                "name": "Chamorro",
                "native": "Chamoru"
            },
            {
                "code": "co",
                "name": "Corsican",
                "native": "Corsu"
            },
            {
                "code": "cr",
                "name": "Cree",
                "native": "Nehiyaw"
            },
            {
                "code": "cs",
                "name": "Czech",
                "native": "Česky"
            },
            {
                "code": "cu",
                "name": "Old Church Slavonic / Old Bulgarian",
                "native": "словѣньскъ / slověnĭskŭ"
            },
            {
                "code": "cv",
                "name": "Chuvash",
                "native": "Чăваш"
            },
            {
                "code": "cy",
                "name": "Welsh",
                "native": "Cymraeg"
            },
            {
                "code": "da",
                "name": "Danish",
                "native": "Dansk"
            },
            {
                "code": "de",
                "name": "German",
                "native": "Deutsch"
            },
            {
                "code": "dv",
                "name": "Divehi",
                "native": "ދިވެހިބަސް",
                "rtl": 1
            },
            {
                "code": "dz",
                "name": "Dzongkha",
                "native": "ཇོང་ཁ"
            },
            {
                "code": "ee",
                "name": "Ewe",
                "native": "Ɛʋɛ"
            },
            {
                "code": "el",
                "name": "Greek",
                "native": "Ελληνικά"
            },
            {
                "code": "en",
                "name": "English",
                "native": "English"
            },
            {
                "code": "eo",
                "name": "Esperanto",
                "native": "Esperanto"
            },
            {
                "code": "es",
                "name": "Spanish",
                "native": "Español"
            },
            {
                "code": "et",
                "name": "Estonian",
                "native": "Eesti"
            },
            {
                "code": "eu",
                "name": "Basque",
                "native": "Euskara"
            },
            {
                "code": "fa",
                "name": "Persian",
                "native": "فارسی",
                "rtl": 1
            },
            {
                "code": "ff",
                "name": "Peul",
                "native": "Fulfulde"
            },
            {
                "code": "fi",
                "name": "Finnish",
                "native": "Suomi"
            },
            {
                "code": "fj",
                "name": "Fijian",
                "native": "Na Vosa Vakaviti"
            },
            {
                "code": "fo",
                "name": "Faroese",
                "native": "Føroyskt"
            },
            {
                "code": "fr",
                "name": "French",
                "native": "Français"
            },
            {
                "code": "fy",
                "name": "West Frisian",
                "native": "Frysk"
            },
            {
                "code": "ga",
                "name": "Irish",
                "native": "Gaeilge"
            },
            {
                "code": "gd",
                "name": "Scottish Gaelic",
                "native": "Gàidhlig"
            },
            {
                "code": "gl",
                "name": "Galician",
                "native": "Galego"
            },
            {
                "code": "gn",
                "name": "Guarani",
                "native": "Avañe'ẽ"
            },
            {
                "code": "gu",
                "name": "Gujarati",
                "native": "ગુજરાતી"
            },
            {
                "code": "gv",
                "name": "Manx",
                "native": "Gaelg"
            },
            {
                "code": "ha",
                "name": "Hausa",
                "native": "هَوُسَ",
                "rtl": 1
            },
            {
                "code": "he",
                "name": "Hebrew",
                "native": "עברית",
                "rtl": 1
            },
            {
                "code": "hi",
                "name": "Hindi",
                "native": "हिन्दी"
            },
            {
                "code": "ho",
                "name": "Hiri Motu",
                "native": "Hiri Motu"
            },
            {
                "code": "hr",
                "name": "Croatian",
                "native": "Hrvatski"
            },
            {
                "code": "ht",
                "name": "Haitian",
                "native": "Krèyol ayisyen"
            },
            {
                "code": "hu",
                "name": "Hungarian",
                "native": "Magyar"
            },
            {
                "code": "hy",
                "name": "Armenian",
                "native": "Հայերեն"
            },
            {
                "code": "hz",
                "name": "Herero",
                "native": "Otsiherero"
            },
            {
                "code": "ia",
                "name": "Interlingua",
                "native": "Interlingua"
            },
            {
                "code": "id",
                "name": "Indonesian",
                "native": "Bahasa Indonesia"
            },
            {
                "code": "ie",
                "name": "Interlingue",
                "native": "Interlingue"
            },
            {
                "code": "ig",
                "name": "Igbo",
                "native": "Igbo"
            },
            {
                "code": "ii",
                "name": "Sichuan Yi",
                "native": "ꆇꉙ / 四川彝语"
            },
            {
                "code": "ik",
                "name": "Inupiak",
                "native": "Iñupiak"
            },
            {
                "code": "io",
                "name": "Ido",
                "native": "Ido"
            },
            {
                "code": "is",
                "name": "Icelandic",
                "native": "Íslenska"
            },
            {
                "code": "it",
                "name": "Italian",
                "native": "Italiano"
            },
            {
                "code": "iu",
                "name": "Inuktitut",
                "native": "ᐃᓄᒃᑎᑐᑦ"
            },
            {
                "code": "ja",
                "name": "Japanese",
                "native": "日本語"
            },
            {
                "code": "jv",
                "name": "Javanese",
                "native": "Basa Jawa"
            },
            {
                "code": "ka",
                "name": "Georgian",
                "native": "ქართული"
            },
            {
                "code": "kg",
                "name": "Kongo",
                "native": "KiKongo"
            },
            {
                "code": "ki",
                "name": "Kikuyu",
                "native": "Gĩkũyũ"
            },
            {
                "code": "kj",
                "name": "Kuanyama",
                "native": "Kuanyama"
            },
            {
                "code": "kk",
                "name": "Kazakh",
                "native": "Қазақша"
            },
            {
                "code": "kl",
                "name": "Greenlandic",
                "native": "Kalaallisut"
            },
            {
                "code": "km",
                "name": "Cambodian",
                "native": "ភាសាខ្មែរ"
            },
            {
                "code": "kn",
                "name": "Kannada",
                "native": "ಕನ್ನಡ"
            },
            {
                "code": "ko",
                "name": "Korean",
                "native": "한국어"
            },
            {
                "code": "kr",
                "name": "Kanuri",
                "native": "Kanuri"
            },
            {
                "code": "ks",
                "name": "Kashmiri",
                "native": "कश्मीरी / كشميري",
                "rtl": 1
            },
            {
                "code": "ku",
                "name": "Kurdish",
                "native": "Kurdî / كوردی",
                "rtl": 1
            },
            {
                "code": "kv",
                "name": "Komi",
                "native": "Коми"
            },
            {
                "code": "kw",
                "name": "Cornish",
                "native": "Kernewek"
            },
            {
                "code": "ky",
                "name": "Kirghiz",
                "native": "Kırgızca / Кыргызча"
            },
            {
                "code": "la",
                "name": "Latin",
                "native": "Latina"
            },
            {
                "code": "lb",
                "name": "Luxembourgish",
                "native": "Lëtzebuergesch"
            },
            {
                "code": "lg",
                "name": "Ganda",
                "native": "Luganda"
            },
            {
                "code": "li",
                "name": "Limburgian",
                "native": "Limburgs"
            },
            {
                "code": "ln",
                "name": "Lingala",
                "native": "Lingála"
            },
            {
                "code": "lo",
                "name": "Laotian",
                "native": "ລາວ / Pha xa lao"
            },
            {
                "code": "lt",
                "name": "Lithuanian",
                "native": "Lietuvių"
            },
            {
                "code": "lu",
                "name": "Luba-Katanga",
                "native": "Tshiluba"
            },
            {
                "code": "lv",
                "name": "Latvian",
                "native": "Latviešu"
            },
            {
                "code": "mg",
                "name": "Malagasy",
                "native": "Malagasy"
            },
            {
                "code": "mh",
                "name": "Marshallese",
                "native": "Kajin Majel / Ebon"
            },
            {
                "code": "mi",
                "name": "Maori",
                "native": "Māori"
            },
            {
                "code": "mk",
                "name": "Macedonian",
                "native": "Македонски"
            },
            {
                "code": "ml",
                "name": "Malayalam",
                "native": "മലയാളം"
            },
            {
                "code": "mn",
                "name": "Mongolian",
                "native": "Монгол"
            },
            {
                "code": "mo",
                "name": "Moldovan",
                "native": "Moldovenească"
            },
            {
                "code": "mr",
                "name": "Marathi",
                "native": "मराठी"
            },
            {
                "code": "ms",
                "name": "Malay",
                "native": "Bahasa Melayu"
            },
            {
                "code": "mt",
                "name": "Maltese",
                "native": "bil-Malti"
            },
            {
                "code": "my",
                "name": "Burmese",
                "native": "မြန်မာစာ"
            },
            {
                "code": "na",
                "name": "Nauruan",
                "native": "Dorerin Naoero"
            },
            {
                "code": "nb",
                "name": "Norwegian Bokmål",
                "native": "Norsk bokmål"
            },
            {
                "code": "nd",
                "name": "North Ndebele",
                "native": "Sindebele"
            },
            {
                "code": "ne",
                "name": "Nepali",
                "native": "नेपाली"
            },
            {
                "code": "ng",
                "name": "Ndonga",
                "native": "Oshiwambo"
            },
            {
                "code": "nl",
                "name": "Dutch",
                "native": "Nederlands"
            },
            {
                "code": "nn",
                "name": "Norwegian Nynorsk",
                "native": "Norsk nynorsk"
            },
            {
                "code": "no",
                "name": "Norwegian",
                "native": "Norsk"
            },
            {
                "code": "nr",
                "name": "South Ndebele",
                "native": "isiNdebele"
            },
            {
                "code": "nv",
                "name": "Navajo",
                "native": "Diné bizaad"
            },
            {
                "code": "ny",
                "name": "Chichewa",
                "native": "Chi-Chewa"
            },
            {
                "code": "oc",
                "name": "Occitan",
                "native": "Occitan"
            },
            {
                "code": "oj",
                "name": "Ojibwa",
                "native": "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin"
            },
            {
                "code": "om",
                "name": "Oromo",
                "native": "Oromoo"
            },
            {
                "code": "or",
                "name": "Oriya",
                "native": "ଓଡ଼ିଆ"
            },
            {
                "code": "os",
                "name": "Ossetian / Ossetic",
                "native": "Иронау"
            },
            {
                "code": "pa",
                "name": "Panjabi / Punjabi",
                "native": "ਪੰਜਾਬੀ / पंजाबी / پنجابي"
            },
            {
                "code": "pi",
                "name": "Pali",
                "native": "Pāli / पाऴि"
            },
            {
                "code": "pl",
                "name": "Polish",
                "native": "Polski"
            },
            {
                "code": "ps",
                "name": "Pashto",
                "native": "پښتو",
                "rtl": 1
            },
            {
                "code": "pt",
                "name": "Portuguese",
                "native": "Português"
            },
            {
                "code": "qu",
                "name": "Quechua",
                "native": "Runa Simi"
            },
            {
                "code": "rm",
                "name": "Raeto Romance",
                "native": "Rumantsch"
            },
            {
                "code": "rn",
                "name": "Kirundi",
                "native": "Kirundi"
            },
            {
                "code": "ro",
                "name": "Romanian",
                "native": "Română"
            },
            {
                "code": "ru",
                "name": "Russian",
                "native": "Русский"
            },
            {
                "code": "rw",
                "name": "Rwandi",
                "native": "Kinyarwandi"
            },
            {
                "code": "sa",
                "name": "Sanskrit",
                "native": "संस्कृतम्"
            },
            {
                "code": "sc",
                "name": "Sardinian",
                "native": "Sardu"
            },
            {
                "code": "sd",
                "name": "Sindhi",
                "native": "सिनधि"
            },
            {
                "code": "se",
                "name": "Northern Sami",
                "native": "Sámegiella"
            },
            {
                "code": "sg",
                "name": "Sango",
                "native": "Sängö"
            },
            {
                "code": "sh",
                "name": "Serbo-Croatian",
                "native": "Srpskohrvatski / Српскохрватски"
            },
            {
                "code": "si",
                "name": "Sinhalese",
                "native": "සිංහල"
            },
            {
                "code": "sk",
                "name": "Slovak",
                "native": "Slovenčina"
            },
            {
                "code": "sl",
                "name": "Slovenian",
                "native": "Slovenščina"
            },
            {
                "code": "sm",
                "name": "Samoan",
                "native": "Gagana Samoa"
            },
            {
                "code": "sn",
                "name": "Shona",
                "native": "chiShona"
            },
            {
                "code": "so",
                "name": "Somalia",
                "native": "Soomaaliga"
            },
            {
                "code": "sq",
                "name": "Albanian",
                "native": "Shqip"
            },
            {
                "code": "sr",
                "name": "Serbian",
                "native": "Српски"
            },
            {
                "code": "ss",
                "name": "Swati",
                "native": "SiSwati"
            },
            {
                "code": "st",
                "name": "Southern Sotho",
                "native": "Sesotho"
            },
            {
                "code": "su",
                "name": "Sundanese",
                "native": "Basa Sunda"
            },
            {
                "code": "sv",
                "name": "Swedish",
                "native": "Svenska"
            },
            {
                "code": "sw",
                "name": "Swahili",
                "native": "Kiswahili"
            },
            {
                "code": "ta",
                "name": "Tamil",
                "native": "தமிழ்"
            },
            {
                "code": "te",
                "name": "Telugu",
                "native": "తెలుగు"
            },
            {
                "code": "tg",
                "name": "Tajik",
                "native": "Тоҷикӣ"
            },
            {
                "code": "th",
                "name": "Thai",
                "native": "ไทย / Phasa Thai"
            },
            {
                "code": "ti",
                "name": "Tigrinya",
                "native": "ትግርኛ"
            },
            {
                "code": "tk",
                "name": "Turkmen",
                "native": "Туркмен / تركمن"
            },
            {
                "code": "tl",
                "name": "Tagalog / Filipino",
                "native": "Tagalog"
            },
            {
                "code": "tn",
                "name": "Tswana",
                "native": "Setswana"
            },
            {
                "code": "to",
                "name": "Tonga",
                "native": "Lea Faka-Tonga"
            },
            {
                "code": "tr",
                "name": "Turkish",
                "native": "Türkçe"
            },
            {
                "code": "ts",
                "name": "Tsonga",
                "native": "Xitsonga"
            },
            {
                "code": "tt",
                "name": "Tatar",
                "native": "Tatarça"
            },
            {
                "code": "tw",
                "name": "Twi",
                "native": "Twi"
            },
            {
                "code": "ty",
                "name": "Tahitian",
                "native": "Reo Mā`ohi"
            },
            {
                "code": "ug",
                "name": "Uyghur",
                "native": "Uyƣurqə / ئۇيغۇرچە"
            },
            {
                "code": "uk",
                "name": "Ukrainian",
                "native": "Українська"
            },
            {
                "code": "ur",
                "name": "Urdu",
                "native": "اردو",
                "rtl": 1
            },
            {
                "code": "uz",
                "name": "Uzbek",
                "native": "Ўзбек"
            },
            {
                "code": "ve",
                "name": "Venda",
                "native": "Tshivenḓa"
            },
            {
                "code": "vi",
                "name": "Vietnamese",
                "native": "Tiếng Việt"
            },
            {
                "code": "vo",
                "name": "Volapük",
                "native": "Volapük"
            },
            {
                "code": "wa",
                "name": "Walloon",
                "native": "Walon"
            },
            {
                "code": "wo",
                "name": "Wolof",
                "native": "Wollof"
            },
            {
                "code": "xh",
                "name": "Xhosa",
                "native": "isiXhosa"
            },
            {
                "code": "yi",
                "name": "Yiddish",
                "native": "ייִדיש",
                "rtl": 1
            },
            {
                "code": "yo",
                "name": "Yoruba",
                "native": "Yorùbá"
            },
            {
                "code": "za",
                "name": "Zhuang",
                "native": "Cuengh / Tôô / 壮语"
            },
            {
                "code": "zh",
                "name": "Chinese",
                "native": "中文"
            },
            {
                "code": "zu",
                "name": "Zulu",
                "native": "isiZulu"
            }
        ]

    },
    DEFAULT_SETTINGS:{
        "latest_app_version": "0.06",
        "domain_enabled": ["Australia", "France", "Germany", "Italy", "Poland", "Spain", "Ukraine", "United States", "United Kingdom"],
        "language_enabled": ["en", "ru", "de", "fr", "it", "uk", "es"],
        "color_count":"400"
    },

}