<template>
  <div class="SP-container-wrap">
    <div class="BP-container">
      <div class="qr-code-container" v-if="showQr && hash!==null">
        <div class="qr-close-btn" v-on:click="hideQR()"><i class="fas fa-times"></i></div>
        <transition name="list" tag="div">
          <q-rgenerator v-if="showQr && hash!==null" class-name="flex-center" :value="hash" size="300" ></q-rgenerator>
        </transition>
        <div class="txt-md t-center w-margin grey4-txt" :class="$t('font')" style="line-height: 18px;"><i class="far fa-exclamation-triangle"></i> {{$t('make_screen_brighter')}}</div>
      </div>
      <div class="scanner" v-if="scanMode">
        <div class="scanner-close-btn" v-on:click="stopScan()"><i class="fas fa-times"></i></div>
        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"></StreamBarcodeReader>
      </div>
      <div class="input-container">
        <div class="input-group has-both w-margin" :class="$t('font')">
          <div class="input-pre grey7 txt-xxl" style="border-right: 1px solid var(--t-white)"><currency></currency></div>
          <div class="input-desc">{{$t('points_to_buy')}} <span class="red-txt txt-md">* </span></div>
          <div class="input-pos txt-xl" :class="{'grey7':!canScan,'yellow':canScan}" v-on:click="scan()"><i class="fal fa-qrcode "></i></div>
          <div class="input-area">
            <input class="txt-xl number"  type="number" min="0" max="100000" autocomplete="off" v-on:focusin="canClear('points_amount')" v-model="points_amount">
          </div>
        </div>
      </div>
      <div class="container" v-if="noPartners">
        <p class="grey5-txt txt-md" style="text-align: center; margin: 30px;" :class="$t('font')">{{$t('no_partners_available')}}</p>
      </div>
      <div class="info-box" v-for="(partner,index) in partnersData" :key="index">
        <div class="info-box-content">
          <ul>
            <li><span :class="$t('font')"><i class="fal fa-building txt-lg1" style="margin-right: 24px;"></i></span> <span class="en txt-md1 ">{{partner.company}}</span></li>
            <li><span :class="$t('font')"><i class="fal fa-coins txt-lg1" style="margin-right: 22px;"></i></span>  <span class="en txt-lg blue-txt"> {{partner.balance}}</span></li>
          </ul>
        </div>
        <div class="info-box-icon" style="padding-right: 5px;">
          <i class="far fa-lock-open-alt txt-xl blue-txt" v-if="(partner.lock ==0 && !lock_status) || lock_status===0" v-on:click="lock(partner.balance_id)"></i>
          <i class="far fa-lock-alt txt-xl " v-if="(partner.lock==1 && lock_status===null) || lock_status===1"></i>
        </div>
        <div class="info-box-button">
          <div class="action-button active-btn"  v-on:click="share(partner.balance_id)">
            <i class="fal fa-qrcode txt-2xl" v-if="showSpinner!=partner.balance_id"></i>
            <spinner v-if="showSpinner===partner.balance_id"></spinner>
          </div>
        </div>
      </div>


    </div>
    <launcher></launcher>
  </div>
</template>

<script>
import launcher from "@/components/employee/launcher";

import currency from "@/components/STATIC/currency";
import Currency from "@/components/STATIC/currency";
import {mapActions, mapState} from "vuex";
import spinner from "@/components/STATIC/spiner";
import support from "@/support/support";
import {StreamBarcodeReader} from "vue-barcode-reader";
import QRgenerator from 'qrcode.vue'

export default {
  components:{
    Currency,
    currency,
    launcher,

    spinner,
    StreamBarcodeReader,
    QRgenerator,

  },
  data(){
    return{
      errors:[],
      points_amount:0,

      lock_status : null,

      partners:[],

      hash:null,
      scanner_on:null,

      showSpinner:false,

      scanMode:false,
      showQr:false,
      noPartners:false,
    }
  },
  mounted(){
     this._getPartnerList().then(response=>{
       this.noPartners = (response.data.length<=0);
     });
  },
  computed:{
    ...mapState('_partner',['CLOUD']),

    canScan(){
      return this.points_amount>0;
    },
    partnersData(){

      return (this.CLOUD.length<=0) ? [] : this.CLOUD;
    }
  },
  methods:{

    ...mapActions('_partner',['_getPartnerList','_share','_lock','_create_transaction']),


    canClear(actor){
      let val = this[actor];
      if(val.length >0 && val !==0){
        return false;
      }else{
        return this[actor] = '';
      }
    },
    resetState(){
      this.points_amount=0;
      this.lock_status = null;

      this.hash=null;
      this.scanner_on=null;

      this.showSpinner=false;
      this.scanMode = false;
      this.showQr = false;
    },

    scan(){
      return (this.canScan) ? this.scanMode = true: false;
    },
    stopScan(){
      this.scanMode = false;
    },
    /* DEFAULT */
    onLoaded () {
    },
    async onDecode (content) {
      await this.$store.dispatch('showLoading');
      this.hash = content;
      this.stopScan();

      let data = {
        hash:this.hash,
        points_amount:support.roundTo(Number(this.points_amount),2),
      };
      this._create_transaction(data).then(response => {
        if(response.status===200){
          this.$store.dispatch('hideLoading');
          return this.resetState();
        }


      }).catch(error => {
        this.$store.dispatch('hideLoading');
        return this.errors = error.errors;
      });

    },
    showQrCode (){
      if(this.hash !==null){
        this.showQr = true;
      }else{
        return false;
      }
    },
    hideQR(){
      this.showQr = false;
    },
    share(balance_id){
      this.$store.dispatch('showLoading');
        // this.showSpinner = balance_id;
        let data = {
          balance_id:balance_id,
        };
        /* Submit Request to Sever */
        this._share(data).then(response => {
          if(response.status===200){
            this.$store.dispatch('hideLoading');
            this.lock_status =0;
            this.hash = response.data;
            return this.showQrCode();
            // return this.$router.push('/')
          }
        }).catch(error => {
          this.$store.dispatch('hideLoading');
          this.hash =null;
          this.errors = error.errors;
          return this.hideQR();
        });

    },
    lock(balance_id){
      this.$store.dispatch('showLoading');
      let data = {
        balance_id:balance_id,
      };
      /* Submit Request to Sever */
      this._lock(data).then(response => {
        if(response.status===200){
          this.$store.dispatch('hideLoading');
          return this.lock_status = 1;
          // return this.$router.push('/')
        }
      }).catch(error => {
        this.$store.dispatch('hideLoading');
        this.lock_status = 0;
        this.errors = error.errors;
        return false;
      });
    }

  }
}
</script>

<style scoped>
/*.info-box {*/
/*  display: grid;*/
/*  grid-template-areas: "box-content box-info-icon" "box-content box-action-button";*/
/*  grid-template-columns: auto 55px;*/
/*  grid-template-rows: auto 55px;*/
/*  padding: 15px 15px 15px 15px;*/
/*  grid-column-gap: 20px;*/
/*  grid-row-gap: 20px;*/
/*  margin: 5px;*/
/*  border-radius: 12px;*/
/*  -webkit-box-shadow: 0 2px  8px rgba(0,0,0,0.08);*/
/*  -moz-box-shadow: 0 2px  8px rgba(0,0,0,0.08);*/
/*  box-shadow: 0 2px  8px rgba(0,0,0,0.08);*/


/*  justify-self: center;*/
/*}*/
/*.info-box-content {*/
/*  grid-area: box-content;*/
/*  display: flex;*/
/*  justify-content: left;*/
/*  text-align: left;*/
/*  font-size: 15px;*/

/*}*/
/*.info-box-content >ul{*/
/*  text-decoration: none;*/
/*  list-style: none;*/
/*  padding-inline-start: 0;*/
/*  margin-block-start: 0;*/
/*  margin-block-end: 0;*/
/*  margin-inline-start: 0;*/
/*  margin-inline-end: 0;*/
/*  line-height: 25px;*/
/*}*/
/*.info-box-icon{*/
/*  grid-area: box-info-icon;*/
/*  font-size: 16px;*/
/*  margin-right: 5px;*/
/*  color: var(--grey4);*/
/*  text-align: right;*/
/*}*/
/*.info-box-button{*/
/*  grid-area: box-action-button;*/
/*  display: flex;*/

/*  justify-content: center;*/
/*  justify-items: center;*/
/*  width: 100%;*/
/*  height: 100%;*/

/*}*/
/*.action-button{*/
/*  height: 45px;*/
/*  width: 53px;*/
/*  border-radius:12px;*/
/*  text-align: center;*/
/*  font-size: 30px;*/
/*  padding-top: 9px;*/
/*}*/
/*.active-btn {*/
/*  background-color: var(--yellow);*/
/*}*/
/*.BP-container{*/
/*  margin-top: 10px;*/
/*  width: 100%;*/
/*  margin: 5px;*/
/*  padding-bottom: 100px;*/
/*}*/
/*.input-container{*/
/*  max-width: 474px;*/
/*  justify-self: center;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  padding: 10px 10px 10px 10px;*/
/*}*/
/*.SP-container-wrap{*/
/*  display: flex;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  overflow:hidden;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/
/*.input-container {*/
/*  display: block;*/
/*}*/
/*textarea, input[type="text"], input[type="button"], input[type="submit"], input:not([type="radio"]):not([type="checkbox"]), input[type=text]:focus, input:focus, input:active {*/
/*  height: 22px !important;*/
/*  width: 100%;*/
/*  margin: 0;*/
/*  border-radius: 4px;*/
/*  -webkit-appearance: none;*/
/*  color: var(--black);*/
/*  border: 0px solid var(--t-white);*/
/*  padding-right: 4px;*/
/*}*/


/*.scanner-close-btn {*/
/*  position: fixed;*/
/*  display: grid;*/
/*  top:20px;*/
/*  right: 20px;*/
/*  width: 34px;*/
/*  height: 34px;*/
/*  border-radius: 50%;*/
/*  background-color: #000000;*/
/*  text-align: center;*/
/*  justify-items: center;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  align-content: center;*/
/*  z-index: 102;*/
/*  color: white;*/
/*  font-size: 30px;*/
/*}*/
/*.scanner {*/
/*  position: fixed;*/
/*  display: grid;*/
/*  justify-content: center;*/
/*  align-content: center;*/
/*  align-items: center;*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  z-index: 101;*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  background-color: #010101;*/

/*}*/
/*.qr-code-container {*/
/*  position: fixed;*/
/*  display: grid;*/
/*  justify-content: center;*/
/*  align-content: center;*/
/*  align-items: center;*/
/*  width: 100vw;*/
/*  height: 100vh;*/
/*  z-index: 101;*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  background-color: white;*/

/*}*/
/*.qr-close-btn {*/
/*  position: fixed;*/
/*  display: grid;*/
/*  top:20px;*/
/*  right: 20px;*/
/*  width: 34px;*/
/*  height: 34px;*/
/*  border-radius: 50%;*/
/*  background-color: var(--grey4);*/
/*  text-align: center;*/
/*  justify-items: center;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  align-content: center;*/
/*  z-index: 102;*/
/*  color: white;*/
/*  font-size: 30px;*/
/*}*/
</style>