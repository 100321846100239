<template>
  <div class="mg-t-30">
    <app-window :id="2" close-class="grey3-txt">
      <template #content>
        <print-receipt :transaction="Transaction" :processType="1"></print-receipt>
      </template>
    </app-window>
    <div class="transaction-group grid-row-2 w-t-line" v-if="HasTransactions">
      <transaction-group-head @search="filterTransactions"></transaction-group-head>
      <div class="transaction-group-body">
        <div class="transaction-container" v-for="(item,index) in getTransactions" :key="index">
          <div  class="grid-col-4 strech j-c-space-between">
            <div class="grid-row-2">
              <div class="txt-xs grey3-txt">{{$t('check_amount')}}</div>
              <div class="flex-end"><p><dollar></dollar> {{item.check_amount}}</p></div>
            </div>
            <div class="grid-row-2">
              <div class="txt-xs grey3-txt">{{$t('discount')}} <span>({{inPercent(item)}}%)</span></div>
              <div class="flex-end"><p><dollar></dollar> {{item.discount_value}} </p></div>
            </div>
            <div class="grid-row-2">
              <div class="txt-xs grey3-txt">{{$t('cash_paid')}}</div>
              <div class="flex-end"><p><dollar></dollar> {{item.cash_to_pay}}</p></div>
            </div>
            <div class="grid-row-2 " @click="setForPrint(item)">
              <div class="txt-xs grey3-txt">{{ item.date }}</div>
              <div class="txt-3xl flex-center-raw pd-t-5">
                <btn-square icon="fal fa-print blue-txt" color="grey7"></btn-square>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Dollar from "@/components/STATIC/dollar";
import BtnSquare from "@/views/components/action_button_square";
import {mapGetters} from "vuex";
import AppWindow from "@/views/components/application_window";
import PrintReceipt from "@/views/components/print_receipt";
import {awOpen} from "@/support/applicationWindow";
import TransactionGroupHead from "@/views/components/transaction-group-head";
import {searchFilter} from "@/support/helpers";


export default {
  name: "discountTransactions",
  components: {TransactionGroupHead, PrintReceipt, AppWindow, BtnSquare,  Dollar},
  props:['getType'],
  data(){
    return {
      filterKeyword:'',
      enablePrint:false,
      setForPrintTransaction:'',
    }
  },
  computed:{
    ...mapGetters('DISCOUNT',['storeGetTransactions']),
    HasFilterKeyword(){
      return this.filterKeyword.length>0;
    },
    Keyword(){
      return this.filterKeyword;
    },
    HasTransactions(){
      return this.getTransactions.length>0 || this.HasFilterKeyword;
    },
    getTransactions(){
      let self = this;
      let TRANSACTIONS = this.storeGetTransactions;
      TRANSACTIONS = TRANSACTIONS.filter(function(item){
        return item.type===self.getType;
      });
      let searchColmns =[
        {name:'cash_to_pay',type:'str'},
        {name:'check_amount',type:'num'},
        {name:'check_number',type:'num'},
        {name:'date',type:'str'},
        {name:'discount_value',type:'num'},
        {name:'rate',type:'num'},
      ];
      if(self.HasFilterKeyword) {
        return searchFilter(self.Keyword, TRANSACTIONS, searchColmns, 1);
      }
      return TRANSACTIONS;
    },
    Transaction(){
      return this.setForPrintTransaction;
    },

  },
  methods:{
    filterTransactions(input){
        this.filterKeyword = input;
    },
    inPercent(item){
        return item.discount_value/(item.check_amount/100);
    },
    setForPrint (item) {
      this.setForPrintTransaction = item;
      awOpen(2);
    },
  }
}
</script>

<style scoped>

</style>