import rootStore from '@/store/';
/*
* @param searchTem - String
* @param searchPlace - Array
* @param searchColomns - Array with Objects inside [{name:'Jimmy',type:'String'}]
* @parame letterCount - Number , From How many letters in search term to start function
*/


export const searchFilter=(searchTerm, searchPlace, searchColomns, letterCount=2)=>{
    if(searchTerm!==false){
        if(searchTerm.length>=letterCount){

            return searchPlace.filter(item=> {
                if(item){

                    let result = false;

                    searchColomns.forEach(colmn => {

                        if(colmn.type ==='num' || colmn.type ==='number'){
                            if(item[colmn.name] === +searchTerm){
                                result = true;
                            }
                        }else{
                            if(item[colmn.name].toString().toLowerCase().includes(searchTerm.toLowerCase())){
                                result = true;
                            }
                        }

                    });

                    return result;
                }

            });
        }else{
            return searchPlace.filter(item=>{
                if(item){
                    return true;
                }
            });
        }

    }else{
        return searchPlace;
    }
}
export const loading=(LoadingState=true)=>{

       return (LoadingState) ? rootStore.dispatch('showLoading',{ root: true }) : rootStore.dispatch('hideLoading',{ root: true });
}
export const infoMessage=(message)=>{
    rootStore.dispatch('infoMessage',message,{ root: true });
}
export const successMessage=(message)=>{
    rootStore.dispatch('successMessage',message,{ root: true });
}
export const warningMessage=(message)=>{
    rootStore.dispatch('warnMessage',message,{ root: true });
}
export const errorMessage=(message)=>{
    rootStore.dispatch('errorMessage',message,{ root: true });
}
export const isLightCard=(card)=>{
    return (parseInt(card.like) === -1);
}
export const updateMessage=(os)=>{
    rootStore.dispatch('updateMessage',os,{ root: true });
}
export const hideHTML=(data)=>{
    if(typeof data==="object"){
        return data.map(function(id){
           return  document.getElementById(id).style="visibility:hidden";
        });
    }
    return document.getElementById(data).style="visibility:hidden";
}
export const showHTML=(data)=>{
    if(typeof data==="object"){
        return data.map(function(id){
            return  document.getElementById(id).style="visibility:visible";
        });
    }
    return document.getElementById(data).style="visibility:visible";
}
export const rand=(min,max)=>{
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
export const isDefined=(container,variable)=>{
    return (container[variable]!==undefined);
}
export default {
    namespaced: true,
    searchFilter,
    loading,
    isLightCard,
    errorMessage,
    warningMessage,
    successMessage,
    infoMessage,
    hideHTML,
    showHTML,
    isDefined




}
