<template>
<div ref="focus">
  <div class="flex-cnt wd-100p ht-100p j-c-start a-i-center flex-dir-col pd-t-50">
    <div class="box-button-group box-on-line-2 w-tb-margin">
      <div class="box-button-item"  v-for="(ITEM,index) in allowedFunctions" :key="index" v-on:click="$router.push({name:ITEM.link})">
        <div class="box-button"  :class="ITEM.color">
          <div class="box-icon">
            <i class="icon" :class="ITEM.icon"></i>
          </div>
          <i v-if="ITEM.tag!==3" class="fad  txt-xs1" :class="'tag-'+ITEM.type+' '+ITEM.arrowColor+' fa-arrow-'+ITEM.arrow" ></i>
          <i v-if="ITEM.tag===3" class="fad fa-arrow-right txt-xs1 tag-in green-txt"></i>
          <i v-if="ITEM.tag===3" class="fad fa-arrow-right txt-xs1 tag-out red-txt"></i>
        </div>
        <div class="box-button-text indigo-txt txt-xs1 en-r" :class="$t('font')">{{ $t(ITEM.label)}} </div>
      </div>
      <div class="box-button-item"  @click="openReport()">
        <div class="box-button indigo">
          <div class="box-icon pd-t-30 pd-t-10">
            <i class="pd-t-10 txt-7xl fad fa-file-chart-pie"></i>
          </div>
        </div>
        <div class="box-button-text indigo-txt txt-xs1 en-r" :class="$t('font')">{{ $t('get_report')}} </div>
      </div>
    </div>
  </div>
  <app-bar></app-bar>
</div>
</template>
<script>
import AppBar from "@/views/components/apps_bar";
import {mapState} from "vuex";
import config from "@/config";
import support from "@/support/support";
import {awOpen} from "@/support/applicationWindow";

export default {
  name: "appDashboard",
  components: {AppBar},

  computed:{
    ...mapState('GENERAL',['SETTINGS','STATIC_ICON_ARRAY']),
    updateInterval(){
      return config.dataUpdateIntervalInMinutes;
    },
    allowedFunctions:function(){
      let data = this.STATIC_ICON_ARRAY;

      data = data.filter(obj => {
        if(!this.Active){
          return false;
        }
        if(obj.tag ===1 && this.DiscountActive){
          return obj;
        }
        if(obj.tag ===2 && this.CollectorActive){
          return obj;
        }
        if(obj.tag ===3 && this.PartnerActive){
          return obj;
        }
        if(obj.tag ===0){
          return obj;
        }
      });
      return data.slice(0,4);
    },
    PartnerActive(){
      return this.SETTINGS.PARTNERS.enabled===1;
    },
    DiscountActive(){
      return this.SETTINGS.DISCOUNT.enabled===1;
    },
    CollectorActive(){
      return this.SETTINGS.COLLECTOR.enabled===1;
    },
    Active(){
      return this.SETTINGS.active;
    }
  },
  methods:{
    openReport(){
      return awOpen(7);
    },
    logOutIfNotActive(){
      if(this.Active){
        support.logOut();
        return this.$router.push({ name: "src.views.login" });
      }
    },
  },
  mounted() {
    // const [el] = this.$refs.focus;
    // if (el) {
    //   el.scrollIntoView({ behavior: "smooth" });
    // }
  },
}
</script>