import rootStore from '@/store/';

export const ShowLoading=(State=true)=>{

    return (State) ? rootStore.dispatch('showLoading',{ root: true }) : rootStore.dispatch('hideLoading',{ root: true });
}
export const HideLoading=()=>{
    return rootStore.dispatch('hideLoading',{ root: true });
}
/*
* Side : Left | Right | 1-5
* State : True/False
*/
export const ShowSpinner=(SpinnerNumber,State=true)=>{
        let options = {spinner:SpinnerNumber,state:State};
        return rootStore.dispatch('loadingSpinner',options,{ root: true });
}
export const HideSpinner=(SpinnerNumber)=>{
    let options = {spinner:SpinnerNumber,state:false};
    return rootStore.dispatch('loadingSpinner',options,{ root: true });
}
export const ResetLoadingSpinner=()=>{
    return rootStore.dispatch('resetLoadingSpinner',{ root: true });
}
export const ShowSpinners=()=>{
    LeftSpinner();
    RightSpinner();
}
export const LeftSpinner=(state=true)=>{
    let left = {spinner:0,state};
    rootStore.dispatch('loadingSpinner',left,{ root: true });
}
export const RightSpinner=(state=true)=>{
    let right = {spinner:1,state};
    rootStore.dispatch('loadingSpinner',right,{ root: true });
}
export const HideSpinners=()=>{
    LeftSpinner(false);
    RightSpinner(false);
}
export default {
    namespaced: true,
    ShowSpinner,
    ShowLoading,
    HideLoading,
    ResetLoadingSpinner,
    ShowSpinners,
    HideSpinners,
    HideSpinner
}