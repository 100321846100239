export default {

    SETTINGS:{
        id: 0,
        active: 0,
        first_name: "",
        last_name: "",
        session_open:true,
        session_open_date:'',
        session_close_date:'',
        MERCHANT: {
            name: "",
            discount_module: false,
            collector_module: false,
            partner_module: false
        },
        BRANCH: {
            name: "",
            address: "",
            phone: ""
        },
        COLLECTOR: {
            enabled: 0,
            min_purchase: 0,
            spend_minimum: 0,
            sell_rate: 0,
            buy_rate: 0,
            max_onetime_grant: -1
        },
        DISCOUNT: {
            enabled: 0
        },
        PARTNERS: {
            enabled: 0
        }

    },
    REPORT:{
        total_checks_number:'',
        total_check_amount:'',
        total_net_income:'',
        total_discounts:'',
        total_points_accepted:'',
        total_points_accepted_value:'',
        total_points_granted:'',
        total_cards_granted:'',
        first_check_time:'',
        last_check_time:'',
    },
    FRESH_SETTINGS:{
        id: 0,
        active: 0,
        first_name: "",
        last_name: "",
        MERCHANT: {
            name: "",
            discount_module: false,
            collector_module: false,
            partner_module: false
        },
        BRANCH: {
            name: "",
            address: "",
            phone: ""
        },
        COLLECTOR: {
            enabled: 0,
            min_purchase: 0,
            spend_minimum: 0,
            sell_rate: 0,
            buy_rate: 0,
            max_onetime_grant: -1
        },
        DISCOUNT: {
            enabled: 0
        },
        PARTNERS: {
            enabled: 0
        }

    },

    /* Static Hardcoded Data */
    STATIC_ICON_ARRAY:[
                // {tag:0,color:'ui-gr-blue t-white-txt',label:'operations',link:'operations',icon:'fal fa-lightbulb-on'},
                {tag:2, color:'yellow indigo-txt ',label:'accept_points',link:'AcceptPoints',icon:'fad fa-coins', arrowColor:'green-txt', type:'in' , arrow:"right"},
                {tag:2,color:'indigo yellow-txt  ',label:'give_points',link:'GrantPoints',icon:'fad fa-coins fa-swap-opacity', arrowColor:'red-txt', type:'out', arrow:"right"},
                {tag:1,color:'red t-white-txt',label:'accept_discount_card',link:'AcceptCard',icon:'fad fa-percent', arrowColor:'green-txt', type:'in', arrow:"right"},
                {tag:1,color:'red t-white-txt',label:'give_discount_card',link:'GrantCard',icon:'fad fa-credit-card-front', arrowColor:'red-txt', type:'out', arrow:"right"},
                {tag:0,color:'t-white t-white-txt',label:'',link:'',icon:'', arrowColor:'', type:'out', arrow:""},
                {tag:0,color:'t-white t-white-txt',label:'',link:'',icon:'', arrowColor:'', type:'out', arrow:""},
            ],

}