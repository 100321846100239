<template>
    <div class="flex-center" >
        <div class="flex-center" style="max-width: 414px;">


    <div class="w-margin w-launcher">
            <div class="app-card-item grcolor1">
              <div class="card-skeleton" id="card">
                <div class="company-logo"><i class="fal fa-exchange"></i></div>
                <div class="company-name" :class="$t('font')">{{$t('transfer_to_account')}}</div><div class="card-type txt-xl"><i class="fal fa-coins"></i></div>
                <div class="pay-button" :class="{ 'green ': accountStatus===1, 'red ': accountStatus===0, 'yellow ':accountStatus===-1&&canCheckStatus,'hidden':!canCheckStatus }" v-on:click="checkAccount">
                  <i class="fal fa-search black-txt" v-if="accountStatus===-1"></i>
                  <i class="fal fa-check white-txt" v-if="accountStatus===1"></i>
                  <i class="fal fa-exclamation-triangle white-txt" v-if="accountStatus===0"></i>
                </div>
                <div class="account-number">
                  <span class="acc" style="margin-left:3px;"><input class="accountNumber" :placeholder="$t('enter_account_number')" autocomplete="off" value="" @input="transform" ></span>
                </div>
                <div class="valid-date ka">
                  <span class="t-white-txt txt-md1">{{$t('to_transfer')}}</span><br>
                  <span class="t-white-txt" :class="$t('font')">{{$t('amount')}}</span>
                </div>
                <div class="card-points">{{points}}</div>
                <logo></logo>

              </div>
            </div>


            <div class="input-group has-postxt w-margin mg-b--cls" :class="$t('font')">
                <div class="input-desc">{{$t('check_amount')}} <span class="red-txt txt-md">* </span>
                    <span class="orange-txt" v-if="form.check_amount!==0 && form.check_amount<=minimum_purchase">{{$t('minimum')}}: {{ minimum_purchase }}</span>
                    <span class="red-light-txt" v-if="errors['check_amount']">{{ errors['check_amount'][0] }}</span>
                </div>
                <div class="input-pos grey7 txt-xxl "><lari-symbol></lari-symbol></div>
                <div class="input-area"><input class="txt-xl number" type="number" min="0" max="100000" autocomplete="off" value="" @input="calculatePoints"></div>
            </div>
            <div class="value-description">
              <span>{{$t('check_amount_calculates_points_txt')}}</span>
            </div>


            <div class="input-group has-pre  w-margin mg-b--cls" :class="$t('font')">
                <div class="input-desc">{{$t('check_number')}}</div>
                <div class="input-pre grey7 en-sb txt-xl "><i class="fal fa-receipt"></i></div>
                <div class="input-area"><input class="txt-xl text" type="text" v-model="check_number" ></div>
            </div>
            <div class="value-description">
              <span>{{$t('enter_check_number')}}</span>
            </div>
             <div class="space-div--20"></div>
        <div class="container flex-center">
            <button class="ka btn btn-md " :class="{ 'yellow ': canSubmit, 'grey7 ': !canSubmit}" style="justify-self: right;" @click.prevent="submitTransfer"><i class="fal fa-exchange"></i> {{$t('transfer')}}</button>
        </div>
        <launcher></launcher>
    </div>

        </div>
    </div>
</template>
<script>
import launcher from "./launcher";
// import InputNumber from "@/components/STATIC/inputNumber";
// import InputText from "@/components/STATIC/input";
import lariSymbol from "@/components/STATIC/lariSymbol";
import {mapActions, mapState} from 'vuex';
import support from "@/support/support";
import Logo from "@/components/STATIC/cardLogo";


export default {
        components:{
          Logo,
            // InputNumber,
            launcher,
            // InputText,
            lariSymbol,


        },
        data(){
            return {
                scope:'_transfer',
                errors: [],
                points:0,
                account:'',
                sell_rate:0,
                accStatus:-1,
                check_number:'',
                minimum_purchase:0,
                form:{
                    check_amount:0,
                    check_number:'',
                    amount:0,
                    account:'',
                }

            }
        },
        mounted(){
            this.resetToDefaults();
            this.sell_rate = this._settings.sell_rate;
            this.minimum_purchase = this._settings.collector_min_purchase;

        },
        computed:{
            ...mapState('_transfer',['CLOUD']),
            ...mapState('_home',{_settings:'CLOUD'}),

          canCheckStatus(){
            return !(support.stripSpace(this.account).length < 16 || this.form.amount <= 0);
          },
          canSubmit(){
            return this.accountStatus === 1 && this.form.check_amount > this.minimum_purchase && this.form.amount > 0 && this.form.account.length > 0;
          },
          accountStatus(){
            return this.accStatus;
          },

        },
        methods:{
            ...mapActions('_transfer',['_checkAccount','_submitTransfer']),
            resetToDefaults(){
                    this.errors =[];
                    this.points=0;
                    this.account='';
                    this.sell_rate=0;
                    this.accStatus= -1;
                    this.check_number='';
                    this.form = {
                        check_amount:0,
                        check_number:'',
                        amount:0,
                        account:'',
                    }
            },
            submitTransfer(){

                if(this.canSubmit){
                   this._submitTransfer(this.form).then(response => {
                        if(response.status===200){
                             this.errors = [];
                             return this.$router.push('/')
                        }
                    }).catch(error => {
                        this.errors = error.errors;

                    });
                }else{
                    return false;
                }
            },
            checkAccount(){
              if(!this.canCheckStatus){
                return false;
              }
                let check_data = {account:this.account,check_amount:this.form.check_amount};
                this._checkAccount(check_data).then(response => {
                    if(response.status===200){
                        this.errors = [];
                        this.accStatus = 1;
                        this.form.amount =this.points;
                        this.form.account = this.account;
                        this.form.check_number = this.check_number;
                    }
                }).catch(error => {
                    this.errors = error.errors;

                    return this.accStatus = 0;

                });

            },

            calculatePoints(e){
                let amount = Number(e.target.value);
                this.points = support.roundTo(Number(this.sell_rate)*Number(amount),2);
                this.form.check_amount = support.roundTo(amount,2);
                this.form.amount = support.roundTo(this.points,2);
                this.form.account = this.account
                this.form.check_number = this.check_number;
                return true;

            },
            transform(e){
                let accountNumberUnchanged = e.target.value;
                let accChanged = this.space(accountNumberUnchanged,4);
                this.account = accountNumberUnchanged;




                if(accChanged !==false){
                    return  e.target.value = accChanged;
                }

            },

            space(str, after) {
                if (!str) {
                    return false;
                }
                after = after || 4;
                let v = String(str).replace(/[^\dA-Z]/g, ''),
                    reg = new RegExp(".{" + after + "}", "g");
                return v.replace(reg, function (a) {
                    return a + ' ';
                });
            },
        }
    }
</script>
<style type="scss" scoped>
.account-number {
  border: 1px solid var(--t-white) !important;
}
</style>