<template>
  <i class="fal fa-spinner fa-spin"></i>
</template>

<script>
export default {
}
</script>

<style scoped>

</style>