import {storagePut} from "@/support/storage";
import {__TRANSACTION_STORAGE_KEY, storeGetTransactions} from "@/store/Collector/getters";


export const SET_RESULT = (state,data) => {

    let CALLER = state._CALLER_METHOD;

    let SET_PLACE  = state[CALLER + "_RESULT"];
    let NEW_DATA = SET_PLACE;


    Object.keys(SET_PLACE).forEach(key =>{
        NEW_DATA[key] = data[key];
    });
    if(CALLER==="PROCESS" || CALLER==="GRANT"){
        if(data.transaction!==undefined){
            if(data.transaction.id!==undefined){
                let TRANSACTIONS = storeGetTransactions(state);
                TRANSACTIONS.unshift(data.transaction);
                state.TRANSACTIONS = [];
                state.TRANSACTIONS = Object.assign([], state.TRANSACTIONS,TRANSACTIONS);
                storagePut(__TRANSACTION_STORAGE_KEY(),state.TRANSACTIONS);
            }
        }
    }
    state[CALLER+"_RESULT"] = Object.assign({}, state[CALLER+"_RESULT"],NEW_DATA);
    //Reset Caller
    state._CALLER_METHOD = '';
}
export const SET_CALLER =(state,caller)=>{
            state._CALLER_METHOD = '';
            state._CALLER_METHOD = caller
}
export const CONSTRUCT_SUBMIT_FORM = (state) =>{
       let FORM = state.FORM;
       let SUBMIT_FORM ={};
        Object.keys(FORM).forEach(key =>{
            if(FORM[key].length>0||FORM[key]>0){
                SUBMIT_FORM[key] = FORM[key];
            }
        });
        state.SUBMIT_FORM ={};
        state.SUBMIT_FORM = Object.assign({}, state.SUBMIT_FORM,SUBMIT_FORM);
}
export const UPDATE_FORM_VALUE = (state,data) =>{

    let FORM = state.FORM;
    Object.keys(FORM).forEach(key =>{
        if(data[key]!==undefined){
            FORM[key] = data[key];
        }
    });
    state.FORM ={};
    state.FORM = Object.assign({}, state.FORM,FORM);
}
export const FRESH_FORM = (state) =>{
    let FORM = state.FORM;
    Object.keys(FORM).forEach(key =>{
        FORM[key] = '';
    });
    state.FORM ={};
    state.FORM = Object.assign({}, state.FORM,FORM);

    let SUBMIT_FORM = state.SUBMIT_FORM;
    Object.keys(SUBMIT_FORM).forEach(key =>{
        SUBMIT_FORM[key] = '';
    });
    state.SUBMIT_FORM ={};
    state.SUBMIT_FORM = Object.assign({}, state.SUBMIT_FORM,SUBMIT_FORM);

    let SCAN_RESULT = state.SCAN_RESULT;
    Object.keys(SCAN_RESULT).forEach(key =>{
        SCAN_RESULT[key] = '';
    });
    state.SCAN_RESULT ={};
    state.SCAN_RESULT = Object.assign({}, state.SCAN_RESULT,SCAN_RESULT);

    let GRANT_RESULT = state.GRANT_RESULT;
    Object.keys(GRANT_RESULT).forEach(key =>{
        GRANT_RESULT[key] = '';
    });
    state.GRANT_RESULT ={};
    state.GRANT_RESULT = Object.assign({}, state.GRANT_RESULT,GRANT_RESULT);

    let PROCESS_RESULT = state.PROCESS_RESULT;
    Object.keys(PROCESS_RESULT).forEach(key =>{
        PROCESS_RESULT[key] = '';
    });
    state.PROCESS_RESULT ={};
    state.PROCESS_RESULT = Object.assign({}, state.PROCESS_RESULT,PROCESS_RESULT);
}