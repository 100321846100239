import Api from "@/apis/Api";

const END_POINT = 'v1/discount';

export default {
    scan(hash) {
        return  Api().post(`${END_POINT}/scan`,hash);
    },
    process(data) {
        return  Api().post(`${END_POINT}/process`,data);
    },
    choose(data) {
        return  Api().post(`${END_POINT}/choose`,data);
    },
    grant(data) {
        return  Api().post(`${END_POINT}/grant`,data);
    },
    transfer(data) {
        return  Api().post(`${END_POINT}/transfer`,data);
    },
}