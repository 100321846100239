import rootStore from '@/store/';

export const aw=(state=true)=>{
    if(!state){
        return rootStore.dispatch('applicationWindowClose',{ root: true });
    }
    return rootStore.dispatch('applicationWindowOpen',{ root: true });
}
export const awSetStatus=()=>{
    return rootStore.dispatch('applicationWindowStatus',{ root: true });
}
export const awSetData=(data)=>{

    return rootStore.dispatch('applicationWindowSetData',data,{ root: true });
}
export const awSetId=(id)=>{

    return rootStore.dispatch('applicationWindowSetId',id,{ root: true });
}
export const awOpen=(id,data)=>{
    aw();
    awSetId(id);
    return awSetData(data);
}
export const awClose=()=>{
    return aw(false);
}

export default {
    namespaced: true,
    aw,
    awSetData,
    awSetStatus,
    awOpen,
    awClose,
}