export default {
    /* Data Is Filed From Cloud */
    CLOUD:[],

    /* Static Hardcoded Data */
    STATIC:{


    },
    /* Form Template Data */
    TEMPLATE:{

    },
    /* New Inputs Data */
    INPUTS:[],


}