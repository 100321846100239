import {notifyError, notifySuccess} from "@/support/notification";
import {logOut} from "@/support/auth";

import {isDefined} from "@/support/helpers";

export const ResponseMessage = (response) =>{

    let message=null;

    if(isDefined(response,'data')){
        if(isDefined(response.data,'message')){
            message = response.data.message;
        }
    }
    if(isDefined(response,'response')){
        if(isDefined(response.response,'data')){
            if(isDefined(response.response.data,'message')){
                message = response.response.data.message;
            }
            if(isDefined(response.response.data,0)){
                if(isDefined(response.response.data[0],'message')){
                  message = response.response.data[0].message;
                }
            }
        }
    }

    return message;
}
export const ResponseData = (response) => {
    let data =null;

    if(isDefined(response,'data')){
        data = response.data;
        if(isDefined(response.data,'data')){
           data = response.data.data;
        }
        if(isDefined(response.data,'errors')){
            data = response.data.errors;
        }
    }

    if(isDefined(response,'response')){
        data = response.response.data;
        if(isDefined(response.response.data,'data')){
            data = response.response.data.data;
        }
    }
    return data;
}
export const ResponseStatus = (response) => {
    let status = 0;
    if(isDefined(response,'status')){
        status = response.status;
    }
    if(isDefined(response,'response')){
        if(isDefined(response.response,'status')){
            status = response.response.status;
        }
    }
    if(isDefined(response,'request')){
        if(isDefined(response.request,'status')){
            status = response.request.status;
        }
    }
    return status;

}
export const DataErrors = (data) => {
       if(isDefined(data,'errors')){
           return data.errors;
       }
       return [];
}


export const ApiResponseHandler =(response, quiteMode=false)=>{

    let RESP_STATUS = ResponseStatus(response);
    let RESP_DATA = ResponseData(response);
    let RESP_MESSAGE = ResponseMessage(response);
    let RESP_ERRORS = DataErrors(RESP_DATA);



    if(!RESP_STATUS){
        if(!quiteMode){
            return notifyError();
        }
        return;
    }


    if(RESP_STATUS === 200) { //Success
        if(!quiteMode){
            notifySuccess();
        }
        return RESP_DATA;
    }
    if(RESP_STATUS === 401) { //Non Logged User
        logOut();
    }
    if(RESP_STATUS === 422) { //Error
        if(!quiteMode) {
            notifyError(RESP_MESSAGE);
        }
        return RESP_ERRORS;
    }
    if(RESP_STATUS===404){
        if(!quiteMode) {
            notifyError();
        }
        return;
    }
    if(RESP_STATUS===500){

        if(!quiteMode) {
            notifyError();
        }
        return;
    }
    if(RESP_STATUS ===0){ //Offline
        if(!quiteMode) {
            notifyError()
        }
    }

}

export default {
    ApiResponseHandler,
}