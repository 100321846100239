<template>
    <div class="launcher">

      <div><router-link :to="{name:'home'}"><i class="fad fa-home-alt"></i><span class="txt-xs1 ka">{{$t('main')}}</span></router-link> </div>

      <div v-if="settings.collector_enabled ===1">
        <router-link  :to="{name:'buypoints'}">
          <i class="fad fa-coins" style="display: block;">

          </i><span class="txt-xs1" :class="$t('font')">{{$t('get')}}</span>
        </router-link>
      </div>

      <div v-if="settings.collector_enabled ===1"><router-link  :to="{name:'sellpoints'}"><i class="fad fa-coins" style="display: block;"></i><span class="txt-xs1" :class="$t('font')">{{$t('give')}}</span></router-link> </div>

      <div v-if="settings.discount_enabled ===1"><router-link  :to="{name:'buycards'}"><i class="fad fa-percent fa-rotate-180" style="display: block;"></i><span class="txt-xs1" :class="$t('font')">{{$t('get_discount')}}</span></router-link> </div>

      <div v-if="settings.discount_enabled ===1 && settings.collector_enabled ===0"><router-link  :to="{name:'sellcards'}"><i class="fad fa-gift-card" style="display: block;"></i><span class="txt-xs1" :class="$t('font')">{{$t('give_discount')}}</span></router-link> </div>

    </div>
</template>
<script>

import {mapState} from 'vuex';

export default {

        computed:{
                ...mapState('_home',['CLOUD']),
                settings(){
                  return this.CLOUD;
                }
        },


    }
</script>
<style scoped>

</style>