<template>
<i class="pof">{{currency}}</i>
</template>

<script>
import {getDollar} from "@/support/support";

export default {
  computed:{
    currency() {
      return getDollar();
    }
  }
}
</script>

<style scoped>

</style>