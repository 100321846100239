<template>
  <div class="qr-logo">
    <!--        <svg data-name="portfel" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330.39 128.85">-->
    <!--            <path d="M17.21,19.8H47.73q9.3,0,15.42,4a26,26,0,0,1,9.19,10A27.76,27.76,0,0,1,75.4,46.59a27.34,27.34,0,0,1-3.12,12.86A26,26,0,0,1,63,69.34q-6.12,3.94-15.2,3.94H33.18v24h-16ZM47.73,58.19a12.21,12.21,0,0,0,6.56-1.64,10.94,10.94,0,0,0,3.94-4.1,11.62,11.62,0,0,0,0-10.72,10.87,10.87,0,0,0-3.94-4.1A12.12,12.12,0,0,0,47.73,36H33.18v22.2Z"/>-->
    <!--            <path d="M82.29,59.72a18.78,18.78,0,0,1,2-8.8,19.09,19.09,0,0,1,5.14-6.29,22.56,22.56,0,0,1,7.27-3.83A27.2,27.2,0,0,1,105,39.49a26.64,26.64,0,0,1,8.31,1.31,22.63,22.63,0,0,1,7.22,3.83,19.09,19.09,0,0,1,5.14,6.29,18.78,18.78,0,0,1,2,8.8V78.2a19.08,19.08,0,0,1-2,8.92,18.67,18.67,0,0,1-5.14,6.23,22.59,22.59,0,0,1-7.22,3.72A27.81,27.81,0,0,1,105,98.33a28.4,28.4,0,0,1-8.37-1.26,22.53,22.53,0,0,1-7.27-3.72,18.67,18.67,0,0,1-5.14-6.23,19.08,19.08,0,0,1-2-8.92ZM105,53.16a9.91,9.91,0,0,0-5.14,1.42,4.56,4.56,0,0,0-2.41,4.16V78.53A5.2,5.2,0,0,0,99.9,83a8.77,8.77,0,0,0,5.14,1.7,8.6,8.6,0,0,0,5-1.7,5.2,5.2,0,0,0,2.41-4.43V58.74a4.56,4.56,0,0,0-2.41-4.16A9.71,9.71,0,0,0,105,53.16Z"/>-->
    <!--            <path d="M159.51,39.49a37.29,37.29,0,0,1,8,1.09V52.83a16.93,16.93,0,0,0-4.26-.55h-.77a15.08,15.08,0,0,0-4.87,1,9.17,9.17,0,0,0-3.66,2.51,6,6,0,0,0-1.42,4V97.24H137.3V59.61a18.68,18.68,0,0,1,2-8.86,19.23,19.23,0,0,1,5.19-6.29,22.75,22.75,0,0,1,7.17-3.72A26.15,26.15,0,0,1,159.51,39.49Z"/>-->
    <!--            <path d="M192.21,98.11H190.9a17.14,17.14,0,0,1-8.53-2.73,15.85,15.85,0,0,1-5.64-6.95,27.18,27.18,0,0,1-2-11.1V28h14l1.09,12.58h10.06V54.47H189.8V77.22q0,4.92,2.74,6.45a8.93,8.93,0,0,0,4.26.88,24.27,24.27,0,0,0,4.71-.55V96.47A29.64,29.64,0,0,1,192.21,98.11Z"/>-->
    <!--            <path d="M232.78,33.47a8.25,8.25,0,0,0-4.92,1.42,6.85,6.85,0,0,0-2.73,5.58V43.2h10.06V57.09H225.13V97.24h-15.2V39.59a21.11,21.11,0,0,1,1.85-9A17.69,17.69,0,0,1,217,24a20.71,20.71,0,0,1,8.21-3.71,25.53,25.53,0,0,1,6.89-.88,30.94,30.94,0,0,1,3.72.22V33.8A15.69,15.69,0,0,0,232.78,33.47Z"/>-->
    <!--            <path d="M264.61,98.33a28.4,28.4,0,0,1-8.37-1.26,22.51,22.51,0,0,1-7.32-3.77,19.09,19.09,0,0,1-5.2-6.29,18.82,18.82,0,0,1-2-8.81V59.5a18.55,18.55,0,0,1,2-8.91A18.79,18.79,0,0,1,249,44.35a22.19,22.19,0,0,1,7.38-3.66,30.35,30.35,0,0,1,16.51-.11,20.36,20.36,0,0,1,7,3.44,17.46,17.46,0,0,1,4.93,6,19.38,19.38,0,0,1,1.85,8.8V75.47H257.07V78a5.54,5.54,0,0,0,2.62,5,10.66,10.66,0,0,0,5.91,1.7,13,13,0,0,0,5.08-1,7.77,7.77,0,0,0,3.77-3.23l10,7.22a17.83,17.83,0,0,1-4,5.09,22.12,22.12,0,0,1-5,3.28,23,23,0,0,1-5.46,1.8A27.22,27.22,0,0,1,264.61,98.33Zm7.77-39.27a4.93,4.93,0,0,0-2.3-4.43,9.34,9.34,0,0,0-5.14-1.47,10.34,10.34,0,0,0-5.41,1.47,4.94,4.94,0,0,0-2.46,4.54v5h15.31Z"/>-->
    <!--            <path d="M316.78,98.33a24.9,24.9,0,0,1-9.46-1.64,17.81,17.81,0,0,1-6.56-4.49,18.32,18.32,0,0,1-3.83-6.67,26,26,0,0,1-1.26-8.2V19.8h15.21V77.33a7.63,7.63,0,0,0,2.18,5.47q2.19,2.3,6.89,2.29v13c-.58.07-1.13.13-1.64.16S317.29,98.33,316.78,98.33Z"/>-->
    <!--        </svg>-->
    <!--                                    <svg data-name="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 553.68 128.85">-->
    <!--                                        <path d="M96,61.3c0,22.06-16.09,38.27-35.84,38.27-8.89,0-16.08-2.69-20.23-9l-6.33,31.56H10.41l17.92-89.7H50.26l-1.34,6.22a27.14,27.14,0,0,1,19.14-7.31C82.32,31.32,96,42.16,96,61.3Zm-23.4,1.22c0-7.68-4.64-12.68-12.56-12.68-9.14,0-15.72,7.44-15.72,18.53,0,7.67,4.63,12.55,12.55,12.55C66,80.92,72.57,73.49,72.57,62.52Z" style="fill:#fff"/>-->
    <!--                                        <path d="M101.93,69.46c0-21.81,16.94-38.14,40.59-38.14,21.08,0,34.61,11.82,34.61,30,0,21.93-16.94,38.27-40.58,38.27C115.46,99.57,101.93,87.62,101.93,69.46Zm51.68-6.94c0-7.68-4.51-12.68-12.43-12.68-9.27,0-15.73,7.44-15.73,18.53,0,7.67,4.51,12.55,12.44,12.55C147.15,80.92,153.61,73.49,153.61,62.52Z" style="fill:#fff"/>-->
    <!--                                        <path d="M238.43,31.32l-4,20.84a39.56,39.56,0,0,0-5.24-.37c-10.11,0-16.57,4.51-18.76,15.48l-6.34,31.2H180.9l13.17-66.06H216l-1.46,7.32C220.39,33.75,228.55,31.32,238.43,31.32Z" style="fill:#fff"/>-->
    <!--                                        <path d="M267.43,73.85a17.26,17.26,0,0,0-.24,2.19c0,3.66,2.07,5.61,6.09,5.61a14.61,14.61,0,0,0,7-2l2.92,16c-4.87,2.8-11.09,3.9-17.18,3.9-13.53,0-22.31-7.07-22.31-19.38a28.77,28.77,0,0,1,.61-6.22l4.39-21.81h-9.75l3.53-17.31h9.63l3.42-17.18h23.15l-3.41,17.18h15l-3.41,17.31h-15Z" style="fill:#fff"/>-->
    <!--                                        <path d="M328,33.51l-.25,1.34h15.6l-3.41,17.31H325l-9.26,46.31H292.53l9.27-46.31h-9.75l3.53-17.31h9.75l.49-2.68c3-15.11,13.4-25.23,31.07-25.23,6.22,0,11.95,1.22,16,3.54L344,26.69a12.75,12.75,0,0,0-6.82-2C332,24.74,329.34,27.54,328,33.51Z" style="fill:#fff"/>-->
    <!--                                        <path d="M412,71.17H363.46c.85,7.31,5.85,10.72,15,10.72a25.47,25.47,0,0,0,16-5.36L404,91c-8.28,6.1-18.15,8.54-28.15,8.54-21.2,0-35.1-11.7-35.1-30.11,0-21.93,16.21-38.14,40-38.14,20.11,0,32.54,11.7,32.54,29A49.93,49.93,0,0,1,412,71.17ZM364.8,59H392c.12-7.07-4.88-11.21-12.31-11.21C371.87,47.77,367,52.4,364.8,59Z" style="fill:#fff"/>-->
    <!--                                        <path d="M434.39,8h23.15l-18,90.43H416.35Z" style="fill:#fff"/>-->
    <!--                                        <circle cx="485.67" cy="86.96" r="11.5" style="fill:#fff;opacity:1"/>-->
    <!--                                    </svg>-->
    <!--                                <svg  data-name="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391.2 81.41">-->
    <!--                                    <path d="M109.35,51.89c0,19-14.46,31.27-37.06,31.27h-15l-3.85,19.61H32.23l15-75h31.7C98.43,27.78,109.35,36.68,109.35,51.89ZM87.93,53.71c0-6.32-4.39-9.22-11.78-9.22H65l-4.4,22H73.25C82.57,66.45,87.93,62,87.93,53.71Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                    <path d="M110.86,77.27c0-19.17,14.89-33.53,35.67-33.53,18.53,0,30.42,10.4,30.42,26.36,0,19.28-14.89,33.63-35.67,33.63C122.75,103.73,110.86,93.23,110.86,77.27Zm45.41-6.1c0-6.75-4-11.14-10.92-11.14-8.14,0-13.82,6.53-13.82,16.28,0,6.75,4,11,10.93,11C150.6,87.34,156.27,80.81,156.27,71.17Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                    <path d="M230.83,43.74l-3.54,18.32a36.47,36.47,0,0,0-4.6-.32c-8.89,0-14.57,4-16.5,13.6l-5.57,27.43H180.27l11.57-58.06h19.28l-1.29,6.43C215,45.89,222.15,43.74,230.83,43.74Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                    <path d="M256.32,81.13a14.41,14.41,0,0,0-.21,1.93c0,3.21,1.82,4.92,5.35,4.92a12.85,12.85,0,0,0,6.11-1.71l2.57,14c-4.28,2.47-9.75,3.43-15.1,3.43-11.89,0-19.61-6.21-19.61-17a24.69,24.69,0,0,1,.54-5.46l3.86-19.18h-8.57l3.1-15.21h8.46l3-15.1h20.36l-3,15.1h13.17l-3,15.21H260.18Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                    <path d="M309.56,45.67l-.21,1.18h13.71l-3,15.21H306.88l-8.14,40.71H278.39l8.14-40.71H278l3.11-15.21h8.57l.43-2.36c2.67-13.28,11.78-22.17,27.31-22.17,5.46,0,10.5,1.07,14,3.11l-7.82,14.24a11.23,11.23,0,0,0-6-1.71C313.1,38,310.74,40.42,309.56,45.67Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                    <path d="M383.36,78.77H340.73c.75,6.43,5.14,9.43,13.18,9.43a22.44,22.44,0,0,0,14-4.71l8.46,12.74c-7.28,5.36-16,7.5-24.74,7.5-18.64,0-30.85-10.28-30.85-26.46,0-19.28,14.25-33.53,35.13-33.53,17.68,0,28.6,10.29,28.6,25.5A43.28,43.28,0,0,1,383.36,78.77ZM341.91,68.06H365.8c.1-6.21-4.29-9.85-10.82-9.85C348.12,58.21,343.84,62.28,341.91,68.06Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                    <path d="M403.07,23.29h20.36l-15.86,79.48H387.22Z" transform="translate(-32.23 -22.32)" style="fill:#fff"/>-->
    <!--                                </svg>-->
    <!--                                <svg  data-name="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459.08 128.85">-->
    <!--                                    <path style="fill:#fff" d="M114.45,55c0,16.71-12.53,27.1-32.56,27.1H66.78v20.68H49.43v-75H81.89C101.92,27.78,114.45,38.17,114.45,55ZM96.88,55c0-8.25-5.35-13.07-16-13.07H66.78V68H80.92C91.53,68,96.88,63.13,96.88,55Z"/>-->
    <!--                                    <path style="fill:#fff" d="M121.2,74c0-17.35,13.39-29.67,31.71-29.67S184.51,56.6,184.51,74s-13.28,29.67-31.6,29.67S121.2,91.3,121.2,74Zm46.38,0c0-10-6.32-16-14.67-16s-14.78,6-14.78,16,6.42,16,14.78,16S167.58,83.91,167.58,74Z"/>-->
    <!--                                    <path style="fill:#fff" d="M231.43,44.28V59.71c-1.4-.11-2.47-.22-3.75-.22-9.21,0-15.32,5-15.32,16.07v27.21H195.65V45.14h16v7.6C215.68,47.17,222.54,44.28,231.43,44.28Z"/>-->
    <!--                                    <path style="fill:#fff" d="M280.6,100c-3.43,2.47-8.47,3.64-13.61,3.64-13.6,0-21.53-7-21.53-20.67V59.28h-8.89V46.42h8.89v-14h16.71v14h14.35V59.28H262.17V82.74c0,4.92,2.68,7.6,7.18,7.6a11.29,11.29,0,0,0,6.75-2.14Z"/>-->
    <!--                                    <path style="fill:#fff" d="M308.34,46.42h14.78V59.28H308.77v43.49H292.06V59.28h-8.89V46.42h8.89V43.85c0-13,7.92-21.42,22.28-21.42,4.71,0,9.64,1,12.74,3.11l-4.39,12.1a11.67,11.67,0,0,0-6.75-2c-4.92,0-7.6,2.68-7.6,8.36Z"/>-->
    <!--                                    <path style="fill:#fff" d="M386.86,78.66h-43.6c1.61,7.18,7.71,11.57,16.5,11.57a19.55,19.55,0,0,0,14.46-5.57l8.89,9.64c-5.36,6.11-13.39,9.32-23.78,9.32-19.93,0-32.89-12.53-32.89-29.67s13.18-29.67,30.75-29.67c16.92,0,30,11.35,30,29.89C387.18,75.45,387,77.27,386.86,78.66Zm-43.81-9.74h28.38c-1.18-7.29-6.64-12-14.14-12S344.22,61.53,343.05,68.92Z"/>-->
    <!--                                    <path style="fill:#fff" d="M398.32,23.29H415v79.48H398.32Z"/>-->
    <!--                                </svg>-->
    <svg  data-name="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 553.68 128.85">
      <path d="M90.83,53.46c0,17-12.72,27.51-33,27.51H42.45v21H24.83V25.84h33C78.11,25.84,90.83,36.39,90.83,53.46ZM73,53.46c0-8.37-5.44-13.26-16.2-13.26H42.45V66.62H56.8C67.56,66.62,73,61.73,73,53.46Z" style="fill:#fff"/>
      <path d="M99.64,63.9c0-22.62,17.61-39.36,41.64-39.36s41.65,16.64,41.65,39.36-17.73,39.36-41.65,39.36S99.64,86.52,99.64,63.9Zm65.46,0c0-14.46-10.23-24.35-23.82-24.35s-23.81,9.89-23.81,24.35,10.22,24.36,23.81,24.36S165.1,78.36,165.1,63.9Z" style="fill:#fff"/>
      <path d="M244.69,102,230,80.76H213.8V102H196.19V25.84h32.95c20.33,0,33.05,10.55,33.05,27.62,0,11.42-5.76,19.79-15.66,24L263.61,102ZM228.16,40.2H213.8V66.73h14.36c10.76,0,16.2-5,16.2-13.27S238.92,40.2,228.16,40.2Z" style="fill:#fff"/>
      <path d="M290.79,40.2H266.43V25.84h66.33V40.2H308.4V102H290.79Z" style="fill:#fff"/>
      <path d="M359.83,40V60.1h35.23V74.23H359.83V102H342.21V25.84h57.52V40Z" style="fill:#fff"/>
      <path d="M470.63,87.82V102H411.69V25.84h57.52V40h-40V56.51h35.34v13.7H429.2V87.82Z" style="fill:#fff"/>
      <path d="M484.65,25.84h17.61V87.61h38.17V102H484.65Z" style="fill:#fff"/>
    </svg>
    <!--                                <svg  data-name="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 553.68 128.85">-->
    <!--                                    <path d="M93.57,50.46c0,18.95-14.46,31.27-37,31.27h-15l-3.85,19.59H16.48l15-74.95H63.16C82.65,26.37,93.57,35.26,93.57,50.46ZM72.15,52.28c0-6.31-4.39-9.21-11.77-9.21H49.24L44.85,65H57.48C66.8,65,72.15,60.53,72.15,52.28Z" style="fill:#fff"/>-->
    <!--                                    <path d="M98.6,69.84c0-26,19.27-45,46.79-45,23,0,37.26,13.6,37.26,33,0,26-19.28,45-46.79,45C112.84,102.82,98.6,89.22,98.6,69.84Zm62.63-10.7c0-10-6.42-16.71-17.66-16.71-14.67,0-23.56,12-23.56,26.13,0,10,6.42,16.7,17.67,16.7C152.35,85.26,161.23,73.27,161.23,59.14Z" style="fill:#fff"/>-->
    <!--                                    <path d="M242.39,78.84l12.74,22.48H233L221.83,81.41H211.12l-4,19.91H186l15-74.95h32c19.17,0,30.2,8.89,30.2,23.88C263.16,64.06,255.45,74.34,242.39,78.84ZM230,43.07H218.83l-4.5,22H227c9.31,0,14.77-4.5,14.77-12.75C241.74,46,237.25,43.07,230,43.07Z" style="fill:#fff"/>-->
    <!--                                    <path d="M291.64,43.18h-23L272,26.37h67.13l-3.42,16.81H312.84l-11.67,58.14H280Z" style="fill:#fff"/>-->
    <!--                                    <path d="M365,42.75l-3.32,16.49h33.08l-3.32,16.38h-33l-5.14,25.7H332.1l15-74.95h58.68l-3.32,16.38Z" style="fill:#fff"/>-->
    <!--                                    <path d="M433.39,42.75l-2.46,12.64h33.3l-3.11,15.85h-33.4L425,84.94h39.29l-3.42,16.38H400.84l15-74.95h58.68l-3.32,16.38Z" style="fill:#fff"/>-->
    <!--                                    <path d="M487.78,26.37H509L497.42,84.51h35.76l-3.43,16.81h-57Z" style="fill:#fff"/>-->
    <!--                                </svg>-->
  </div>
</template>
<script>
export default {

}
</script>
<style>
.portfel-card-logo{
  height: 70px;
}
.portfel-card-logo > svg{
  fill: #ffffff;
}
</style>