<template>
  <div @click="onOff()" :class="setClass">
    <i v-if="!getState" style="bottom: -3px; --fa-primary-color: white; --fa-secondary-color: var(--grey4); --fa-secondary-opacity: 1.0;" class="fad fa-toggle-off"></i>
    <i v-if="getState" style="bottom: -3px; --fa-primary-color:var(--green) ; --fa-secondary-color: var(--t-white); --fa-secondary-opacity: 1.0;" class="fad fa-toggle-on"></i>
  </div>
</template>

<script>
export default {
  name: "inputSwitch",
  props:{
    setClass:{
      default() {
        return '';
      }
    },
    state:{
      default(){
        return false;
      }
    }
  },
  emits:['change'],

  data(){
    return {
      localState:false,
    }
  },
  mounted() {
    this.localState = this.state;
  },
  watch:{
    state:function(val){
        this.localState = val;
    }
  },
  computed:{
    getState(){
      return this.localState;
    },
  },
  methods:{
    onOff(){
      this.localState = !this.localState;
      this.$emit('change',this.localState);
    }
  }
}
</script>

<style scoped>

</style>