import {ApiResponseHandler} from "@/support/apiResponseHandler";
import Api from "@/apis/Collector/CollectorApi";
import {HideLoading, ShowLoading} from "@/support/loading";

export const _scan = ({commit,state})=> {
    return new Promise((resolve ,reject) => {
        ShowLoading();
        Api.scan(state.SUBMIT_FORM).then(response => {

            let result = ApiResponseHandler(response,true);
            commit('SET_CALLER','SCAN');
            commit('SET_RESULT',result);
            resolve (response);
            HideLoading();
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _process = ({commit,state})=> {
    return new Promise((resolve, reject) => {
        ShowLoading();
        Api.process(state.SUBMIT_FORM).then(response => {
            let result = ApiResponseHandler(response,true);
            commit('SET_CALLER','PROCESS');
            commit('SET_RESULT',result);
            resolve (response);
            HideLoading();
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _grant = ({commit,state})=> {
    return new Promise((resolve, reject) => {
        ShowLoading();
        Api.grant(state.SUBMIT_FORM).then(response => {
            let result = ApiResponseHandler(response,true);
            commit('SET_CALLER','GRANT');
            commit('SET_RESULT',result);
            resolve (response);
            HideLoading();
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _transfer = ({commit,state})=> {
    return new Promise((resolve, reject) => {
        ShowLoading();
        Api.transfer(state.SUBMIT_FORM).then(response => {
            let result = ApiResponseHandler(response);
            commit('SET_CALLER','GRANT');
            commit('SET_RESULT',result);
            resolve (result);
            HideLoading();
        }).catch(error=>{
            HideLoading();
            return reject(ApiResponseHandler(error));
        });

    });
}
export const _createSubmitForm = ({commit})=> {
        commit('CONSTRUCT_SUBMIT_FORM');
}
export const _updateFormValue =({commit},data)=>{
    commit('UPDATE_FORM_VALUE',data);
    commit('CONSTRUCT_SUBMIT_FORM');
}
export const _freshForm =({commit})=>{
    commit('FRESH_FORM');
}


